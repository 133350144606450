<div class="home-container">
  <div class="header">
    <div class="menu">
      <!-- <mat-icon
        style="color: #084574"
        aria-hidden="false"
        aria-label="menu icon"
        >menu</mat-icon
      > -->
    </div>
    <div class="logo">
      <h1 class="title">Waitron</h1>
    </div>
  </div>
  <div class="place-selector">
    <div class="banner">
      <h1 class="info">Table ordering made easy.</h1>
    </div>
    <div class="venue">
      <div class="top-content">
        <p>Search for your local venue.</p>
      </div>
      <mat-form-field appearance="outline">
        <!-- <mat-label>Please select a pub</mat-label>
        <mat-select
          [(value)]="selectedPub"
          (selectionChange)="selectPub(selectedPub)"
        >
          <mat-option></mat-option>
          <mat-option
            value="{{ list.locId }}"
            *ngFor="let list of companyList"
            >{{ list.displayName }}</mat-option
          >
        </mat-select> -->
        <mat-label>Pick a venue</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Number"
          matInput
          [formControl]="myControl"
          [matAutocomplete]="auto"
          #locSearch
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectPub($event.option.value)"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option.displayName"
          >
            {{ option.displayName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-button color="primary" (click)="searchClick(locSearch.value)">Search</button>
    </div>
  </div>

  <div class="ill-background">&nbsp;</div>

  <footer>
    <p>&copy; Azui 2021</p>
  </footer>
</div>
