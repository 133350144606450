<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">Invoices</h1>

      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="data-content">
    <!-- Filter goes here -->
    <!-- <div class="filters"></div> -->

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="Invoice">
        <th mat-header-cell *matHeaderCellDef>Invoice</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="Date range">
        <th mat-header-cell *matHeaderCellDef>Date Range</th>
        <!-- <td mat-cell *matCellDef="let element">{{ element.weekStartDate }}</td> -->
        <td mat-cell *matCellDef="let element">
          {{ element.weekStartDate | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Net">
        <th mat-header-cell *matHeaderCellDef>Net</th>
        <td mat-cell *matCellDef="let element">
          {{ element.turnover | currency: "GBP" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Download">
        <th mat-header-cell *matHeaderCellDef>Download</th>
        <td mat-cell *matCellDef="let element">
          <a value="Download PDF" (click)="SavePDF(element.id)"> PDF </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="emptyTable" class="emptyTable">No records found</div>
    <mat-paginator
      [pageSizeOptions]="[20, 40, 60, 80, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<!-- <table id="html-table-id">TESTER</table> -->

<!-- <div id="pdf-content" #pdfContent></div> -->
