import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AdminService } from 'src/app/SERVICES/admin.service';
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { SuccessComponent } from '../../success/success.component';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddMenuComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) { }

  addMenu: FormGroup;
  title: string = this.data[0].title;
  durationInSeconds = 2;

  ngOnInit(): void {
    this.addMenu = this.formBuilder.group({
      menuName: ['', [Validators.required]],
      enableItem: [''],
    });
  }

  add() {
    let sesh = this.ls.checkCookieOnly('sesh=');

    const formData = new FormData();
    formData.append('key', sesh.toString());
    formData.append('enableItem', this.addMenu.value.enableItem);
    formData.append('name', this.addMenu.value.menuName);
    formData.append('locId', this.ls.getSessionStorage('locID'));
    formData.append('userId', this.service.userId);
    if (this.title === "menu") {
      formData.append('action', 'addNewProdType');
    } else if (this.title === "extra") {
      formData.append('action', 'newExtraCategory');
    }


    this.spinner.show();
    return this.as.postReq(formData).pipe(
      finalize(() => this.spinner.hide())
    ).subscribe(res => {
      if (res.result === 'success') {
        this.openSnackBar();
        this.dialogRef.close();
      }
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snackbar-container'],
      data: 'Menu has been created'
    });
  }
}
