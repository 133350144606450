import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-prev-orders",
  templateUrl: "./prev-orders.component.html",
  styleUrls: ["./prev-orders.component.scss"],
})
export class PrevOrdersComponent implements OnInit {
  constructor(
    private service: ServicesService,
    private ls: LocalStorageService
  ) {}

  orders: Array<any> = [];

  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    let sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    // "&locId=" +
    //     locID +
    this.service
      .getReqOrder(
        "?action=userHistory&key=" +
          sesh +
          "&days=14" +
          "&userId=" +
          this.service.userId +
          "&locId=" +
          locID
      )
      .subscribe((res) => {
        console.log("res order history", res);
        this.orders = res.result.data;
      });
  }
}
