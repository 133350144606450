<div class="container">
  <p class="title">My Orders</p>

  <div class="prev-orders" *ngIf="orders.length > 0">
    <p class="title">Previous Orders</p>

    <!-- <div *ngFor="let order of orders"> -->
    <!-- <p class="title">Order #{{ order.id }}</p> -->
    <!-- </div> -->

    <mat-accordion>
      <mat-expansion-panel *ngFor="let order of orders">
        <!-- hideToggle -->
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="title" style="margin-bottom: 0">Order #{{ order.id }}</p>
          </mat-panel-title>
          <!-- <mat-panel-description>
            This is a summary of the content
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div *ngFor="let item of order.items" class="prev-item">
          <span style="margin-right: 4px" class="qty">
            {{ item.itemQuantity }}x
          </span>
          <span style="font-weight: lighter" class="name">
            {{ item.itemName }}</span
          >
          <span style="font-weight: lighter" class="desc"
            >, {{ item.portion }}</span
          >
          <div
            style="font-weight: lighter; margin-bottom: 6px"
            *ngFor="let extra of item.extras"
          >
            <span class="desc">, {{ extra.ExtraName }}</span>
          </div>
          <span style="font-weight: lighter" class="desc"
            >, £{{ item.itemPrice }}
          </span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="no-orders" *ngIf="orders.length === 0">
    <div class="icon-centered">
      <span class="custom-icon no-orders no-active"></span>
      <h2 class="info">You have no previous orders</h2>
      <p class="title">
        Looks like you don’t have any previous orders, check our menu to
        order now.
      </p>

      <a routerLink="../">
        <button mat-button color="primary">Order Now</button>
      </a>
    </div>
  </div>
</div>
