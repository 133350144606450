<ngx-spinner></ngx-spinner>
<div class="item-text">
  <h2 mat-dialog-title> New {{title}}</h2>
</div>

<form [formGroup]="addMenu" id="add-form" (ngSubmit)="add()">
  <mat-dialog-content class="mid-content">
    <div id="name">

      <div class="is-active">
        <h3> Name </h3>
        <mat-slide-toggle formControlName="enableItem" class="enable-item">Active</mat-slide-toggle>
      </div>
      <mat-form-field required appearance="outline">
        <input matInput type="text" placeholder="E.g. Food" formControlName="menuName">
        <mat-hint class="hint" align="start"><strong>Choose a name for your new menu.</strong> </mat-hint>
      </mat-form-field>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" type="button" mat-dialog-close> Cancel </button>
    <button mat-button color="primary" type="submit" value="add" [disabled]="addMenu.invalid"> Add </button>

    <!-- [mat-dialog-close]="true"  -->
  </mat-dialog-actions>
</form>