<ngx-spinner></ngx-spinner>
<app-breadcrumbs></app-breadcrumbs>

<div class="categories-container">
  <div
    class="categories"
    *ngFor="let c of categoryArray?.products | keyvalue; let i = index"
  >
    <a [routerLink]="[c.key]" routerLinkActive>
      <div class="category">
        <span class="custom-icon {{ c.value.icon }}-icon"></span>
        <p class="title">{{ c.key }}</p>
        <!-- <h3>{{ c.key }}</h3> -->
      </div>
    </a>
  </div>
</div>
<span class="custom-icon Drinks-background"></span>
