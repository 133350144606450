<div class="login-container">
  <div class="header">
    <a routerLink="../">
      <mat-icon aria-label="Back Button" fontSet="material-icons-outlined">
        west</mat-icon
      >
      <p class="title">My Account</p>
    </a>
  </div>

  <div id="login">
    <div id="login-container">
      <div class="icon-container">
        <span class="custom-icon sign-in-icon"> </span>
      </div>

      <div class="title-container">
        <h2 class="info">Welcome Back</h2>
        <p>
          <span> Not registered? </span>
          <a routerLink="../register"> Creat an account </a>
        </p>
      </div>

      <!-- error message goes here -->
      <app-info-box
        *ngIf="statusFailed"
        [ngClass]="{ 'error-box': statusFailed }"
        [status]="status"
      ></app-info-box>
      <form [formGroup]="loginForm" id="login-form" (ngSubmit)="login()">
        <div id="email">
          <mat-form-field
            [ngClass]="{ 'has-error': formControls.email.errors }"
            required
          >
            <input
              matInput
              type="email"
              placeholder="Email"
              formControlName="email"
            />
            <mat-error *ngIf="formControls.email.errors"
              >Email must be a valid email address</mat-error
            >
          </mat-form-field>
        </div>

        <div id="password">
          <mat-form-field
            [ngClass]="{ 'has-error': formControls.password.errors }"
            required
          >
            <input
              matInput
              type="password"
              placeholder="Password"
              formControlName="password"
            />
            <mat-error *ngIf="formControls.password.errors"
              >Password is required</mat-error
            >
          </mat-form-field>
        </div>

        <div id="extra-links">
          <p>
            <a routerLink="../forgot-password"> Forgot password? </a>
          </p>
        </div>

        <!-- <div class="g-signin2" data-onsuccess="onSignIn"></div> -->
        <div class="button-container">
          <button
            mat-button
            color="primary"
            type="submit"
            value="Log in"
            [disabled]="loginForm.invalid"
          >
            Sign in
          </button>
        </div>
      </form>
      <app-g-signin></app-g-signin>
    </div>
  </div>
</div>
