<div class="order-container">
  <div class="populated-cart">
    <div class="container" *ngFor="let o of acceptedOrders; let i = index;">
      <div *ngIf="o.orderStatus === '3'" class="inner-container">

        <div class="order-details" (click)="itemClicked(o)">
          <div class="order-title">
            <h4> #{{o.id}} </h4>
            <h4> {{o.createdDate | date:'shortTime'}} </h4>
          </div>


          <div class="secondary-items">
            <h5> Table No. {{o.tableNumber}} </h5>
            <h5>
              {{ o.itemCount }} Items
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="empty-cart" *ngIf="acceptedOrders.length === 0">
    <div class="icon-centered">
      <mat-icon aria-label="Shopping cart" fontSet="material-icons-outlined">
        report</mat-icon>

      <h3> There are currently no new accepted orders</h3>

      <!-- <a routerLink=""> <button mat-button color="primary"> Return to menu </button> </a> -->
    </div>

  </div>
</div>