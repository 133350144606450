import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { ErrorComponent } from "../../error/error.component";
import { SuccessComponent } from "../../success/success.component";

@Component({
  selector: "app-edit-product",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditProductComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditProductComponent>,
    public dialog: MatDialog
  ) {}

  addProduct: FormGroup;
  categories: Object;
  sesh: Observable<any>;
  extra: Object;
  extras: any;

  isEnabled: boolean;
  item = this.data[0];
  catId = this.data[1];
  predefinedExtras: Array<any> = [];
  durationInSeconds = 2;

  uom: Array<any> = [];
  filteredOptions: Observable<string[]>;

  vat: Array<any> = [];
  vatOptions: Observable<string[]>;

  ngOnInit(): void {
    this.addProduct = this.formBuilder.group({
      productName: [this.item.name, [Validators.required]],
      subCategory: [this.item.subCategoryId, [Validators.required]],
      enableItem: [(this.isEnabled = this.item.enabled === "1" ? true : false)],
      description: [this.item.desc],
      abv: [this.item.abv],
      vat: [this.item.vat, [Validators.required]],
      extras: [this.predefinedExtras],

      options: this.formBuilder.array([]),
      // action: 'x',
      action: "updateProduct",
    });

    this.vatNameControl();

    this.sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    // let request = "?action=getNewProductDefaultItems&key=" + this.sesh + '&categoryId=' + this.catId;
    let request =
      "?action=getNewProductDefaultItems&key=" +
      this.sesh +
      "&categoryId=" +
      this.catId +
      "&locId=" +
      locID +
      "&userId=" +
      this.service.userId;
    this.as.getReq(request).subscribe((res: any) => {
      if (res.result) {
        if (res.result.categorys && res.result.categorys.length) {
          this.categories = res.result.categorys[0].title;
        }

        if (res.result.uom && res.result.uom.length) {
          let uomLength = res.result.uom.length;
          let i;
          for (i = 0; i < uomLength; i++) {
            this.uom.push(res.result.uom[i]["unit of measurement"]);
          }
          this.manageNameControl(0);
        }

        if (res.result.vat && res.result.vat.length) {
          let vatLength = res.result.vat.length;
          let i;
          for (i = 0; i < vatLength; i++) {
            this.vat.push(res.result.vat[i]["vat name"]);
          }
          this.vatNameControl();
        }

        if (res.result.extra && res.result.extra.length) {
          this.extra = res.result.extra;
        }

        if (res.result.extras) {
          this.extras = res.result.extras;
        }
      }
    });

    // this.addOption();
    this.fillInputs();
  }

  manageNameControl(index) {
    // TODO: Fix this autocomplete shite.
    let arrayControl = this.addProduct.get("options") as FormArray;
    this.filteredOptions = arrayControl
      .at(index)
      .get("optionPortion")
      .valueChanges.pipe(
        // this.filteredOptions = this.addProduct.get('options')['controls'][0].get('optionUnit').valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.uom.filter(
      (optionTing) => optionTing.toLowerCase().indexOf(filterValue) === 0
    );
  }

  vatNameControl() {
    // TODO: Fix this autocomplete shite.
    let arrayControl = this.addProduct.get("vat") as FormArray;
    this.vatOptions = arrayControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._vatFilter(value))
    );
  }

  private _vatFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vat.filter(
      (vat) => vat.toLowerCase().indexOf(filterValue) === 0
    );
  }

  newOption(): FormGroup {
    return this.formBuilder.group({
      optionName: [""],
      optionPrice: ["", [Validators.required]],
      units: [this.item.units],
      optionPortion: [""],
      optionDefault: [""],
      displayOptionActive: [""],
      displayProductName: [""],
      optionId: ["0"],
    });
  }

  preOption(
    data,
    displayProductName,
    isDefault,
    isActive,
    optionId,
    optionPortion,
    units
  ): FormGroup {
    return this.formBuilder.group({
      optionName: [data.productName],
      optionPrice: [data.price, [Validators.required]],
      units: [units],
      optionPortion: [optionPortion],
      optionDefault: [isDefault],
      displayOptionActive: [isActive],
      displayProductName: [displayProductName],
      optionId: [optionId],
    });
  }

  get options(): FormArray {
    return this.addProduct.get("options") as FormArray;
  }

  addOption() {
    this.options.push(this.newOption());
    const controls = <FormArray>this.addProduct.controls["options"];
    this.manageNameControl(controls.length - 1);
  }

  removeOption(i: number) {
    this.options.removeAt(i);
  }

  add() {
    let product = {
      productName: this.addProduct.value.productName,
      enableItem: this.addProduct.value.enableItem,
      subCategory: this.addProduct.value.subCategory,
      description: this.addProduct.value.description,
      abv: this.addProduct.value.abv,
      units: this.addProduct.value.units,
      vatId: this.addProduct.value.vat,
      id: this.data[2],

      pricing: this.addProduct.value.options,
      extras: this.addProduct.value.extras,
    };

    const formData = new FormData();
    formData.append("product", JSON.stringify(product));
    formData.append("action", this.addProduct.value.action);
    formData.append("key", this.sesh.toString());
    formData.append("locId", this.ls.getSessionStorage("locID"));
    formData.append("userId", this.service.userId);

    return this.as.postReq(formData).subscribe((res) => {
      console.log(res);
      if (res.result === "success") {
        this.dialogRef.close();
        this.openSnackBar();
      } else if (res.status === "failed") {
        this.openError(res.message);
      }
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["snackbar-container"],
      data: "Product has been added successfully.",
    });
  }

  openError(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      panelClass: "modal-container",
      data: [{ message: message }],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }

  fillInputs() {
    // Sets the active toggle to true or false.
    this.isEnabled = this.item.enabled === "1" ? true : false;

    const keys = Object.keys(this.item.extras);

    keys.forEach((key, index) => {
      let i;
      for (i = 0; i < this.item.extras[key].length; i++) {
        this.predefinedExtras.push(this.item.extras[key][i].optionId);
      }
    });

    let k;
    for (k = 0; k < this.item.pricing.length; k++) {
      let displayProductName =
        this.item.pricing[k].displayProductName === "1" ? true : false;
      let displayQuantity =
        this.item.pricing[k].displayQuantity === "1" ? true : false;
      let isDefault = this.item.pricing[k].default === "1" ? true : false;
      let isActive = this.item.pricing[k].enabled === "1" ? true : false;
      let optionId = this.item.pricing[k].id;
      let optionPortion = this.item.pricing[k].portion;
      let units = this.item.pricing[k].units;
      this.options.push(
        this.preOption(
          this.item.pricing[k],
          displayProductName,
          isDefault,
          isActive,
          optionId,
          optionPortion,
          units
        )
      );
      const controls = <FormArray>this.addProduct.controls["options"];
      this.manageNameControl(controls.length - 1);
    }
  }
}
