<div class="login-container">
  <div id="login">
    <div id="login-container">

      <h2 id="login-header">Forgot Password</h2>

      <!-- error message goes here -->
      <app-info-box *ngIf="statusFailed || forgotSuccess"
        [ngClass]="{ 'error-box': statusFailed, 'success-box': forgotSuccess}" [status]="status"></app-info-box>
      <form [formGroup]="forgotPassForm" id="login-form" (ngSubmit)="forgotPass($event)">
        <span>
          Enter your email address and we'll send you a link to reset your password.
        </span>
        <div id="email">
          <mat-form-field [ngClass]="{ 'has-error': formControls.email.errors }" required>
            <input matInput type="email" placeholder="Email" formControlName="email">
            <mat-error *ngIf="formControls.email.errors">Email must be a valid email address</mat-error>
          </mat-form-field>
        </div>
        <div class="button-container">
          <button mat-flat-button color="primary" type="submit" value="Log in" [disabled]="forgotPassForm.invalid">Reset
            password</button>
        </div>
        <div id="extra-links">
          <p>
            <a routerLink="../account"> Back to login </a>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>