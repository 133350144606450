import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {

  items: object;
  totalPrice: number = 0;

  constructor(private service: ServicesService) { }

  ngOnInit(): void {

    this.service.getOrderItem('order-item').subscribe(x => {
      this.items = x;
      this.getTotalPrice();
    });

  }


  // TODO This should be passed from the BE when we hook it up so it cannot be changed..
  getTotalPrice() {
    let i;
    for (i = 0; i < this.items[0].items.length; i++) {
      this.totalPrice += this.items[0].items[i].price * this.items[0].items[i].qty;
    }
  }

}
