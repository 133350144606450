import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private ls: LocalStorageService) { }

  items = [];
  totalQty: number = 0;
  totalPrice: number = 0;

  public merchantKey: string;
  public expiry: string;
  
  public checkoutObj: Array<any> = [];

  private qtySource = new BehaviorSubject<number>(0);
  private priceSource = new BehaviorSubject<number>(0);

  currentPrice = this.priceSource.asObservable();
  currentQty = this.qtySource.asObservable();

  addToCart(product): Promise<any> {
    return Promise.resolve((() => {
      // if no items in the cart, add it.
      if (!this.items.length) {
        this.items.push(product);
      } else {
        // checks if there is the same item in the cart array. If it exists, add the qty + break. 
        // If it doesn't exist, push the product to the cart array and then break.
        let itemLen = this.items.length;
        let i;
        for (i = 0; i < itemLen; i++) {
          if (product.cartId === this.items[i].cartId) {
            this.items[i].qty += product.qty;
            break;
          } else {
            if (itemLen === i + 1) {
              this.items.push(product);
              break;
            }
          }
        }
      }

      this.totalQty += product.qty;
      this.qtySource.next(this.totalQty);
      this.getData();
      this.priceSource.next(this.totalPrice);

      let stringyItem = JSON.stringify(this.items);
      this.ls.setSessionStorage('cart', stringyItem);
    })());
  }

  getItems() {
    return this.items;
  }

  clearCart() {
    this.items = [];
    return this.items;
  }

  updateCart(action, qty) {
    if (action === 'updated') {
      this.totalQty += qty;
    } else if (action === 'removed') {
      this.totalQty -= qty;
    }

    this.getData();
    this.qtySource.next(this.totalQty)

    if (this.items.length) {
      let stringyItem = JSON.stringify(this.items);
      this.ls.setSessionStorage('cart', stringyItem);
    } else {
      this.ls.removeSessionStorage('cart');
    }
  }

  getData() {
    this.totalPrice = 0;
    let i;
    for (i = 0; i < this.items.length; i++) {
      this.totalPrice += (this.items[i].price * this.items[i].qty);
    }
    this.priceSource.next(this.totalPrice)
  }

  checkedOut() {
    this.totalQty = 0;
    this.qtySource.next(this.totalQty);
    this.totalPrice = 0;
    this.priceSource.next(this.totalPrice);
  }
}
