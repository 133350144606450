import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs/operators";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { SuccessComponent } from "../../success/success.component";

@Component({
  selector: "app-add-category",
  templateUrl: "./add-category.component.html",
  styleUrls: ["./add-category.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddCategoryComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    public dialogRef: MatDialogRef<AddCategoryComponent>,
    private _snackBar: MatSnackBar
  ) {}

  addCategory: FormGroup;
  parentCatgories: Object;
  uom: Object;
  selectedValue: string;
  durationInSeconds = 2;

  ngOnInit(): void {
    this.addCategory = this.formBuilder.group({
      selectedCat: ["", [Validators.required]],
      newCategory: [""],
      subCategory: [""],
      newIcon: [""],
      // action: 'nah',
      action: "addNewCategory",
      key: this.ls.checkCookieOnly("sesh="),
    });

    let id = this.ls.getSessionStorage("menuIdAdmin");
    let locID = this.ls.getSessionStorage("locID");
    let sesh = this.ls.checkCookieOnly("sesh=");

    // this.as.getReq("?action=getList&productType=" + id + '&productType=' + id + '&key=' + sesh).subscribe((res: any) => {
    this.as
      .getReq(
        "?action=getList&productType=" +
          id +
          "&productType=" +
          id +
          "&key=" +
          sesh +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res: any) => {
        if (res.result) {
          this.parentCatgories = res.result.categorys;
        }
      });
  }

  add() {
    // let sesh = this.ls.checkCookieOnly('sesh=');
    let menuId = this.ls.getSessionStorage("menuIdAdmin");

    const formData = new FormData();
    formData.append("action", this.addCategory.value.action);
    formData.append("selectedCat", this.addCategory.value.selectedCat);
    formData.append("subCategory", this.addCategory.value.subCategory);
    formData.append("key", this.addCategory.value.key);
    formData.append("productType", menuId);
    formData.append("locId", this.ls.getSessionStorage("locID"));
    formData.append("userId", this.service.userId);
    if (this.selectedValue === "0") {
      formData.append("newCategory", this.addCategory.value.newCategory);
      formData.append("icon", this.addCategory.value.newIcon);
    }

    this.as.postReq(formData).subscribe(
      (res) => {
        if (res.result === "success") {
          this.openSnackBar();
          this.dialogRef.close();
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  // TODO: Check if this is needed? Currently not being used, it only closes add cat the modal.
  // closeDialog(): void {
  //   this.dialogRef.close();

  //   this.dialogRef.afterClosed()
  //     .pipe(finalize(() => console.log("Completed")))
  //     .subscribe(data => {
  //     });
  // }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["snackbar-container"],
      data: "Category has been created",
    });
  }
}
