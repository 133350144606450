<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    fixedInViewport="true"
    class="sidenav"
    opened="{{ showSidebar }}"
    disableClose="true"
    mode="side"
    [ngClass]="{ 'icon-mode': !isExpanded }"
  >
    <div class="content">
      <div class="company-name">
        <div class="logo">
          {{ icon }}
        </div>
        <h2 *ngIf="isExpanded">{{ title }}</h2>
      </div>

      <mat-nav-list *ngIf="!isAdmin">
        <a
          mat-list-item
          href="#"
          *ngFor="let n of nav"
          [routerLink]="[n.link]"
          routerLinkActive="active"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="icon"
            fontSet="material-icons-outlined"
            >{{ n.icon }}</mat-icon
          >

          <span *ngIf="isExpanded"> {{ n.name }} </span>
        </a>

        <div *ngIf="false">
          <a
            mat-list-item
            href="#"
            *ngFor="let na of navAdmin"
            [routerLink]="[na.link]"
            routerLinkActive="active"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="icon"
              fontSet="material-icons-outlined"
              >{{ na.icon }}</mat-icon
            >

            <span *ngIf="isExpanded"> {{ na.name }} </span>
          </a>
        </div>
      </mat-nav-list>

      <mat-nav-list *ngIf="isAdmin">
        <div *ngFor="let n of navAdmin">
          <a
            mat-list-item
            href="#"
            *ngIf="n.type.indexOf(isAdmin) > -1"
            [routerLink]="[n.link]"
            routerLinkActive="active"
            queryParamsHandling="merge"
          >
            <span class="custom-icon {{ n.icon }}"></span>

            <span *ngIf="isExpanded" class="nav-link {{ n.class }}">
              {{ n.name }}
            </span>
          </a>
        </div>
      </mat-nav-list>

      <div class="sidebar-footer">
        <div class="toggle-menu" (click)="isExpanded = !isExpanded">
          <span class="material-icons-outlined"> toggle_on </span>

          <p class="title" *ngIf="isExpanded">Toggle Menu</p>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content
  [ngStyle]="{'margin-left': isExpanded ? '220px' : '90px' }">
    <div
      class="top-header"
      [ngStyle]="{ height: contentHeight + 'px', display: showHeader }"
    >
      <div class="nav-container" #navContainer>
        <div class="logo" [routerLink]="['./']">
          <h1>{{ logo }}</h1>
        </div>

        <div class="header-icons">
          <a
            [routerLink]="['./admin/dashboard']"
            ActivatedRoute
            *ngIf="isAdmin"
          >
            <span class="custom-icon dashboard-icon"></span>
          </a>

          <a [routerLink]="['./cart']" ActivatedRoute>
            <span
              class="custom-icon basket-icon"
              [matBadge]="totalQty"
              [matBadgeHidden]="totalQty === 0"
              matBadgeColor="warn"
              matBadgePosition="before"
            ></span>
          </a>
          <a [routerLink]="['./account']" ActivatedRoute>
            <span class="custom-icon user-two-icon"></span>
          </a>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
