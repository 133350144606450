<app-header [nav]="navigationLinks" [navAdmin]="adminLinks">
  <div
    id="app-root-body"
    [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
  </div>

  <app-bottom-nav></app-bottom-nav>
</app-header>
