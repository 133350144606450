import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CartService } from "src/app/SERVICES/cart.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";

@Component({
  selector: "app-bottom-nav",
  templateUrl: "./bottom-nav.component.html",
  styleUrls: ["./bottom-nav.component.scss"],
})
export class BottomNavComponent implements OnInit {
  constructor(
    private cart: CartService,
    public router: Router,
    private ls: LocalStorageService) {
    router.events.subscribe((val: any) => {
      if (val.url && val.url.indexOf("/admin/") > -1) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = true;
      }
    });
  }

  showBottomNav: boolean = true;
  totalQty: number = 0;
  locId: string;

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");

    // Gets the total quantity in from cartService and adds it to the badge.
    this.cart.currentQty.subscribe((totalQty) => (this.totalQty = totalQty));
  }
}
