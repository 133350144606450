<div class="order-container" *ngFor="let o of orderItems; let i = index">
  <div class="order-header">
    <div class="order-id">
      <div class="title">
        <h2 class="info">Order #{{ o.id }}</h2>
      </div>
    </div>

    <div class="checkout-button">
      <!-- <mat-icon
        aria-label="menu vertical"
        fontSet="material-icons-outlined"
        (click)="openDialog(o.orderStatus, o.id)"
      >
        more_vert</mat-icon
      > -->

      <button
        (click)="completed(o.orderStatus, o.id)"
        mat-button
        color="primary"
        *ngIf="o.orderStatus == 2"
      >
        Complete
      </button>
    </div>
  </div>

  <div class="order-info">
    <div>
      <!-- <div class="title"> -->
      <h3 class="info">Table Number:</h3>
      <span> {{ o.tableNumber }}</span>
      <!-- </div> -->
    </div>

    <div>
      <!-- <div class="title"> -->
      <h3 class="info">Order Time:</h3>
      <!-- </div> -->
      <span>{{ o.createdDate | date: "shortTime" }}</span>
    </div>

    <div>
      <h3 class="info">Total items:</h3>
      <span>{{ o.itemCount }} item<span *ngIf="o.itemCount > 1">s</span></span>
    </div>

    <div>
      <h3 class="info">Total amount:</h3>
      <!-- <span>{{ o.createdDate | date: "shortTime" }}</span> -->
      <span>{{ o.totalPrice | currency: "GBP" }}</span>
    </div>
  </div>

  <div class="order-details">
    <div class="cart-item" *ngFor="let oi of o.items">
      <p class="title">{{ oi.itemQuantity }}x {{ oi.itemName }},</p>
      <ul>
        <li class="info">{{ oi.portion }}</li>
        <li class="info" *ngFor="let e of oi.extras">{{ e?.ExtraName }}</li>
      </ul>
    </div>
  </div>
</div>
