import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-update-status",
  templateUrl: "./update-status.component.html",
  styleUrls: ["./update-status.component.scss"],
})
export class UpdateStatusComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateStatusComponent>,
    private service: ServicesService,
    private ls: LocalStorageService
  ) {}

  options: any;
  currentId: string;
  orderId: string;

  ngOnInit(): void {
    this.options = this.data[0];
    this.currentId = this.data[1];
    this.orderId = this.data[2];
  }

  onSubmit(id) {
    let sesh = this.ls.checkCookieOnly("sesh=").toString();
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("action", "updateOrder");
    formData.append("orderId", this.orderId);
    formData.append("statusId", id);
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    formData.append("key", sesh);

    this.service.updateOrder(formData).subscribe((res) => {
      if (res.result === "success") {
        this.closeDialog();
        // window.location.reload();
      } else if (res.status === "failed") {
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
