import { Component, OnInit, Inject } from "@angular/core";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<ConfirmComponent>,
    private ls: LocalStorageService
  ) {}

  locId: string;

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");
  }
}
