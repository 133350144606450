import { formatDate } from '@angular/common';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { match } from 'minimatch';

export interface ValidationResult {
	[key: string]: boolean;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
	public static hasNumber: boolean;
	public static hasUpper: boolean;
	public static hasLower: boolean;
	public static hasSymbol: boolean;
	public static hasEight: boolean;

	public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

		return invalidCtrl || invalidParent;
		return false;
	}

	public strong(control: FormControl): ValidationResult {
		MyErrorStateMatcher.hasNumber = /\d/.test(control.value);
		MyErrorStateMatcher.hasUpper = /[A-Z]/.test(control.value);
		MyErrorStateMatcher.hasLower = /[a-z]/.test(control.value);
		MyErrorStateMatcher.hasSymbol = /[!@#\$%\^&]/.test(control.value);
		MyErrorStateMatcher.hasEight = /(?=.{8,})/.test(control.value);

		const valid = MyErrorStateMatcher.hasNumber && MyErrorStateMatcher.hasUpper && MyErrorStateMatcher.hasLower && MyErrorStateMatcher.hasSymbol && MyErrorStateMatcher.hasEight;

		// const valid = true;
		if (!valid) {
			// return what´s not valid
			return { strong: true };
		}
		return null;
	}

	public dob(control: FormControl): ValidationResult {
		let dobYoungest = new Date();
		dobYoungest.setFullYear(dobYoungest.getFullYear() - 13);
		let limit = formatDate(dobYoungest, 'yyyy-MM-dd', 'en-uk');
		let ctrl;

		if (control.value != "") {
			ctrl = formatDate(control.value, 'yyyy-MM-dd', 'en-uk');
		}

		if (ctrl !== undefined && ctrl > limit) {
			return { dob: true };
		}
		return null;

	}
}
