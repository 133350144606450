import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-completed-orders',
  templateUrl: './completed-orders.component.html',
  styleUrls: ['./completed-orders.component.scss']
})
export class CompletedOrdersComponent implements OnInit {

  constructor() { }

  @Input() completedOrders: any;
  @Input() statusList: any;

  @Output() onItemPicked: EventEmitter<any> = new EventEmitter<any>();

  
  ngOnInit(): void {
  }

  
  itemClicked(item) {
    this.onItemPicked.emit([item]);
  }
}
