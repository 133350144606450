<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">Overview</h1>

      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="dashboard-container">
    <div class="widgets">
      <!-- TODO: Turn into component! -->
      <div class="widget" routerLink="../orders">
        <div class="content">
          <p class="title">
            {{ totalOrders?.displayName }}
          </p>

          <h3 class="info">
            {{ totalOrders?.value.result }}
          </h3>
        </div>
        <span class="custom-icon receipt-white-icon"> </span>
      </div>
      <div class="widget" routerLink="../orders">
        <div class="content">
          <p class="title">
            {{ todaysTotal?.displayName }}
          </p>

          <h3 class="info">
            {{ todaysTotal?.value.result | currency: "GBP" }}
          </h3>
        </div>
        <span class="custom-icon currency-icon"> </span>
      </div>
      <div class="widget" routerLink="../menu">
        <div class="content">
          <p class="title">
            {{ activeMenu?.displayName }}
          </p>

          <h3 class="info">
            {{ activeMenu?.value.result }}
          </h3>
        </div>
        <span class="custom-icon food-white-icon"> </span>
      </div>
      <div class="widget" routerLink="../users">
        <div class="content">
          <p class="title">
            {{ userCount?.displayName }}
          </p>

          <h3 class="info">
            {{ userCount?.value.result }}
          </h3>
        </div>
        <span class="custom-icon users-white-icon"> </span>
      </div>
    </div>

    <div class="recent-orders">
      <p class="title">{{ orderStats?.displayName }}</p>

      <div class="order-content">
        <h3 class="info" *ngIf="orderStats?.value.result.data == null">
          You have no recent orders
        </h3>
        <div *ngFor="let order of orderStats?.value.result.data" class="order">
          <span>
            Order #{{ order?.id }} -
            <span class="time">
              {{ order?.createdDate | date: "shortTime" }}
            </span>
          </span>
          <span>
            <strong>{{ order?.orderStatus }} </strong>
          </span>
        </div>
      </div>
      <a routerLink="../insights"> View all </a>
    </div>

    <div class="analytics-widget" #containerRef>
      <p class="title">Weekly Earnings</p>

      <ngx-charts-line-chart
        *ngIf="seriesGraphsArray !== 'No data available'"
        [view]="[containerRef.offsetWidth - 50, containerRef.offsetHeight]"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="multi"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
      >
      </ngx-charts-line-chart>

      <div class="no-menus" *ngIf="seriesGraphsArray === 'No data available'">
        <div class="icon-centered">
          <span class="custom-icon no-orders no-active"></span>
          <h2 class="info">No data availale</h2>
          <p class="title">
            There is currently no data availale. Please check back in at the end
            of the week.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
