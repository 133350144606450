<div class="search-container">
  <!-- <span> -->
    <span class="custom-icon search-icon"> </span>
    <input
      type="text"
      name="search"
      autocomplete="off"
      placeholder="Start searching for a drink"
      matInput
      class="custom-input"
    />
  <!-- </span> -->
</div>
<!-- [(ngModel)]="searchText" -->
