import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { fadeAnimation } from "../../animation";

@Component({
  selector: "app-app-container",
  templateUrl: "./app-container.component.html",
  styleUrls: ["./app-container.component.scss"],
  animations: [fadeAnimation],
})
export class AppContainerComponent implements OnInit {
  navigationLinks: Array<Object> = [
    { name: "Home", link: "about", icon: "home" },
    { name: "Order", link: "", icon: "receipt" },
    { name: "My Account", link: "account", icon: "account_circle" },
    // { name: 'Order History', link: 'history', icon: 'history' },
  ];
  adminLinks: Array<Object> = [];
  locId: string;

  constructor(private ls: LocalStorageService) {}

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");
    this.getAdminMenu();
  }

  getAdminMenu() {

    this.adminLinks = [
      {
        name: "Dashboard",
        link: "admin/dashboard",
        icon: "dashboard-icon",
        type: ["1", "2", "3", "4", "5", "6"],
      },
      {
        name: "Menu",
        link: "admin/menu",
        icon: "food-icon",
        type: ["1", "2", "3", "4"],
      },
      {
        name: "Live Orders",
        link: "admin/orders",
        icon: "live-icon",
        class: "live-order",
        type: ["1", "2", "3", "4", "5", "6", "7"],
      },
      {
        name: "Invoices",
        link: "admin/invoices",
        icon: "chart-icon",
        type: ["1", "2"],
      },

      // {
      //   name: "Extras",
      //   link: "admin/extras",
      //   icon: "restaurant_menu",
      //   type: ["1", "2", "3", "4"],
      // },
      {
        name: "All Orders",
        link: "admin/insights",
        icon: "all-orders-icon",
        type: ["1", "2", "3", "4", "5"],
      },
      // { name: 'Analytics', link: "admin/analytics', icon: 'bar_chart', type: '' },
      {
        name: "Employees",
        link: "admin/users",
        icon: "users-icon",
        type: ["1", "2", "3"],
      },
      {
        name: "My Account",
        link: "./account",
        icon: "settings-icon",
        type: ["1", "2", "3", "4", "5", "6", "7"],
      },
    ];
  }
}
