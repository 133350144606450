<div class="item-text">
  <h2 mat-dialog-title> Set table number</h2>
</div>

<mat-dialog-content class="mid-content">
  <mat-form-field color="primary">
    <mat-label>Table Number</mat-label>
    <mat-select [(ngModel)]="selectedValue">
      <mat-option *ngFor="let table of tableNumbers" [value]="table">
        {{ table.tableNumber }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" mat-dialog-close> Close </button>
  <button mat-button color="primary" type="button" (click)="setTable();"> Set table
  </button> <!-- [mat-dialog-close]="true"  -->
</mat-dialog-actions>