import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { CartService } from "src/app/SERVICES/cart.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  isHomepage: boolean = true;
  isExpanded: boolean = true;
  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    private cart: CartService,
    private service: ServicesService,
    private ls: LocalStorageService
  ) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        // On homepage, show the pub title and address
        // Else only show the title.
        if (val.url == "/") {
          this.isHomepage = true;
          this.showHeader = "block";
        } else if (
          val.url.indexOf("/about") > -1 ||
          val.url.indexOf("/account") > -1 ||
          val.url.indexOf("/register") > -1 ||
          val.url.indexOf("/cart") > -1 ||
          val.url.indexOf("/cart/checkout") > -1
        ) {
          this.showHeader = "none";
        } else {
          this.showHeader = "block";
          this.isHomepage = false;
        }

        if (val.url.indexOf("/admin/") > -1) {
          this.showHeader = "none";
          this.showSidebar = true;
        } else {
          this.showSidebar = false;
        }
      }

      this.resizeChanges();
    });
    this.service.isUserAdmin.subscribe((res) => {
      console.log("res in header", res);
      this.isAdmin = res;

      res === "0" ? (this.isAdmin = false) : (this.isAdmin = res);
    });
  }
  opened: boolean;
  logo: any;
  title: string;
  icon: string;
  location: string;
  contentHeight: number = 0;
  isAdmin: any;
  totalQty: number = 0;
  showHeader: string;
  showSidebar: boolean = false;
  locId: string;

  @Input() nav: Array<string>;
  @Input() navAdmin: Array<string>;
  @ViewChild("navContainer") navView: ElementRef;

  @HostListener("window:resize") onResize() {
    if (this.navView) {
      this.contentHeight = this.navView.nativeElement.offsetHeight;
    }
  }

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");

    // Gets the total quantity in from cartService and adds it to the badge.
    this.cart.currentQty.subscribe((totalQty) => (this.totalQty = totalQty));
    this.getName();
  }

  getName() {
    this.service
      .getReqCompany(
        "?action=localVenuInfo&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((companyInfo) => {
        console.log("local venue info res", companyInfo);
        this.logo = companyInfo.res.venue.companyDisplayName;
        this.title = companyInfo.res.venue.companyDisplayName;
        this.icon = this.title.charAt(0);
        this.location = companyInfo.res.address.firstLine;
        
      });
  }

  ngAfterViewChecked() {
    this.resizeChanges();
  }

  resizeChanges() {
    if (this.navView) {
      this.contentHeight = this.navView.nativeElement.offsetHeight;
    }
    this.cdr.detectChanges();
  }
}
