import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { merge, Observable, of as observableOf } from "rxjs";
import { SuccessComponent } from "../../success/success.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorComponent } from "../../error/error.component";

@Component({
  selector: "app-refunds",
  templateUrl: "./refunds.component.html",
  styleUrls: ["./refunds.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RefundsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["select", "name", "price", "qty"];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RefundsComponent>,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  expandedElement: any;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  partialRefund = false;
  refundAmnt: number = 0;
  currentPrice = this.data.totalSalePriceWithRefund;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  items: Array<any>;
  refundTooMuch: boolean = false;
  createdDate: any;
  orderId: any;

  ngOnInit(): void {}

  getOrderDetailsv2() {
    let locID = this.ls.getSessionStorage("locID");
    let sesh = this.ls.checkCookieOnly("sesh=");

    this.as
      .getReqOrder(
        "?action=getOrderDetails&key=" +
          sesh +
          "&locId=" +
          locID +
          "&orderId=" +
          this.data.id +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        console.log("res", res);
        // const ELEMENT_DATA = res.result.items;
        let i;
        let itemArray = [];

        for (i = 0; i < res.result.items.length; i++) {
          if (res.result.items[i].itemPrice !== null) {
            itemArray.push(res.result.items[i]);
            this.items = res.result.items;
          }
        }
        this.dataSource = new MatTableDataSource(itemArray);
      });
  }

  getTotalCost() {
    return this.currentPrice;
  }

  ngAfterViewInit(): void {
    this.getOrderDetailsv2();
    this.createdDate = this.data.createdDate;
    this.orderId = this.data.id;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    // let obj = [];
    // for (let i = 0; i < numRows; i++) {
    //   if (this.dataSource.data[i]?.itemQuantity > 0) {
    //     obj.push(this.dataSource.data[i]);
    //   }
    // }

    return numSelected === numRows;
    // return numSelected === obj.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  refundQty(row, input) {
    console.log("refundQty r", row);
    console.log("refundQty v", input);

    row.refundQty = input.value;
  }

  onSubmit(refund) {
    console.log("e", refund);
    let sesh = this.ls.checkCookieOnly("sesh=");

    const formData = new FormData();

    formData.append("action", "refundOrder");
    formData.append("orderId", this.orderId);
    formData.append("reason", "cant be null");

    // This is the partial refund.
    formData.append("refundAmount", this.refundAmnt.toString());

    formData.append("key", sesh.toString());
    formData.append("locId", this.ls.getSessionStorage("locID"));

    let refundData: any = [];

    // let totalRefund: number;

    for (let i = 0; i < refund.length; i++) {
      let obj: any = {};
      if (refund[i].refundQty) {
        obj.qty = refund[i].refundQty;
      } else {
        obj.qty = refund[i].itemQuantity;
      }
      obj.id = refund[i].orderItemId;

      refundData.push(obj);
    }

    // totalRefund += this.refundAmnt;

    console.log("refundData", refundData);

    formData.append("refundList", JSON.stringify(refundData));

    this.as.postReqOrder(formData).subscribe((res) => {
      console.log("res", res);
      if (res.result.status === "success") {
        this.openSnackBar(res.result.refund);
        this.dialogRef.close();
      } else if (res.result.status === "failed") {
        this.openError(res.result.message);
      }
    });
  }

  openSnackBar(refundType) {
    let refundMessage;
    switch (refundType) {
      case "PARTIAL":
        refundMessage = "A partial refund has been accepted";
        this.openSuccess(refundMessage);
        break;

      case "YES":
        refundMessage = "A full refund has been accepted";
        this.openSuccess(refundMessage);
        break;

      default:
        refundMessage = "Refund type not found. Please contact admin";
        this.openError(refundMessage);
        break;
    }
  }

  openSuccess(refundMessage) {
    let durationInSeconds = 5;
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: durationInSeconds * 1000,
      panelClass: ["snackbar-container"],
      data: refundMessage,
    });
  }

  openError(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      panelClass: "modal-containerx",
      data: [{ message: message }],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }
}
