import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  constructor(private http: HttpClient, private router: Router) {}

  categorys: Array<{
    name: string;
    img: string;
  }> = [
    { name: "Drinks", img: "../../../assets/images/drinks.jpg" },
    { name: "Snacks", img: "../../../assets/images/snacks.jpg" },
  ];

  public registeredSucces: boolean = false;
  catArray: any;
  public isLoggedIn: boolean = false;
  private AUTH_SERVER = "https://www.waitron.azui.co.uk";
  public reload: number;
  public termsUTD: boolean = false;
  public redirectUrl: string;
  public userId: string = ""; // TODO -- Remove this from prod...
  public sesh: any;
  public isAdmin: any = false;
  public isUserAdmin: BehaviorSubject<any> = new BehaviorSubject<any>("0");
  public adminRoles = ["1", "2", "3", "4", "5", "6", "7"];

  // TODO I could just use the above and change it to param? Check...
  getOrderHisotry(title: string) {
    let path = "assets/mock/" + title + ".json";
    return this.http.get(path, { withCredentials: true });
  }

  // TODO adjust for when after order item DB is complete from BE...
  // I have no idea how this'll work but we only want send back only the current order history item, not all of them.
  getOrderItem(title: string) {
    let path = "assets/mock/" + title + ".json";
    return this.http.get(path, { withCredentials: true });
  }

  register(form: FormData) {
    let path = "/php/login/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form);
  }

  forgotPassword(form: FormData) {
    let path = "/php/login/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form);
  }

  passwordReset(form: FormData) {
    let path = "/php/login/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form);
  }

  updateOrder(form: FormData) {
    let path = "/php/order/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form);
  }

  getSeshInfo() {
    let path = "/php/login/function.php?action=getSessionInfo";
    return this.http.get<any>(`${this.AUTH_SERVER}` + path);
  }

  checkSesh(data) {
    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/json; charset=utf-8"
      ),
      withCredentials: true,
    };

    let path = "/php/login/function.php";
    return this.http
      .post<any>(`${this.AUTH_SERVER}` + path, data, options)
      .pipe(
        map((res) => {
          console.log("resDone");
          this.reload = res[0]?.reload;
          if (this.reload === 0) {
            this.isLoggedIn = true;
            if (res[0].userType !== "0") {
              this.isAdmin = res[0].userType;
              return true;
            } else if (res[0].userType === "0") {
              return true;
            }
          } else if (this.reload === 3) {
            this.router.navigateByUrl("/");
            this.isLoggedIn = false;
            this.termsUTD = false;
            document.cookie =
              "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie =
              "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            return false;
          }
        })
      );
  }

  checkIfAdmin(): Observable<any> {
    let data = this.checkCookieOnly("sesh=");

    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/json; charset=utf-8"
      ),
      withCredentials: true,
    };

    let path = "/php/login/function.php";
    return this.http
      .post<any>(`${this.AUTH_SERVER}` + path, data, options)
      .pipe(
        map((res) => {
          this.reload = res[0]?.reload;
          if (this.reload === 0) {
            this.isLoggedIn = true;
            if (this.adminRoles.indexOf(res[0].userType) > -1) {
              this.isAdmin = res[0].userType;
              return res[0].userType;
            } else {
              return false;
            }
          } else {
            this.router.navigateByUrl("/");
            this.isLoggedIn = false;
            this.termsUTD = false;
            document.cookie =
              "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie =
              "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            return false;
          }
        })
      );
  }

  checkIfLoggedIn(): Observable<any> {
    let data = this.checkCookieOnly("sesh=");

    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/json; charset=utf-8"
      ),
      withCredentials: true,
    };

    let path = "/php/login/function.php";
    return this.http
      .post<any>(`${this.AUTH_SERVER}` + path, data, options)
      .pipe(
        map((res) => {
          this.reload = res[0]?.reload;
          if (this.reload === 0) {
            this.isLoggedIn = true;
            return true;
          } else if (this.reload === 3) {
            return false;
          }
        })
      );
  }

  logout(data: object) {
    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/json; charset=utf-8"
      ),
      withCredentials: true,
    };

    return this.http
      .post<any>(`${this.AUTH_SERVER}/php/login/function.php`, data, options)
      .subscribe(
        (res) => {
          if (res.status === "success") {
            this.isLoggedIn = false;
            document.cookie =
              "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie =
              "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        },
        (err) => console.log("err", err)
      );
  }

  arr: any;
  seshKey: any;
  data: object;

  checkCookieOnly(cookieName: string): any {
    document.cookie.split(";").filter((item) => {
      if (item.startsWith(cookieName) || item.startsWith(" " + cookieName)) {
        this.arr = item.match(/sesh=(.*)/);
        // Did it match?
      }
    });

    let locID = sessionStorage.getItem("locID");

    if (this.arr != null) {
      let sesh = this.arr[1];
      this.seshKey = sesh;
      this.data = {
        key: sesh,
        action: "checkSessionLive",
        userId: this.userId,
        locId: locID,
      };

      return this.data;
    } else {
      return false;
    }
  }

  // for get request GET THIS WORKING
  getReq(action) {
    let path = "/php/waitron/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  getReqLogin(action) {
    let path = "/php/login/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  postReqLogin(form: FormData) {
    let path = "/php/login/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form);
  }

  getReqOrder(action) {
    let path = "/php/order/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  getReqCompany(action) {
    let path = "/php/company/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  postReqOrder(form) {
    let path = "/php/order/function.php";
    return this.http.post(`${this.AUTH_SERVER}` + path, form);
  }

  PDFOrder() {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    let path = "/php/order/function.php";
    return this.http.get(`${this.AUTH_SERVER}` + path, {
      headers: headers,
      responseType: "blob",
    });
  }
}
