<div class="container">
  <div class="inner-container" *ngFor="let item of items">
    <div class="order-header">
      <div class="order-title">
        <a routerLink="/history">
          <mat-icon aria-label="Next button title" fontSet="material-icons-outlined"> keyboard_arrow_left
          </mat-icon>
        </a>

        <h3> {{ item.orderNo }} </h3>

        <div> </div>
      </div>

      <h5 class="date"> {{ item.date | date:'medium' }} </h5>
    </div>

    <div class="item-container">
      <div class="populated-item">
        <h3 class="title"> Items </h3>
        <div class="cart-item" *ngFor="let i of item.items">
          <!-- (click)='ontap(item);' -->
          <div class="qty">
            <h3>{{ i.qty }}x</h3>
            <!-- <h3>1x</h3> -->
          </div>

          <div class="item">
            <h3> {{ i.drink }} </h3>
            <h5 class="info"> {{ i.portion }} </h5>
            <h5 class="info" *ngFor="let x of item.extras">{{ x }}</h5>
            <!-- <h3> drank </h3>
            <h5 class="info"> Portion </h5>
            <h5 class="info"> extras </h5> -->
          </div>

          <div class="price">
            <h3>{{ i .price | currency:'GBP' }}</h3>
            <!-- <h3>{{ 4 | currency:'GBP' }}</h3> -->
          </div>
        </div>
      </div>
    </div>

    <div class="checkout-cta" #checkoutContainer>
      <!-- *ngIf="items.length > 0" -->
      <!--
      <div class="table-number"> [ngClass]="!tableNotSelected ? '' : 'error'"
        <h4 class="title-number"> Table Number</h4>

        <h4 *ngIf="!selectedTable"> Not set </h4>
        <h4> Not set </h4> *ngIf="!selectedTable"
        <h4 *ngIf="selectedTable"> {{ selectedTable }} </h4>
        <h4 *ngIf="selectedTable"> Tabele? </h4>

        <mat-icon aria-label="Next button title"> keyboard_arrow_right
        </mat-icon>
      </div>
    -->

      <div class="payment-method">
        <h5 class="title-number"> Paid by</h5>
        <h5> Debit/Credit Card </h5>
      </div>
      <div class="total">
        <h3> Total</h3>
        <h3> {{ totalPrice | currency:'GBP' }} </h3>
        <!-- <h3> {{ 1 | currency:'GBP' }} </h3> -->
      </div>
      <div class="checkout-button">
        <button mat-button color="primary"> Re-order items </button> <!-- (click)="checkout();" -->
      </div>
    </div>
  </div>

</div>