import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appExpiryDate]'
})
export class ExpiryDateDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');

    if (trimmed.length > 5) {
      trimmed = trimmed.substr(0, 5);
    }

    trimmed = trimmed.replace(/\//g, '');

    let numbers = [];

    numbers.push(trimmed.substr(0, 2));
    if (trimmed.substr(2, 2) != "") {
      numbers.push(trimmed.substr(2, 2))
    }

    input.value = numbers.join("/");
  }

}
