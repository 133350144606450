import { Component, OnInit } from "@angular/core";
import { ServicesService } from "src/app/SERVICES/services.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Status } from "src/app/INTERFACES/status-message";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { LoginService } from "src/app/SERVICES/common/login.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private service: ServicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private seshService: LocalStorageService,
    private ls: LocalStorageService,
    private loginService: LoginService
  ) {}

  loginForm: FormGroup;
  isSubmitted: boolean = false;
  statusFailed: boolean = false;
  status: Status = {
    message: "",
  };
  locId: string;

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");

    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      action: "login",
    });

    // this.googleInit();
  }

  get formControls() {
    return this.loginForm.controls;
  }

  login() {
    const formData = new FormData();
    formData.append("action", this.loginForm.value.action);
    formData.append("email", this.loginForm.value.email);
    formData.append("password", this.loginForm.value.password);
    formData.append("locId", this.locId);
    formData.append("userId", this.service.userId);

    this.loginService.login(formData);

    // add if to not subscibe if email and password isn't entered
    // this.service.postReqLogin(formData).subscribe(
    //   (res) => {
    //     if (res.login.status === "success") {
    //       this.seshService.setCookie("sesh=", res.login.result.user);
    //       this.service.isLoggedIn = true;

    //       if (res.login.result?.type != 0) {
    //         this.service.isAdmin = res.login.result?.type;
    //         this.service.isUserAdmin.next(res.login.result?.type);

    //         switch (res.login.result?.type) {
    //           case "1":
    //           case "2":
    //           case "3":
    //           case "4":
    //           case "5":
    //           case "6":
    //             this.router.navigate(["c", this.locId, "admin", "dashboard"]);
    //             break;
    //           case "7":
    //             this.router.navigate(["c", this.locId, "admin", "orders"]);
    //             break;
    //           default:
    //             console.log("this should be a customer user");
    //             break;
    //         }
    //       } else if (res.login.result?.type == 0) {
    //         // Just a normal user
    //         this.router.navigate(["c", this.locId]);
    //       } else {
    //         let fromCheckout = this.seshService.getSessionStorage(
    //           "fromCheckout"
    //         );
    //         if (fromCheckout) {
    //           this.router.navigate(["c", this.locId, "cart", { CFL: true }]);
    //           this.seshService.removeSessionStorage("fromCheckout");
    //         } else {
    //           this.router.navigate(["c" + this.locId]);
    //         }
    //       }
    //     } else if (res.login.status === "failed") {
    //       this.statusFailed = true;
    //       this.status.message = res.login.message; //TODO Look at this.
    //     }
    //   },
    //   (err) => console.log("err", err)
    // );
  }

  getSeshInfo() {
    this.service.getSeshInfo().subscribe((x) => {});
  }
}
