import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UpdateStatusComponent } from "src/app/MODALS/update-status/update-status.component";
import { AdminComponent } from "src/app/PAGES/admin/admin.component";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-admin-order",
  templateUrl: "./admin-order.component.html",
  styleUrls: ["./admin-order.component.scss"],
})
export class AdminOrderComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public admin: AdminComponent,
    private ls: LocalStorageService,
    private service: ServicesService
  ) {}

  @Input() orderItems: any;
  @Input() statusList: any;

  ngOnInit(): void {}

  openDialog(currentStatus, orderId) {
    const dialogRef = this.dialog.open(UpdateStatusComponent, {
      panelClass: "modal-container",
      data: [this.statusList, currentStatus, orderId],
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.admin.getService();
      console.log("Dialog result:", result);
    });
  }

  completed(currentStatus, orderId) {
    let sesh = this.ls.checkCookieOnly("sesh=").toString();
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("action", "updateOrder");
    formData.append("orderId", orderId);
    formData.append("statusId", "4");
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    formData.append("key", sesh);

    this.service.updateOrder(formData).subscribe((res) => {
      if (res.result === "success") {
        // window.location.reload();
      } else if (res.status === "failed") {
      }
    });
  }
}
