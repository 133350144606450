<!-- <div class="breadcrumbs">
  <a routerLink="">
    <mat-icon aria-hidden="false" aria-label="home icon" fontSet="material-icons-outlined">home</mat-icon>
  </a>

  <a *ngFor="let breadcrumb of breadcrumbs" routerLink="/{{mainPath}}/{{breadcrumb.link}}" routerLinkActive>
    <mat-icon aria-label="Next button title" fontSet="material-icons-outlined"> keyboard_arrow_right
    </mat-icon>

    <h3> {{breadcrumb.title}} </h3>
  </a>
</div> -->

<div class="breadcrumbs">
  <a (click)="back()">
    <mat-icon aria-label="back button title" fontSet="material-icons-outlined">
      keyboard_backspace
    </mat-icon>

    <!-- <h3> {{breadcrumb.title}} </h3> -->
    <p class="title"> {{lastBreadCrumb}} </p>
  </a>
</div>
