<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="bredcrumb-header">
    <a routerLink="../">
      <mat-icon aria-label="Back Button" fontSet="material-icons-outlined">
        west</mat-icon
      >
      <p class="title">My Basket</p>
    </a>

    <h3 class="title"> The Waitron Inn </h3>
  </div>

  <div
    class="cart-container"
    [ngStyle]="{ 'padding-bottom': contentHeight + 'px' }"
    *ngIf="items.length > 0"
  >
    <div class="populated-cart">
      <p class="title basket">My Basket</p>

      <div class="table-wrapper">
        <p class="title current-order">Current order</p>

        <div
          class="set-table"
          (click)="openDialog()"
          [ngClass]="!tableNotSelected ? '' : 'error'"
        >
          <span class="material-icons" *ngIf="!selectedTable">
            error_outline
          </span>

          <p class="title">Table Number:</p>

          <p class="title" *ngIf="selectedTable">{{ selectedTable }}</p>

          <span class="material-icons"> keyboard_arrow_down </span>
        </div>
      </div>

      <div class="current-cart">
        <div class="cart-item" *ngFor="let item of items; let i = index">
          <!-- (click)="ontap(item)" -->
          <div class="col-1">
            <div class="item">
              <p class="title">{{ item.name }}</p>
              <h5 class="info">{{ item.optionTitle }}</h5>
              <h5 class="info" *ngFor="let x of item.extras">{{ x?.name }}</h5>
            </div>
          </div>

          <div class="col-2">
            <div class="qty">
              <div class="">
                <button
                  type="button"
                  aria-label="Add qty one"
                  mat-icon-button
                  class="subtract"
                  (click)="subOne(item, i)"
                >
                  <mat-icon
                    aria-label="Remove one icon"
                    color="primary"
                    fontSet="material-icons-outlined"
                  >
                    remove
                  </mat-icon>
                </button>
              </div>
              <p>{{ item.qty }}</p>
              <div>
                <button
                  type="button"
                  aria-label="Add qty one"
                  mat-icon-button
                  class="add"
                  (click)="addOne(item)"
                >
                  <mat-icon
                    aria-label="Add one icon"
                    color="primary"
                    fontSet="material-icons-outlined"
                  >
                    add
                  </mat-icon>
                </button>
              </div>
            </div>

            <div class="price-container">
              <p class="price">{{ item.price * item.qty | currency: "GBP" }}</p>
            </div>
          </div>

          <div
            class="delete-container"
            [ngClass]="showDelete && currentShowDelete == i ? '' : 'hide'"
          >
            <p class="title">Remove {{ item.name }}?</p>

            <div class="button-container">
              <button mat-button color="primary" (click)="removeItem(item)">
                Yes
              </button>
              <button mat-button class="no" (click)="cancel()">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="checkout-cta" #checkoutContainer *ngIf="items.length > 0">
    <div class="total">
      <div class="promotions" *ngIf="hasPromotion">
        <div id="subtotal">
          <h3 class="title info">Sub total</h3>
          <h3 class="info">{{ totalPrice | currency: "GBP" }}</h3>
        </div>

        <h3 class="title info">Promotions</h3>

        <div class="promo-applied info" *ngFor="let promo of promotions">
          <h4 class="info">{{ promo?.promotionName }}</h4>

          <h4 class="info">{{ promo.discount | currency: "GBP" }}</h4>
        </div>
      </div>

      <div id="total">
        <h3 class="info">Total</h3>
        <h3 class="info">{{ totalPrice + discountPrice | currency: "GBP" }}</h3>
      </div>
    </div>
    <div class="checkout-button">
      <button
        mat-button
        color="primary"
        (click)="checkPromo()"
        *ngIf="!checkedPromo"
      >
        Check Promotions
      </button>
      <button
        mat-button
        color="primary"
        (click)="toCheckout()"
        *ngIf="checkedPromo"
      >
        Checkout
      </button>
    </div>
  </div>

  <div class="empty-cart" *ngIf="items.length === 0">
    <div class="icon-centered">
      <span class="custom-icon basket-background no-active"></span>
      <h2 class="info">Your basket is empty</h2>
      <p class="title">
        Looks like you don’t have any items in your basket, check our menu to
        order.
      </p>

      <a routerLink="../">
        <button mat-button color="primary">Order Now</button>
      </a>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
