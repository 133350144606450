<div class="item-text">
  <h2 mat-dialog-title>New Category</h2>
  <!-- <h5 mat-dialog-title> Add New category </h5> -->
</div>

<form [formGroup]="addCategory" id="add-form" (ngSubmit)="add()">
  <mat-dialog-content class="mid-content">
    <div id="category">
      <div class="cat-container">
        <h3>Category</h3>
        <mat-form-field appearance="outline" required>
          <!-- <mat-label>Category</mat-label> -->
          <mat-select
            formControlName="selectedCat"
            name="category"
            [(ngModel)]="selectedValue"
            placeholder="Select a category"
          >
            <mat-option value="0">
              <!-- *ngFor="let pc of parentCatgories" [value]="pc.id" -->
              Add New Category
            </mat-option>
            <mat-option *ngFor="let pc of parentCatgories" [value]="pc.id">
              <!-- *ngFor="let pc of parentCatgories" [value]="pc.id" -->
              {{ pc.category }}
            </mat-option>
          </mat-select>
          <mat-hint class="hint" align="start"
            ><strong
              >Choose an already existing category or create a new one.</strong
            >
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="cat-container new-cat" *ngIf="selectedValue == '0'">
        <h3>New Category</h3>
        <mat-form-field appearance="outline">
          <!-- <mat-label>New Category</mat-label> -->
          <input
            matInput
            type="text"
            placeholder="E.g. Wine"
            formControlName="newCategory"
            name="New Category"
          />
          <mat-hint class="hint" align="start"
            ><strong>Create a new category.</strong>
          </mat-hint>
          <!-- <mat-error *ngIf="formControls.email.errors">Email must be a valid email address</mat-error> -->
        </mat-form-field>
      </div>

      <div>
        <h3>Edit Icon</h3>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            formControlName="newIcon"
            name="Edit Icon"
          />
          <mat-hint class="hint" align="start"
            ><strong>Edit the icon displayed.</strong>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div id="sub-cat">
      <h3>Sub Category</h3>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          placeholder="E.g. White"
          formControlName="subCategory"
        />
        <mat-hint class="hint" align="start"
          ><strong>Create a sub category (optional)</strong>
        </mat-hint>
        <!-- <mat-error *ngIf="formControls.email.errors">Email must be a valid email address</mat-error> -->
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" type="button" mat-dialog-close>
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      type="submit"
      value="add"
      [disabled]="addCategory.invalid"
    >
      Add
    </button>

    <!-- [mat-dialog-close]="true"  -->
  </mat-dialog-actions>
</form>
