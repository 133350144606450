<div class="container">
  <div class="bredcrumb-header">
    <a routerLink="../">
      <mat-icon aria-label="Back Button" fontSet="material-icons-outlined">
        west</mat-icon
      >
      <p class="title">Checkout</p>
    </a>

    <h3 class="title">The Waitron Inn</h3>
  </div>

  <div>
    <!-- <form [formGroup]="registerForm" id="login-form" (ngSubmit)="submitDetails($event)">
      

      <div class="button-container">
        <button mat-flat-button color="primary" type="submit" value="Register" [disabled]="registerForm.invalid">
          Sign up
        </button>
      </div>

    </form> -->
  </div>
  <mat-vertical-stepper linear #stepper>
    <mat-step [stepControl]="detailsFormGroup">
      <form [formGroup]="detailsFormGroup">
        <ng-template matStepLabel> Card details </ng-template>
        <div id="name">
          <mat-form-field required>
            <mat-label>Cardholder name</mat-label>
            <input matInput type="text" formControlName="firstName" />
            <mat-error *ngIf="detailsFormControls.firstName.errors"
              >Cardholder name is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Card number</mat-label>
            <input
              matInput
              type="text"
              placeholder="1234"
              formControlName="cardNumber"
              pattern="[0-9-]*"
              maxlength="19"
              minlength="12"
            />
            <!-- appCardNumber -->
            <mat-error *ngIf="detailsFormControls.cardNumber.errors"
              >Card number is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Expiry Date</mat-label>
            <input
              matInput
              type="text"
              placeholder="MM/YY"
              formControlName="expiry"
              pattern="[0-9/]*"
              maxlength="5"
              minlength="5"
              appExpiryDate
            />
            <mat-error *ngIf="detailsFormControls.expiry.errors"
              >Expiry date required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Security code</mat-label>
            <input
              matInput
              type="text"
              placeholder="123"
              formControlName="secCode"
              pattern="[0-9]*"
              maxlength="3"
              minlength="3"
            />
            <mat-error *ngIf="detailsFormControls.secCode.errors"
              >Security code is required</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <button mat-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="addressFormGroup">
      <form [formGroup]="addressFormGroup">
        <ng-template matStepLabel> Billing address </ng-template>

        <div id="name">
          <mat-form-field required>
            <mat-label>First name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Joe"
              formControlName="firstName"
            />
            <mat-error *ngIf="addressFormControls.firstName.errors"
              >First name is required</mat-error
            >
          </mat-form-field>

          <mat-form-field required>
            <mat-label>Last name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Bloggs"
              formControlName="lastName"
            />
            <mat-error *ngIf="addressFormControls.lastName.errors"
              >Last name is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Address</mat-label>
            <input
              matInput
              type="text"
              placeholder="5 London Rd"
              formControlName="address"
            />
            <mat-error *ngIf="addressFormControls.address.errors"
              >Address is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Post code</mat-label>
            <input
              matInput
              type="text"
              placeholder="AB1 2CD"
              formControlName="postCode"
            />
            <mat-error *ngIf="addressFormControls.postCode.errors"
              >Post code is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>City</mat-label>
            <input
              matInput
              type="text"
              placeholder="Portsmouth"
              formControlName="city"
            />
            <mat-error *ngIf="addressFormControls.city.errors"
              >City is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field required>
            <mat-label>Country</mat-label>
            <input
              matInput
              type="text"
              placeholder="Portsmouth"
              formControlName="country"
            />
            <mat-error *ngIf="addressFormControls.country.errors"
              >Country is required</mat-error
            >
          </mat-form-field>
        </div>

        <div>
          <button mat-button color="accent" matStepperPrevious>Back</button>
          <button mat-button color="primary" matStepperNext>Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Confirmation</ng-template>
      <p class="title">Order Summary:</p>
      <div class="order-summary-container">
        <div class="order-summary" *ngFor="let c of cartSummary">
          <div class="qty">
            <p class="title">{{ c.qty }}x</p>
          </div>

          <div class="info">
            <span class="title"> {{ c.name }}, </span>

            <span class="desc">
              {{ c.portion }},
              <span *ngFor="let e of c?.extras"> {{ e.name }}, </span>
              {{ c.price | currency: "GBP" }}
            </span>
          </div>
        </div>

        <div class="dashed"></div>

        <div id="subtotal">
          <h3 class="title info">Sub total</h3>
          <h3 class="info">{{ totalPrice | currency: "GBP" }}</h3>
        </div>
      </div>

      <div>
        <button mat-button matStepperPrevious color="accent">Back</button>
        <button mat-button color="primary" (click)="submitDetails($event)">
          Continue
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>

  <!-- <div class="button-container">
    <button mat-flat-button color="primary" (click)="submitDetails($event)">
      Sign up
    </button>
  </div> -->
</div>

<form
  [formGroup]="tds"
  (ngSubmit)="onSubmit()"
  method="post"
  action
  id="pa-form"
>
  <input type="hidden" name="PaReq" formControlName="PaReq" />
  <input type="hidden" name="TermUrl" formControlName="TermUrl" />
  <input type="hidden" name="MD" formControlName="MD" />
</form>

<form [formGroup]="tdsvt" method="post" id="cr-form" action>
  <input type="hidden" name="creq" formControlName="creq" />
  <input
    type="hidden"
    name="threeDSSessionData"
    formControlName="transactionId"
  />
  <p>Please click button below to proceed to 3D secure.</p>
  <input type="submit" value="Go" />
</form>

<!-- <form ngNoForm [formGroup]="myForm" action="http://test.local/process_post.php" target="_blank" method="POST">
  <input formControlName="alpha" name="alpha"/>
  <input formControlName="beta" name="beta"/>
  <button type="submit" [disabled]="!myForm.valid" onclick="submit()">SEND</button>
</form> -->

<!-- <iframe src="3DRedirect.asp" name="3Diframe"> 
  <form action="${acsUrl}" method="post"> 
  <input type="hidden" name="creq" value="${creq}" /> 
  <input type="hidden" name="threeDSSessionData" value="${threeDSSessionData}" />
  <p>Please click button below to proceed to 3D secure.</p> <input type="submit" value="Go"/> 
  </form>
</iframe> -->
