import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { ServicesService } from "./services.service";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor(private service: ServicesService) {}

  public seshKey: string;

  // To set the item in local storage.
  // Params k = Key, v = Value
  setLocalStorage(k, v) {
    localStorage.setItem(k, v);
  }

  getLocalStorage(k) {
    return localStorage.getItem(k);
  }

  removeLocalStorage(k) {
    localStorage.removeItem(k);
    return localStorage.removeItem(k);
  }

  setSessionStorage(k, v) {
    sessionStorage.setItem(k, v);
  }

  getSessionStorage(k) {
    return sessionStorage.getItem(k);
  }

  removeSessionStorage(k) {
    sessionStorage.removeItem(k);
    return sessionStorage.removeItem(k);
  }

  setCookie(cookieTitle: string, cookie: string) {
    document.cookie = cookieTitle + cookie + "; Path=/; SameSite=None; Secure";
  }

  // remove localstorage tokens
  deleteCookie(name: string) {
    document.cookie = name + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
  data: object;
  arr;

  // Check if cookie exists.
  checkCookie(cookieName: string) {
    document.cookie.split(";").filter((item) => {
      if (item.startsWith(cookieName) || item.startsWith(" " + cookieName)) {
        this.arr = item.match(/sesh=(.*)/);
        // Did it match?
      } else {
        this.arr = null;
      }
    });

    if (this.arr != null) {
      let sesh = this.arr[1];
      this.seshKey = sesh;
      let locID = this.getSessionStorage("locID");

      this.data = {
        key: sesh,
        action: "checkSessionLive",
        locId: locID,
        userId: this.service.userId,
      };

      let stringified = JSON.stringify(this.data);

      return this.service.checkSesh(this.data);
    } else {
      return false;
    }
  }

  checkCookieOnly(cookieName: string): Observable<any> {
    document.cookie.split(";").filter((item) => {
      if (item.startsWith(cookieName) || item.startsWith(" " + cookieName)) {
        this.arr = item.match(/sesh=(.*)/);
        // Did it match?
      }
    });

    if (this.arr != null) {
      let sesh = this.arr[1];
      this.seshKey = sesh;
      this.data = {
        key: sesh,
        action: "checkSessionLive",
        userId: this.service.userId,
      };

      return sesh;
    } else {
      return null;
    }
  }

  getCookie(cookieName: string): Observable<any> | boolean {
    this.arr = null;
    document.cookie.split(";").filter((item) => {
      if (item.startsWith(cookieName) || item.startsWith(" " + cookieName)) {
        // this.arr = item.match(/cookieName=(.*)/);
        var re = new RegExp(cookieName + "(.*)");
        this.arr = item.match(re);
      }
    });

    if (this.arr != null) {
      return this.arr[1];
    } else {
      return false;
    }
  }
}
