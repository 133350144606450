import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { MatDialog } from "@angular/material/dialog";
import { RefundsComponent } from "src/app/MODALS/ADMIN/refunds/refunds.component";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";

// const ELEMENT_DATA: any[] = [];

@Component({
  selector: "app-insights",
  templateUrl: "./insights.component.html",
  styleUrls: ["./insights.component.scss"],
  providers: [DatePipe],
})
export class InsightsComponent implements OnInit {
  constructor(
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    "orderId",
    "tableNo",
    "status",
    "totalPrice",
    "date",
    "menu",
  ];
  dataSource = new MatTableDataSource();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  todaysDate = new Date();

  sesh = this.ls.checkCookieOnly("sesh=");
  locId = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;

  ngOnInit(): void {
    this.getOrders();

    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  getOrders() {
    let locID = this.ls.getSessionStorage("locID");
    let sesh = this.ls.checkCookieOnly("sesh=");

    this.as
      .getReqOrder(
        "?action=getOrdersbyDays&key=" +
          sesh +
          "&locId=" +
          locID +
          "&days=" +
          30 +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        console.log("res", res);
        const ELEMENT_DATA = res.result.data;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;

        // if (res.result !== "No data available") {
        //   const ELEMENT_DATA = res.result;
        //   this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        //   this.dataSource.paginator = this.paginator;
        //   this.emptyTable = false;
        // } else {
        //   this.emptyTable = true;
        // }
      });
  }

  filterId(event: Event) {
    this.dataSource.filterPredicate = function (
      data: any,
      filter: string
    ): boolean {
      return data.id.toLowerCase().includes(filter);
    };

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterStatus(filterValue: string) {
    this.dataSource.filterPredicate = function (
      data: any,
      filter: string
    ): boolean {
      if (filter.length) {
        return filter.includes(data.orderStatus);
      } else {
        return true;
      }
    };

    this.dataSource.filter = filterValue;
  }

  addEvent(filterValue: string, event, range) {
    this.datepipe = new DatePipe("en");

    this.dataSource.filterPredicate = function (
      data: any,
      filter: string
    ): boolean {
      if (event.value != undefined) {
        var dateFrom = range.start.toLocaleDateString("en-GB");
        var dateTo = range.end.toLocaleDateString("en-GB");

        let dateCheck: any = new Date(data.createdDate);
        dateCheck = dateCheck.toLocaleDateString("en-GB");

        var d1: any = dateFrom.split("/");
        var d2: any = dateTo.split("/");
        var c = dateCheck.split("/");

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

        console.log(check >= from && check <= to);
        // return !filter || data.createdDate.includes(filter);
        return check >= from && check <= to;
      }
    };

    this.dataSource.filter = filterValue;
  }

  // applyCheckbox(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  openDialog(item) {
    console.log("item", item);
    const dialogRef = this.dialog.open(RefundsComponent, {
      panelClass: "modal-container",
      data: item,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }
}
