<div class="snackbar-edit">
  <div class="item">
    <h2> {{ data.name }}</h2>
    <h5 class="info"> {{ data.portion }} </h5>
    <h5 class="info" *ngFor="let x of data.extras">{{ x?.name }}</h5>
  </div>

  <div class="increment">

    <button type="button" aria-label="Add qty one" mat-icon-button (click)="subOne()">
      <mat-icon aria-label="Remove one icon" color="primary"> remove_circle_outline </mat-icon>
    </button>

    <h2> {{ qty }} </h2>

    <button type="button" aria-label="Add qty one" mat-icon-button (click)="addOne()">
      <mat-icon aria-label="Add one icon" color="primary"> add_circle_outline </mat-icon>
    </button>
    
    <h5> {{ price | currency:'GBP' }} </h5>
  </div>

  <div class="button-group">
    <button mat-button color="primary" *ngIf="qty > 0" (click)="updateItem()"> Update </button>
    <button mat-button color="warn" *ngIf="qty === 0" (click)="updateItem()"> Remove Item </button>
    <!-- <button mat-button color="accent" (click)="snackBarRef.dismiss()"> Close </button> -->
  </div>
</div>

<div class="overlayTing"></div>