import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/my-error-state-matcher';
import { Status } from 'src/app/INTERFACES/status-message';
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  constructor(
    private service: ServicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private ls: LocalStorageService) {
    this.getRouteParams();
  }

  queryParams: Params;
  resetPassForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  statusFailed: boolean = false;
  forgotSuccess: boolean = false;
  hasNumber: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasSymbol: boolean;
  hasEight: boolean;
  status: Status = {
    message: ''
  }

  ngOnInit(): void {
    this.resetPassForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      oneTimeCode: ['', [Validators.required]],
      password: ['', [Validators.required, this.matcher.strong]],
      confirmPassword: ['', [Validators.required]],
      action: 'resetLostPassword'
    },
      { validator: this.checkPasswords('password', 'confirmPassword') });

    if (this.queryParams.email) {
      this.resetPassForm.controls['email'].setValue(this.queryParams.email);
      this.resetPassForm.controls['oneTimeCode'].setValue(this.queryParams.oneTimeCode);
    }
  }

  get formControls() {
    return this.resetPassForm.controls;
  }

  checkPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      this.hasNumber = MyErrorStateMatcher.hasNumber
      this.hasUpper = MyErrorStateMatcher.hasUpper
      this.hasLower = MyErrorStateMatcher.hasLower
      this.hasSymbol = MyErrorStateMatcher.hasSymbol
      this.hasEight = MyErrorStateMatcher.hasEight

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  getRouteParams() {
    // URL query parameters
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }

  resetPass(event) {
    const formData = new FormData();

    formData.append('action', this.resetPassForm.value.action);
    formData.append('email', this.resetPassForm.value.email);
    formData.append('password', this.resetPassForm.value.password);
    formData.append('oneTimeCode', this.resetPassForm.value.oneTimeCode);
    formData.append('locId', this.ls.getSessionStorage('locID'));

    this.service.passwordReset(formData).subscribe(
      (res) => {
        if (res.status === 'success') {
          this.forgotSuccess = true;
          this.statusFailed = false;
          this.status.message = res.message

          setTimeout(() => {
            this.router.navigateByUrl('/account');
          }, 3000);
        } else if (res.status === 'failed') {
          this.statusFailed = true;
          this.status.message = res.message
        }
      },
      (err) => console.log('err', err)
    )
  }

}
