import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { AddMenuComponent } from "src/app/MODALS/ADMIN/add-menu/add-menu.component";
import { DeleteProductComponent } from "src/app/MODALS/ADMIN/delete-product/delete-product.component";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-admin-config",
  templateUrl: "./admin-config.component.html",
  styleUrls: ["./admin-config.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AdminConfigComponent implements OnInit {
  constructor(
    private as: AdminService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private service: ServicesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) {}

  extras: any[] = [];

  ngOnInit(): void {
    this.showExtras();
  }

  showExtras() {
    let key = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    let request =
      "?action=getExtras&key=" +
      key +
      "&locId=" +
      locID +
      "&userId=" +
      this.service.userId;
    this.spinner.show();

    this.as
      .getReq(request)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res: any) => {
        console.log("res", res);
        this.extras = res.result.extras;
      });
  }

  addNewMenu(title, item) {
    const dialogRef = this.dialog.open(AddMenuComponent, {
      panelClass: "menu-modal-container",
      disableClose: true,
      data: [{ title: title, item: item }],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
      if (result === undefined) {
        this.showExtras();
      }
    });
  }

  setCatSesh(id, extra) {
    this.ls.setSessionStorage("menuIdAdmin", id);
  }

  toggled(id, e, c, action) {
    let sesh = this.ls.checkCookieOnly("sesh=").toString();
    let locID = this.ls.getSessionStorage("locID").toString();

    const formData = new FormData();
    formData.append("action", "enableDisableValue");
    formData.append("update", action);
    formData.append("col", "enabled");
    formData.append("id", id);
    formData.append("key", sesh);
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    if (e.checked) {
      formData.append("value", "1");
    } else {
      formData.append("value", "0");
    }

    this.spinner.show();
    this.as
      .postReq(formData)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        if (res.reload === 0) {
          if (res.result === "success") {
            c.enabled === "0" ? (c.enabled = "1") : (c.enabled = "0");
          }
        } else if (res.reload === 3) {
          this.router.navigateByUrl("/");
          this.service.isLoggedIn = false;
          document.cookie =
            "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
      });
  }

  deleteProduct(item, id, deleteItem) {
    const dialogRef = this.dialog.open(DeleteProductComponent, {
      panelClass: "delete-product-container",
      data: [item, id, deleteItem],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);

      if (result === undefined) {
        // this.getCat();
        this.showExtras();
      }
    });
  }
}
