<div class="login-container">
  <div class="header">
    <a routerLink="../">
      <mat-icon aria-label="Back Button" fontSet="material-icons-outlined">
        west</mat-icon
      >
      <p class="title">My Account</p>
    </a>
  </div>

  <div id="login">
    <div id="login-container">
      <div class="icon-container">
        <span class="custom-icon sign-up-icon"> </span>
      </div>

      <div class="title-container">
        <h2 class="info">Get started</h2>
        <p>
          <span> Already have an account? </span>
          <a routerLink="../account"> Sign in </a>
        </p>
      </div>

      <!-- error message goes here -->
      <app-info-box
        *ngIf="statusFailed"
        [ngClass]="{ 'error-box': statusFailed }"
        [status]="status"
      ></app-info-box>

      <form
        [formGroup]="registerForm"
        id="login-form"
        (ngSubmit)="register($event)"
      >
        <div id="name">
          <mat-form-field required>
            <mat-label>First name</mat-label>
            <input
              matInput
              type="text"
              placeholder="John"
              formControlName="firstName"
            />
            <mat-error *ngIf="formControls.firstName.errors"
              >First name is required</mat-error
            >
          </mat-form-field>

          <mat-form-field required>
            <mat-label>Last name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Doe"
              formControlName="lastName"
            />
            <mat-error *ngIf="formControls.lastName.errors"
              >Last name is required</mat-error
            >
          </mat-form-field>
        </div>

        <div class="dob">
          <mat-form-field required>
            <mat-label>Date of birth</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="dob"
              placeholder="DD/MM/YYYY"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              startView="multi-year"
              [startAt]="startDate"
            ></mat-datepicker>

            <mat-date-range-picker #picker></mat-date-range-picker>

            <!-- <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker> -->
            <mat-error *ngIf="formControls.dob.errors"
              >You must be 13 years or older to sign up.</mat-error
            >
          </mat-form-field>
        </div>

        <div id="email">
          <mat-form-field required>
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              placeholder="john@example.com"
              formControlName="email"
            />
            <mat-error *ngIf="formControls.email.errors"
              >Email is required</mat-error
            >
          </mat-form-field>
        </div>

        <div id="password">
          <mat-form-field required>
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              placeholder="Password"
              formControlName="password"
            />
            <!-- <mat-error *ngIf="registerForm.hasError('required', 'password')">Password is not valid</mat-error> -->
            <mat-error *ngIf="formControls.password.errors"
              >Password is not valid</mat-error
            >
          </mat-form-field>

          <mat-form-field required>
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              type="password"
              placeholder="Password"
              formControlName="confirmPassword"
            />
            <mat-error *ngIf="formControls.confirmPassword.errors"
              >Passwords do not match</mat-error
            >
          </mat-form-field>

          <div
            id="password-requirements"
            *ngIf="!formControls.password.pristine"
          >
            <!-- <span> Password must contain: </span> -->
            <ul>
              <li>
                At least 1 lower letter
                <i
                  class="material-icons"
                  [ngClass]="hasLower ? 'is-success' : 'is-disabled'"
                >
                  done
                </i>
              </li>
              <li>
                At least 1 capital letter
                <i
                  class="material-icons"
                  [ngClass]="hasUpper ? 'is-success' : 'is-disabled'"
                >
                  done
                </i>
              </li>
              <li>
                At least 1 number
                <i
                  class="material-icons"
                  [ngClass]="hasNumber ? 'is-success' : 'is-disabled'"
                >
                  done
                </i>
              </li>
              <li>
                At least 1 special character e.g. !@£$
                <i
                  class="material-icons"
                  [ngClass]="hasSymbol ? 'is-success' : 'is-disabled'"
                >
                  done
                </i>
              </li>
              <li>
                8+ characters
                <i
                  class="material-icons"
                  [ngClass]="hasEight ? 'is-success' : 'is-disabled'"
                >
                  done
                </i>
              </li>
            </ul>
          </div>
        </div>

        <div class="button-container">
          <button
            mat-button
            color="primary"
            type="submit"
            value="Register"
            [disabled]="registerForm.invalid"
          >
            Create account
          </button>

          <!-- <p class="divider"> or </p> -->

          <!-- <button mat-flat-button color="accent" type="submit" value="Register">
            Sign in with Google
          </button> -->
        </div>
        <!-- <div id="extra-links">
          <p>
            <span> Already have an account? </span>
            <a routerLink="/account"> Sign in </a>
          </p>
        </div> -->
      </form>
    </div>
  </div>

  <!-- <div class="sign-up-info-container">
    <div class="info">
      <h2 class="title"> BudgetBoard </h2>
      <h2 class="description">
        Take control of your money by creating a stress free and easy budget.
      </h2>
    </div>
  </div> -->
</div>

<!-- <app-pop-up-notification></app-pop-up-notification> -->
