import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  checkLogger: boolean;
  customerInfo: any;
  isLoggedIn: boolean;

  constructor(
    private service: ServicesService,
    private spinner: NgxSpinnerService,
    private ls: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    this.spinner.show();
    let sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    // this.service.getReqLogin("?action=getMyInfo" + '&key=' + sesh + '&locId=' + locID).pipe(
    this.service
      .getReqLogin(
        "?action=getMyInfo" +
          "&key=" +
          sesh +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        if (res?.result) {
          this.customerInfo = res.result[0];
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      });
  }

  logout() {
    let data = {
      action: "logout",
      locId: this.ls.getSessionStorage("locID"),
    };
    this.service.logout(data);
    this.isLoggedIn = false;
    this.service.isLoggedIn = false;
    this.service.isUserAdmin.next(false);

    // var auth2 = gapi.auth2.getAuthInstance();
    // auth2.signOut().then(function () {
    //   console.log("User signed out.");
    // });
  }
}
