import { Component, OnInit, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-table-number",
  templateUrl: "./table-number.component.html",
  styleUrls: ["./table-number.component.scss"],
})
export class TableNumberComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TableNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ls: LocalStorageService,
    private service: ServicesService
  ) {}

  tableNumbers: Array<any> = [];
  tablesAmount: number = 20;
  selectedValue: any;

  ngOnInit(): void {
    let locID = this.ls.getSessionStorage("locID");
    this.service
      .getReqCompany("?action=tableInfo&locId=" + locID)
      .subscribe((res) => {
        this.tableNumbers = res.res;
      });

    this.selectedValue = this.data;
  }

  setTable() {
    this.ls.setSessionStorage("tableNo", this.selectedValue.tableNumber);
    this.ls.setSessionStorage("tableId", this.selectedValue.id);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close(this.selectedValue.tableNumber);
  }
}
