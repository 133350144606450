<ngx-spinner></ngx-spinner>
<div class="container">
  <app-breadcrumbs> </app-breadcrumbs>
  <div class="categories new-cat" *ngIf="paramFrom === 'menu'">
    <div class="category">
      <div class="category-items">
        <button class="category-item add-new-cat" (click)="addCategoryDialog()">
          <mat-icon aria-label="edit item" fontSet="material-icons-outlined">
            add
          </mat-icon>
          <h4>New category</h4>
        </button>
      </div>
    </div>
  </div>
  <div
    class="categories"
    *ngFor="let c of categoryArray | keyvalue; let i = index"
  >
    <div class="category">
      <div class="cat-header">
        <h2>{{ c.key }}</h2>

        <div class="icons">
          <mat-slide-toggle
            class="example-margin"
            color="blue"
            [checked]="c.value.enabled == '1' ? true : false"
            (change)="toggled(c.value.categoryId, $event, c, 'cate')"
          >
          </mat-slide-toggle>
          <mat-icon
            aria-label="edit item"
            fontSet="material-icons-outlined"
            (click)="editCategoryDialog(c)"
          >
            edit</mat-icon
          >
          <mat-icon
            aria-label="delite item"
            fontSet="material-icons-outlined"
            (click)="deleteProduct(c, c.value.categoryId, 'cate')"
            class="delete-icon"
          >
            delete</mat-icon
          >
        </div>
      </div>
      <div class="category-items">
        <div *ngFor="let ci of c.value.items" class="category-item">
          <div class="name-and-edit">
            <h3>{{ ci.name }}</h3>

            <div class="icons">
              <mat-icon
                aria-label="edit item"
                fontSet="material-icons-outlined"
                (click)="editProduct(ci, c.value.categoryId, ci.id, paramFrom)"
              >
                edit</mat-icon
              >
              <mat-icon
                aria-label="delite item"
                fontSet="material-icons-outlined"
                (click)="deleteProduct(ci, ci.id, null)"
                class="delete-icon"
              >
                delete</mat-icon
              >
            </div>
          </div>

          <h4>{{ ci.cat }}</h4>

          <h4>{{ ci.price | currency: "GBP" }}</h4>

          <div class="toggle-active">
            <h4 *ngIf="ci.enabled == 1" class="active">Active</h4>
            <h4 *ngIf="ci.enabled == 0" class="disabled">Disabled</h4>

            <mat-slide-toggle
              class="example-margin"
              [checked]="ci.enabled == '1' ? true : false"
              color="blue"
              (change)="toggled(ci.id, $event, ci, null)"
            >
            </mat-slide-toggle>
          </div>
        </div>

        <button
          class="category-item new-item"
          (click)="openDialog(c.value.categoryId, paramFrom)"
        >
          <mat-icon aria-label="edit item" fontSet="material-icons-outlined">
            add
          </mat-icon>
          <h4>New item</h4>
        </button>
      </div>
    </div>
  </div>
</div>
