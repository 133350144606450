<div class="bottom-nav-container" *ngIf="showBottomNav">
  <ul>
    <li>
      <a [routerLink]="['./about']" ActivatedRoute>
        <span class="custom-icon pin-icon"></span>
        Info
      </a>
    </li>
    <li>
      <a [routerLink]="['./']" ActivatedRoute>
        <span class="custom-icon menu-icon"></span>
        Menu
      </a>
    </li>
    <li>
      <a [routerLink]="['./prev-orders']" ActivatedRoute>
        <span class="custom-icon receipt-icon"></span>
        Orders
      </a>
    </li>
  </ul>
</div>
