<div class="snackbar-success">
  <h2> Success</h2>

  <div class="cart-notif">
    <!-- *ngFor="let item of items" -->
    
    <h3> {{data}}</h3>
    <!-- <h3> Product has been deleted.</h3> -->
  
    <div class="button-group">
      <button mat-button color="accent" (click)="snackBarRef.dismiss()"> Close </button>
    </div>
  </div>