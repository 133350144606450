import { Component, OnInit, ViewChild, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from '../../SERVICES/cart.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  
  title: string;
  desc: string;
  price: string;
  options: Array<any>;
  optionTitles: Array<{}> = [];
  extras: Array<any>;
  currentProduct: Array<any>;
  qty: number = 1;
  optionId: string;
  extraTing: any;
  extrasAdded = [];
  selectedRadio;
  
  constructor(
    private cartService: CartService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddComponent>,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.title = this.data.name;
    // this.desc = this.data.desc;
    this.price = this.data.price;
    this.options = this.data.pricing;
    this.extras = this.data.extras;
    console.log('this.extras', this.extras);
    this.optionId = this.options[0].optionId;
    this.currentProduct = this.data;
    this.desc = this.data.desc + ' ' + this.data.abv + ' ABV, ' + this.data.units + ' Units';

    this.getOptionTitle(this.options, null);
  }

  addOne() {
    this.qty++;
  }

  subOne() {
    if (this.qty > 1) {
      this.qty--;
    }
  }

  onSubmit(product, id, qty) {
    let obj: any = {}
    obj.name = product.name;
    obj.desc = this.desc;
    obj.portion = id;
    obj.qty = qty;
    obj.id = this.data.id;
    obj.cartId = obj.id + '-' + id;
    obj.priceId = id;

    this.getOptionTitle(product, id).subscribe(optionTitle => {
      obj.optionTitle = optionTitle;
    });

    // Set the ID, this makes it easier to add 2 of the same items together in the cart.
    // ID = drink id, optionID and extras optionID.

    // Getting price from options
    let i;
    for (i = 0; i < this.options.length; i++) {
      if (id === this.options[i].optionId) {
        obj.price = +this.options[i].price;
        obj.portion = this.options[i].portion;
      }
    }
    // Get extras
    if (this.extrasAdded.length) {

      let i;
      for (i = 0; i < this.extrasAdded.length; i++) {
        if (this.extrasAdded[i]) {
          if (!obj.extras) {
            obj.extras = [];
          }
          obj.extras.push(this.extrasAdded[i]);
          // obj.cartId += obj.extras[i]?.optionId;
          obj.cartId += '-' + this.extrasAdded[i]?.optionId;

          if (this.extrasAdded[i]?.extraPrice !== "0.00") {
            obj.price += +this.extrasAdded[i].extraPrice;
          }
        }
      }

    }

    // obj.id = obj.id.replace(/\s/g, '');

    // Send object to cart.
    this.cartService.addToCart(obj).then((() => {
      this.closeDialog();
      this.openSnackBar(obj);
    }));
  }

  getOptionTitle(product, id): Observable<any[]> {
    if (!id) {
      let k;
      for (k = 0; k < product.length; k++) {
        if (product[k].displayProductName === "0") {
          product[k].optionTitle = product[k].portion;

          if (product[k].displayQuantity === "1") {
            product[k].optionTitle = product[k].quantity + product[k].portion;
          }
        } else if (product[k].displayProductName === "1") {
          product[k].optionTitle = product[k].productName;

          if (product[k].displayQuantity === "1") {
            product[k].optionDesc = product[k].quantity + product[k].portion;
          }
        }
      }
    } else if (id) {
      let j;
      for (j = 0; j < product.pricing.length; j++) {
        if (product.pricing[j].optionId === id) {
          let optionTitle = product.pricing[j].optionTitle;;
          return of(optionTitle);
        }
      }
    }
  }

  openSnackBar(obj) {
    this._snackBar.openFromComponent(ConfirmComponent, {
      duration: 4000,
      panelClass: ['snackbar-container'],
      data: obj
    });
  }

  clearSortingFilters(i, e) {
    e.stopPropagation();

    this.extrasAdded[i] = null
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
