import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServicesService } from "../../SERVICES/services.service";
import { title } from "process";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ServicesService,
    private ls: LocalStorageService,
    private spinner: NgxSpinnerService
  ) {}

  categorys: Array<{
    name: string;
    img: string;
  }>;

  // This is the 2nd category (if they have one. I.E beers, wine, cocktails)
  categoryArray: any;
  currentCategory: string;
  categoryTitle: string;
  currentRoute: string;

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    // this.categorys = this.service.categorys;
    this.showMenus();

    // this.getCat();
  }

  getCat() {
    this.spinner.show();

    this.route.params.subscribe((params) => {
      this.currentCategory = params.cat;
    });

    var i: number;
    for (i = 0; i < this.categorys.length; i++) {
      if (this.currentCategory === this.categorys[i].name) {
        this.categoryTitle = this.categorys[i].name;

        let id = this.ls.getSessionStorage("menuId");
        let locID = this.ls.getSessionStorage("locID");

        this.service
          .getReq(
            "?action=getList&productType=" +
              id +
              "&locId=" +
              locID +
              "&userId=" +
              this.service.userId
          )
          .pipe(finalize(() => this.spinner.hide()))
          .subscribe((res) => {
            this.categoryArray = res;
            this.service.catArray = this.categoryArray;
          });
      }
    }
  }

  showMenus() {
    this.spinner.show();
    let locID = this.ls.getSessionStorage("locID");

    this.service
      .getReq(
        "?action=mainProductOptions&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        this.categorys = res.mainOptions;
        this.getCat();
      });
  }
}
