import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OrderPageComponent } from "./PAGES/order-page/order-page.component";
import { CategoryComponent } from "./COMPONENTS/category/category.component";
import { ViewMenusComponent } from "./COMPONENTS/details/view-menu.component";
import { ItemComponent } from "./COMPONENTS/item/item.component";
import { CartComponent } from "./PAGES/cart/cart.component";
import { OrderHistoryComponent } from "./PAGES/order-history/order-history.component";
import { HomePageComponent } from "./COMPONENTS/home-page/home-page.component";
import { OrderItemComponent } from "./PAGES/order-item/order-item.component";
import { MyAccountComponent } from "./PAGES/my-account/my-account.component";
import { RegisterComponent } from "./PAGES/register/register.component";
import { ForgotPasswordComponent } from "./PAGES/forgot-password/forgot-password.component";
import { PasswordResetComponent } from "./PAGES/password-reset/password-reset.component";
import { AdminComponent } from "./PAGES/admin/admin.component";
import { IsAdminGuard, LoggedInGuard } from "./GUARDS/auth-guard.guard";
import { MenusComponent } from "./PAGES/menus/menus.component";
import { EditMenuComponent } from "./PAGES/edit-menu/edit-menu.component";
import { AdminConfigComponent } from "./PAGES/admin-config/admin-config.component";
import { AboutComponent } from "./PAGES/about/about.component";
import { ProcessCartComponent } from "./COMPONENTS/process-cart/process-cart.component";
import { AdminDashboardComponent } from "./PAGES/admin-dashboard/admin-dashboard.component";
import { AnalyticsComponent } from "./PAGES/analytics/analytics.component";
import { InsightsComponent } from "./PAGES/insights/insights.component";
import { AdminUsersComponent } from "./PAGES/admin-users/admin-users.component";
import { InvoicesComponent } from "./PAGES/invoices/invoices.component";
import { AppContainerComponent } from "./COMPONENTS/app-container/app-container.component";
import { PrevOrdersComponent } from "./COMPONENTS/prev-orders/prev-orders.component";

const routes: Routes = [
  {
    path: "",
    component: HomePageComponent,
  },
  {
    path: "c/:store",
    component: AppContainerComponent,
    children: [
      {
        path: "",
        component: OrderPageComponent,
        children: [
          {
            path: "",
            component: ViewMenusComponent,
          },
          {
            path: "category/:cat",
            component: CategoryComponent,
            data: {
              breadcrumb: ":cat",
            },
          },
          {
            path: "category/:cat/:id",
            component: ItemComponent,
          },
        ],
      },
      {
        path: "cart",
        component: CartComponent,
      },
      { path: "cart/checkout", component: ProcessCartComponent },
      {
        path: "history",
        children: [
          {
            path: "",
            component: OrderHistoryComponent,
          },
          {
            path: ":orderNo",
            component: OrderItemComponent,
          },
        ],
      },
      { path: "account", component: MyAccountComponent },
      {
        path: "register",
        component: RegisterComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: "password-reset",
        component: PasswordResetComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: "admin",
        canActivate: [IsAdminGuard],
        children: [
          {
            path: "dashboard",
            component: AdminDashboardComponent,
          },
          {
            path: "orders",
            component: AdminComponent,
          },
          {
            path: "menu",
            component: MenusComponent,
            children: [],
          },
          {
            path: ":cat/:id",
            component: EditMenuComponent,
          },
          {
            path: "insights",
            component: InsightsComponent,
          },
          {
            path: "analytics",
            component: AnalyticsComponent,
          },
          {
            path: "invoices",
            component: InvoicesComponent,
          },
          {
            path: "users",
            component: AdminUsersComponent,
          },
          {
            path: "extras",
            component: AdminConfigComponent,
          },
        ],
      },
      { path: "about", component: AboutComponent },
      { path: "prev-orders", component: PrevOrdersComponent },
    ],
  },
  { path: "**", component: OrderPageComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
