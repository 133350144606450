import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { ServicesService } from "./SERVICES/services.service";
import { HeaderComponent } from "./COMPONENTS/header/header.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { OrderPageComponent } from "./PAGES/order-page/order-page.component";
import { ViewMenusComponent } from "./COMPONENTS/details/view-menu.component";
import { CategoryComponent } from "./COMPONENTS/category/category.component";
import { ItemComponent } from "./COMPONENTS/item/item.component";
import { AddComponent } from "./MODALS/add/add.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CartComponent } from "./PAGES/cart/cart.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { HomePageComponent } from "./COMPONENTS/home-page/home-page.component";
import { MatBadgeModule } from "@angular/material/badge";
import { ConfirmComponent } from "./MODALS/confirm/confirm.component";
import { EditComponent } from "./MODALS/edit/edit.component";
import { TableNumberComponent } from "./MODALS/table-number/table-number.component";
import { MatSelectModule } from "@angular/material/select";
import { OrderConfirmComponent } from "./MODALS/order-confirm/order-confirm.component";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { BreadcrumbsComponent } from "./COMPONENTS/breadcrumbs/breadcrumbs.component";
import { OrderHistoryComponent } from "./PAGES/order-history/order-history.component";
import { OrderItemComponent } from "./PAGES/order-item/order-item.component";
import { MyAccountComponent } from "./PAGES/my-account/my-account.component";
import { LoginComponent } from "./COMPONENTS/login/login.component";
import { MatInputModule } from "@angular/material/input";
import { RegisterComponent } from "./PAGES/register/register.component";
import { ForgotPasswordComponent } from "./PAGES/forgot-password/forgot-password.component";
import { PasswordResetComponent } from "./PAGES/password-reset/password-reset.component";
import { InfoBoxComponent } from "./COMPONENTS/info-box/info-box.component";
import { AdminComponent } from "./PAGES/admin/admin.component";
import { MatTabsModule } from "@angular/material/tabs";
import { OpenOrdersComponent } from "./COMPONENTS/open-orders/open-orders.component";
import { UpdateStatusComponent } from "./MODALS/update-status/update-status.component";
import { AcceptedOrdersComponent } from "./COMPONENTS/accepted-orders/accepted-orders.component";
import { CompletedOrdersComponent } from "./COMPONENTS/completed-orders/completed-orders.component";
import { enableProdMode } from "@angular/core";
import { MenusComponent } from "./PAGES/menus/menus.component";
import { AddProductComponent } from "./MODALS/ADMIN/add-product/add-product.component";
import { AddCategoryComponent } from "./MODALS/ADMIN/add-category/add-category.component";
import { EditMenuComponent } from "./PAGES/edit-menu/edit-menu.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AdminOrderComponent } from "./COMPONENTS/admin-order/admin-order.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditCategoryComponent } from "./MODALS/ADMIN/edit-category/edit-category.component";
import { EditProductComponent } from "./MODALS/ADMIN/edit-product/edit-product.component";
import { DeleteProductComponent } from "./MODALS/ADMIN/delete-product/delete-product.component";
import { SuccessComponent } from "./MODALS/success/success.component";
import { AddMenuComponent } from "./MODALS/ADMIN/add-menu/add-menu.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AddExtrasComponent } from "./MODALS/ADMIN/add-extras/add-extras.component";
import { AdminConfigComponent } from "./PAGES/admin-config/admin-config.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { ErrorComponent } from "./MODALS/error/error.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AboutComponent } from "./PAGES/about/about.component";
import { ProcessCartComponent } from "./COMPONENTS/process-cart/process-cart.component";
import { MatStepperModule } from "@angular/material/stepper";
import { CardNumberDirective } from "./DIRECTIVES/card-number.directive";
import { ExpiryDateDirective } from "./DIRECTIVES/expiry-date.directive";
import { AdminDashboardComponent } from "./PAGES/admin-dashboard/admin-dashboard.component";
import { MatTableModule } from "@angular/material/table";
import { AnalyticsComponent } from "./PAGES/analytics/analytics.component";
import { InsightsComponent } from "./PAGES/insights/insights.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatMenuModule } from "@angular/material/menu";
import { RefundsComponent } from "./MODALS/ADMIN/refunds/refunds.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AdminUsersComponent } from "./PAGES/admin-users/admin-users.component";
import { InvoicesComponent } from "./PAGES/invoices/invoices.component";
import { SearchComponent } from "./COMPONENTS/search/search.component";
import { BottomNavComponent } from "./COMPONENTS/bottom-nav/bottom-nav.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { AdminHeaderComponent } from "./COMPONENTS/admin-header/admin-header.component";
import { GSigninComponent } from "./COMPONENTS/g-signin/g-signin.component";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AppContainerComponent } from "./COMPONENTS/app-container/app-container.component";
import { PrevOrdersComponent } from "./COMPONENTS/prev-orders/prev-orders.component";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { CookieBannerComponent } from './COMPONENTS/cookie-banner/cookie-banner.component';

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    OrderPageComponent,
    ViewMenusComponent,
    CategoryComponent,
    ItemComponent,
    AddComponent,
    CartComponent,
    HomePageComponent,
    ConfirmComponent,
    EditComponent,
    TableNumberComponent,
    OrderConfirmComponent,
    BreadcrumbsComponent,
    OrderHistoryComponent,
    OrderItemComponent,
    MyAccountComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    InfoBoxComponent,
    AdminComponent,
    OpenOrdersComponent,
    UpdateStatusComponent,
    AcceptedOrdersComponent,
    CompletedOrdersComponent,
    MenusComponent,
    AddProductComponent,
    AddCategoryComponent,
    EditMenuComponent,
    AdminOrderComponent,
    EditCategoryComponent,
    EditProductComponent,
    DeleteProductComponent,
    SuccessComponent,
    AddMenuComponent,
    AddExtrasComponent,
    AdminConfigComponent,
    ErrorComponent,
    AboutComponent,
    ProcessCartComponent,
    CardNumberDirective,
    ExpiryDateDirective,
    AdminDashboardComponent,
    AnalyticsComponent,
    InsightsComponent,
    RefundsComponent,
    AdminUsersComponent,
    InvoicesComponent,
    SearchComponent,
    BottomNavComponent,
    AdminHeaderComponent,
    GSigninComponent,
    AppContainerComponent,
    PrevOrdersComponent,
    CookieBannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatSnackBarModule,
    MatExpansionModule,
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatBadgeModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    NgxChartsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatGridListModule,
    SocialLoginModule,
  ],
  providers: [
    ServicesService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    MatDatepickerModule,
    DatePipe,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "1026065212492-0g31cgam9n519o227tkl4s485grcviq9.apps.googleusercontent.com"
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
