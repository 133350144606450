<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">All Orders</h1>
      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="data-content">
    <div class="filters">
      <div class="filter-inputs">
        <mat-form-field>
          <mat-label>Order ID </mat-label>
          <input
            matInput
            (keyup)="filterId($event)"
            placeholder="Ex. ium"
            #idFilter
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select multiple (selectionChange)="filterStatus($event.value)">
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Closed">Closed</mat-option>
            <mat-option value="Refunded">Refunded</mat-option>
            <mat-option value="Partially Refunded"
              >Partially Refunded</mat-option
            >
            <mat-option value="cancelled">Cancelled</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Date range</mat-label>
          <mat-date-range-input
            [formGroup]="range"
            [rangePicker]="picker"
            #dateRange
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="
                addEvent($event.target.value, $event, dateRange.value)
              "
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
          >
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>
      </div>

      <!-- <p>Selected range: {{ range.value | json }}</p> -->

      <!-- <button mat-flat-button color="primary">Search</button> -->
    </div>

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderId">
        <th mat-header-cell *matHeaderCellDef>Order ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="tableNo">
        <th mat-header-cell *matHeaderCellDef>Table No.</th>
        <td mat-cell *matCellDef="let element">{{ element.tableNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="element.orderStatus">
            {{ element.orderStatus }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPrice">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalSalePrice | currency: "GBP" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date: "short" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef>Menu</th>

        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDialog(element)">
              <mat-icon fontSet="material-icons-outlined">receipt</mat-icon>
              <span>Refund</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ idFilter.value }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[20, 40, 60, 80, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
