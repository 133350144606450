<div class="login-container">
  <div id="login">
    <div id="login-container">
      <h2 id="login-header">Reset Password</h2>

      <!-- error message goes here -->
      <app-info-box *ngIf="statusFailed || forgotSuccess"
        [ngClass]="{ 'error-box': statusFailed, 'success-box': forgotSuccess}" [status]="status"></app-info-box>
      <form [formGroup]="resetPassForm" id="login-form" (ngSubmit)="resetPass($event)">
        <span>
          Enter your email address, one time code and your new password.
        </span>
        <div id="email">
          <mat-form-field [ngClass]="{ 'has-error': formControls.email.errors }" required>
            <input matInput type="email" placeholder="Email" formControlName="email">
            <mat-error *ngIf="formControls.email.errors">Email must be a valid email address</mat-error>
          </mat-form-field>
        </div>
        <div id="code">
          <mat-form-field [ngClass]="{ 'has-error': formControls.email.errors }" required>
            <input matInput type="text" placeholder="One time code" formControlName="oneTimeCode">
            <mat-error *ngIf="formControls.oneTimeCode.errors">Email must be a valid email address</mat-error>
          </mat-form-field>
        </div>
        <div id="password">
          <mat-form-field [ngClass]="{ 'has-error': formControls.email.errors }" required>
            <input matInput type="password" placeholder="Password" formControlName="password">
            <mat-error *ngIf="formControls.password.errors">Email must be a valid email address</mat-error>
          </mat-form-field>

          <mat-form-field [ngClass]="{ 'has-error': formControls.email.errors }" required>
            <input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword">
            <mat-error *ngIf="formControls.confirmPassword.errors">Email must be a valid email address</mat-error>
          </mat-form-field>

          <div id="password-requirements" *ngIf="!formControls.password.pristine">
            <!-- <span> Password must contain: </span> -->
            <ul>
              <li> At least 1 lower letter
                <i class="material-icons" [ngClass]="hasLower ? 'is-success' : 'is-disabled'">
                  done
                </i>
              </li>
              <li> At least 1 capital letter
                <i class="material-icons" [ngClass]="hasUpper ? 'is-success' : 'is-disabled'">
                  done
                </i>
              </li>
              <li> At least 1 number
                <i class="material-icons" [ngClass]="hasNumber ? 'is-success' : 'is-disabled'">
                  done
                </i>
              </li>
              <li> At least 1 special character e.g. !@£$
                <i class="material-icons" [ngClass]="hasSymbol ? 'is-success' : 'is-disabled'">
                  done
                </i>
              </li>
              <li> 8+ characters
                <i class="material-icons" [ngClass]="hasEight ? 'is-success' : 'is-disabled'">
                  done
                </i>
              </li>
            </ul>
          </div>
        </div>
        <div class="button-container">
          <button mat-flat-button color="primary" type="submit" value="Log in" [disabled]="resetPassForm.invalid">Reset
            password</button>
        </div>
      </form>
    </div>
  </div>
</div>