import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  constructor(
    private service: ServicesService,
    private ls: LocalStorageService
  ) {}

  address: any;
  facilities: any;
  openingTimes: any;
  venue: any;
  showShortDesciption: boolean = true;
  showOpening: boolean = false;
  todayDay: string;
  openingTime: string;
  closingTime: string;
  isOpen: boolean = false;

  ngOnInit(): void {
    let locID = this.ls.getSessionStorage("locID");

    this.service
      .getReqCompany("?action=venueInfo&locId=" + locID)
      .subscribe((res) => {
        console.log("res", res);
        this.address = res.res.address;
        this.facilities = res.res.facilities;
        this.openingTimes = res.res.openingTimes;
        this.venue = res.res.venue;

        this.getDay();
      });
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption;
  }

  toggleOpening() {
    this.showOpening = !this.showOpening;
  }

  getDay() {
    switch (new Date().getDay()) {
      case 0:
        this.todayDay = "Sunday";
        break;
      case 1:
        this.todayDay = "Monday";
        break;
      case 2:
        this.todayDay = "Tuesday";
        break;
      case 3:
        this.todayDay = "Wednesday";
        break;
      case 4:
        this.todayDay = "Thursday";
        break;
      case 5:
        this.todayDay = "Friday";
        break;
      case 6:
        this.todayDay = "Saturday";
    }
    this.getClosingTime(this.openingTimes);
  }

  getClosingTime(ot) {
    let i;
    for (i = 0; i < ot.length; i++) {
      if (ot[i].day === this.todayDay) {
        this.closingTime = ot[i].closeTime;
        this.openingTime = ot[i].openTime;

        let close = this.closingTime.match(/\d+/g);
        let closeHour = parseInt(close[0]);
        let closeMin = parseInt(close[1]);

        let open = this.openingTime.match(/\d+/g);
        let openHour = parseInt(open[0]);
        let openMinute = parseInt(open[1]);

        let d = new Date();

        // if (d.getHours() >= openHour && closeHour) {
        //   console.log("THIS SHOP IS CLOSED");

        //   this.isOpen = false;
        // }
      }
    }
  }
}
