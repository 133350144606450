import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { LocalStorageService } from "../SERVICES/local-storage.service";
import { ServicesService } from "../SERVICES/services.service";
import { map } from "rxjs/internal/operators/map";

@Injectable({
  providedIn: "root",
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private service: ServicesService,
    private router: Router,
    private ls: LocalStorageService
  ) {}
  seshKey: string;
  data: object;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.service.redirectUrl = state.url;
    return !!this.ls.checkCookie("sesh=");
  }
}

@Injectable({
  providedIn: "root",
})
export class LoggedInGuard implements CanActivate {
  constructor(
    private service: ServicesService,
    private router: Router,
    private ls: LocalStorageService
  ) {}

  seshKey: string;
  data: object;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.service.redirectUrl = state.url;

    return !this.ls.checkCookie("sesh=");
  }
}
