import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';
import { CartService } from 'src/app/SERVICES/cart.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<EditComponent>,
    private cart: CartService) { }

  qty: number = this.data.qty;
  price: number;

  ngOnInit(): void {
    this.updatePrice();
  }

  addOne() {
    this.qty++;
    this.updatePrice();
  }

  subOne() {
    if (this.qty > 0) {
      this.qty--;
      this.updatePrice();
    }
  }

  // On the edit snackbar, updat the price when qty changes.
  updatePrice() {
    // this.price = this.data.price * this.qty;
  }

  // On snackbar, when qty is updated.
  updateItem() {
    if (this.qty >= 1) {
      let addedQty = this.qty - this.data.qty;
      this.data.qty = this.qty;
      this.cart.updateCart('updated', addedQty);
    } else {
      let i;
      for (i = 0; i < this.cart.items.length; i++) {
        if (this.cart.items[i].id === this.data.id) {
          // delete this.cart.items[i];
          this.cart.items.splice(i, 1);
          this.cart.updateCart('removed', this.data.qty);
          break;
        }
      }
    }
    
    this.snackBarRef.dismiss();

  }
}
