import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { Observable } from "rxjs";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

const ELEMENT_DATA: any[] = [];

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent implements OnInit {
  todaysDate = new Date();

  constructor(
    containerRef: ViewContainerRef,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    private datePipe: DatePipe
  ) {
    console.log("containerRef", containerRef);
  }

  displayedColumns: string[] = ["position", "name", "weight", "symbol"];
  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  isAdmin: boolean = true;
  multi: any[] = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Week";
  yAxisLabel: string = "Earnings (£)";
  timeline: boolean = true;
  db: any;
  seriesGraphsArray;

  colorScheme = {
    domain: ["#5AA454"],
  };

  activeMenu: any;
  openOrders: any;
  totalOrders: any;
  currentWeekEarnings: any;
  todaysTotal: any;
  userCount: any;
  orderStats: any;
  serieGraphs: any;

  currentWeeksEarnings: number;

  sesh: Observable<any> = this.ls.checkCookieOnly("sesh=");
  locId: string = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;

  onSelect(data): void {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
    // this.getOrders();
    this.getAdminInfo();

    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  getAdminInfo() {
    this.as
      .getReqOrder(
        "?action=dashboardInfo2&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.currentWeekEarnings = res.dashbaord.result.curWekEarns;
        this.totalOrders = res.dashbaord.result.totalOrders;
        this.todaysTotal = res.dashbaord.result.todaysTotal;
        this.activeMenu = res.dashbaord.result.activeMenu;
        this.userCount = res.dashbaord.result.userCount;

        this.orderStats = res.dashbaord.result.orderStats;
        this.serieGraphs = res.dashbaord.result.serieGraphs;
        this.seriesGraphsArray = this.serieGraphs.value.result
        this.multi = [{ name: "", series: this.serieGraphs.value.result }];

        // this.currentWeeksEarnings =
        //   res.admDashbaord.result.currentWeeksEarnings;
        // this.multi = [{ name: "", series: res.admDashbaord.result.series }];
      });
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        console.log("res", res);

        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }

  getOrders() {
    this.as
      .getReqOrder(
        "?action=getOrdersNsDaysRestricted&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&days=" +
          30 +
          "&limit=" +
          10
      )
      .subscribe((res) => {
        console.log("res", res);
        const ELEMENT_DATA = res.result.data;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      });
  }
}
