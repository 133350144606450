<div class="cookie-policy" *ngIf="showCookie">
  <h1 class="title">
    Your Privacy
  </h1>

  <p>
    We use cookies to improve your experience on our site. 
  </p>

  <p>
    To find out more, read our <a>cookie policy</a>.
  </p>

  <div>
    <button mat-button color="primary" (click)="hasAcceptedCookie()"> Ok </button>
  </div>
</div>