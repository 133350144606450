import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Status } from 'src/app/INTERFACES/status-message';
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private service: ServicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService) { }

  forgotPassForm: FormGroup;
  statusFailed: boolean = false;
  forgotSuccess: boolean = false;
  status: Status = {
    message: ''
  }

  ngOnInit(): void {
    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      action: 'RequestPasswordReset'
    });
  }

  get formControls() { return this.forgotPassForm.controls; }

  forgotPass(event) {
    const formData = new FormData();

    formData.append('action', this.forgotPassForm.value.action);
    formData.append('email', this.forgotPassForm.value.email);
    formData.append('locId', this.ls.getSessionStorage('locID'));

    this.service.forgotPassword(formData).subscribe(
      (res) => {
        if (res.status === 'success') {
          this.forgotSuccess = true;
          this.statusFailed = false;
          this.status.message = res.message;
          setTimeout(() => {
            this.router.navigateByUrl('/password-reset');
          }, 3000);
        } else if (res.status === 'failed') {
          this.statusFailed = true;
          this.status.message = res.message
        }
      },
      (err) => console.log('err', err)
    )
  }
}
