<ngx-spinner></ngx-spinner>
<!-- <app-search> </app-search> -->

<div class="categories" *ngFor="let category of categorys">
  <a
    routerLink="category/{{ category.name }}"
    routerLinkActive
    [state]="[{ title: category.name }]"
    (click)="setCatSesh(category.id)"
  >
    <div class="category">
      <h1>{{ category.name }}</h1>
      <span class="custom-icon {{ category.name }}-background"></span>
    </div>
  </a>
</div>

<div class="no-menus" *ngIf="categorys.length === 0">
  <div class="icon-centered">
    <span class="custom-icon no-orders no-active"></span>
    <h2 class="info">There are no active menus</h2>
    <p class="title">
      Looks like there aren't any active menus. Please check back in again
      later.
    </p>

    <!-- <a routerLink="../">
      <button mat-button color="primary">Order Now</button>
    </a> -->
  </div>
</div>
