import {
  Component,
  OnInit,
  Input,
  ɵgetComponentViewDefinitionFactory,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ServicesService } from "src/app/SERVICES/services.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/SERVICES/cart.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { OrderConfirmComponent } from "src/app/MODALS/order-confirm/order-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
declare const sagepayOwnForm: any;

@Component({
  selector: "app-process-cart",
  templateUrl: "./process-cart.component.html",
  styleUrls: ["./process-cart.component.scss"],
})
export class ProcessCartComponent implements OnInit {
  constructor(
    private service: ServicesService,
    private ls: LocalStorageService,
    private formBuilder: FormBuilder,
    private cart: CartService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {} // Gets Access Token stored in enviroment variable

  items;
  registerForm: FormGroup;
  checkoutObj: Array<any> = [];
  sesh = this.ls.checkCookieOnly("sesh=");
  locID = this.ls.getSessionStorage("locID");
  cartObj = this.cart.checkoutObj;
  url: string = "";
  urlSafe: SafeResourceUrl;
  paReq = "";
  transactionId = "";
  termUrl = "";
  acsUrl = "";
  creq = "";

  cartSummary: Array<any>;
  totalPrice: number;

  // THIS IS THE STEPPER TEST
  detailsFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  tds: FormGroup;
  tdsvt: FormGroup;
  // THIS IS THE STEPPER TEST

  ngOnInit(): void {
    console.log("x", this.cart.merchantKey);
    console.log("cartObj", this.cartObj);

    this.hasCheckedOut();

    // THIS IS A STEPPER TEST
    this.detailsFormGroup = this.formBuilder.group({
      firstName: ["Joe Bloggs", Validators.required],
      cardNumber: ["4929000005559", Validators.required], //4929000005559 4929000000006
      expiry: ["11/22", Validators.required],
      secCode: ["123", Validators.required],
    });

    this.addressFormGroup = this.formBuilder.group({
      firstName: ["Joe", Validators.required],
      lastName: ["Bloggs", Validators.required],
      address: ["1 Buckingham Palace", Validators.required],
      postCode: ["SW1A 1AA", Validators.required],
      city: ["London", Validators.required],
      country: ["England", Validators.required],
    });

    this.tds = this.formBuilder.group({
      PaReq: [this.paReq, Validators.required],
      TermUrl: [this.termUrl, Validators.required],
      MD: [this.transactionId, Validators.required],
    });

    this.tdsvt = this.formBuilder.group({
      creq: [this.paReq, Validators.required],
      transactionId: [this.transactionId, Validators.required],
    });
    // THIS IS A STEPPER TEST

    ////////////
    // This is to show the summary, will be in 3rd stepper in a bit.
    ////////////
    this.cartSummary = this.cart.getItems();
    console.log("this.cartSummary", this.cartSummary);

    this.cart.currentPrice.subscribe(
      (totalPrice) => (this.totalPrice = totalPrice)
    );
    this.totalPrice = this.cart.totalPrice;
  }

  get detailsFormControls() {
    return this.detailsFormGroup.controls;
  }

  get addressFormControls() {
    return this.addressFormGroup.controls;
  }

  submitDetails(e) {
    console.log("e", e);
    sagepayOwnForm({
      merchantSessionKey: this.cart.merchantKey,
    }).tokeniseCardDetails({
      cardDetails: {
        cardholderName: "AP",
        cardNumber: this.detailsFormGroup.value.cardNumber,
        expiryDate: "1122",
        securityCode: "123",
      },
      // UIkit.modal.confirm('Are you sure you want to delete this?', () => {

      onTokenised: (res) => {
        if (res.success) {
          this.sendCart(res.cardIdentifier);
        } else {
          this.sendCart("else");
        }
      },
    });
  }

  sendCart(cardId) {
    console.log("cardId", cardId);
    console.log("this.cartObj", this.cartObj);

    let obj = {
      action: "checkout",
      message: this.cartObj,
      key: this.sesh,
      locId: this.locID,
      cardIdentifier: cardId,
      customer: {
        firstName: "a",
        lastName: "p",
        address1: "88",
        city: "Portsmouth",
        postCode: "412",
        country: "GB",
      },
      screen: {
        height: window.innerHeight + "px",
        width: window.innerWidth + "px",
        browserLanguage: "en-gb",
        browserColorDepth: 16,
      },
      userId: this.service.userId,
      msk: this.cart.merchantKey,
    };
    console.log("obj", obj);

    this.service.postReqOrder(obj).subscribe((res: any) => {
      console.log("res", res);

      switch (res.result.opayo.statusCode) {
        case "0000":
          this._snackBar.openFromComponent(OrderConfirmComponent, {
            duration: 6000,
            panelClass: ["snackbar-container"],
          });

          break;
        case "2007":
          this.paReq = res.result.opayo.paReq;
          this.acsUrl = res.result.opayo.acsUrl;
          this.transactionId = res.result.opayo.transactionId;

          this.tds.setValue({
            PaReq: res.result.opayo.paReq,
            TermUrl:
              "https://www.waitron.azui.co.uk/php/order/paymentGateway.php",
            MD: res.result.opayo.transactionId,
          });

          this.onSubmit();

          break;
        case "2021":
          this.creq = res.result.opayo.cReq;
          this.acsUrl = res.result.opayo.acsUrl;
          // let transactionId = res.result.opayo.transactionId;

          this.tdsvt.setValue({
            creq: res.result.opayo.cReq,
            transactionId: res.result.opayo.transactionId,
          });

          this.onSubmit2021();

          break;
      }
    });
  }

  onSubmit(): void {
    let form: any = document.getElementById("pa-form");
    form.action = this.acsUrl;
    form.submit();
  }

  onSubmit2021(): void {
    let form: any = document.getElementById("cr-form");
    form.action = this.acsUrl;
    form.submit();
  }

  hasCheckedOut() {
    const timeout = 5000;
    this.route.queryParams.subscribe((params) => {
      if (params.success) {
        this._snackBar
          .openFromComponent(OrderConfirmComponent, {
            duration: 6000,
            panelClass: "snackbar-container",
          })
          .afterDismissed()
          .subscribe(() => {
            this.cart.items = [];
            this.ls.removeSessionStorage("cart");
            this.items = this.cart.getItems();
            this.cart.checkedOut();
            this.router.navigate(["./"]);
          });

        // this._snackBar.afterDismissed().subscribe((info) => {
        //   if (info.dismissedByAction === true) {
        //     // your code for handling this goes here
        //   }
        // });
      }
    });
  }
}
