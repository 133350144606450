<div class="item-text">
  <h2 mat-dialog-title>Order {{ orderId }}</h2>
  <h5 mat-dialog-title>{{ createdDate | date: "short" }}</h5>
</div>

<mat-dialog-content class="mid-content">
  <div class="populated-cart">
    <!-- <h3 class="title">Items</h3> -->
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="row.itemQuantity > '0' && $event.stopPropagation()"
            (change)="
              row.itemQuantity > '0' && $event ? selection.toggle(row) : null
            "
            [checked]="row.itemQuantity > '0' && selection.isSelected(row)"
            [aria-label]="row.itemQuantity > '0' && checkboxLabel(row)"
            [disabled]="row.itemQuantity == 0"
          >
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.itemName }}</td>
        <td mat-footer-cell *matFooterCellDef>Total</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let element">
          {{ element.itemPrice | currency: "GBP" }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ getTotalCost() | currency: "GBP" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef>Qty</th>
        <td mat-cell *matCellDef="let element">
          {{ element.itemQuantity }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let row; let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            [ngClass]="
              selection.isSelected(row) && element.itemQuantity > 1
                ? 'show-qty'
                : ''
            "
          >
            <ng-container
              *ngIf="selection.isSelected(row) && element.itemQuantity > 1"
            >
              <p>Refund qty:</p>
              <input
                type="number"
                step="1"
                matInput
                max="{{ element.itemQuantity }}"
                value="{{ element.itemQuantity }}"
                min="1"
                (change)="refundQty(row, refundVal)"
                #refundVal
              />
            </ng-container>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="row.itemQuantity > '0' && selection.toggle(row)"
        [ngClass]="row.itemQuantity == 0 ? 'disabled' : ''"
      ></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="qty-input"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

    <div class="partial-refund">
      <div class="refund-main">
        <mat-checkbox #partialChecked> </mat-checkbox>
        <p>Partial Refund</p>
      </div>

      <div *ngIf="partialChecked.checked" class="partial-refund-amount">
        <p>Refund amount:</p>
        <mat-form-field appearance="outline">
          <span matPrefix>£</span>
          <input type="number" matInput [(ngModel)]="refundAmnt" />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" mat-dialog-close>
    Cancel
  </button>
  <button
    mat-button
    color="primary"
    type="button"
    (click)="onSubmit(selection.selected)"
  >
    Refund
  </button>
</mat-dialog-actions>
