<ngx-spinner></ngx-spinner>
<div class="item-text">
  <h2 mat-dialog-title *ngIf="type !== 'edit'">  New Option </h2>
  <h2 mat-dialog-title *ngIf="type === 'edit'"> Edit Option </h2>
</div>

<form [formGroup]="addExtra" id="extras-form" (ngSubmit)="add()">

  <mat-dialog-content class="mid-content">
    <div id="name">

      <div class="is-active">
        <h3> Name </h3>
        <div class="toggles">
          <mat-slide-toggle formControlName="displayProductName" class="show-name">Display product name
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="enableItem" class="enable-item">Active</mat-slide-toggle>
        </div>
      </div>
      <mat-form-field required appearance="outline">
        <input matInput type="text" placeholder="Lemonade" formControlName="productName">
        <mat-hint class="hint" align="start"><strong>Choose a name for your new product.</strong> </mat-hint>
      </mat-form-field>

    </div>

    <div id="price">
      <h3> Price </h3>
      <mat-form-field appearance="outline">
        <input matInput type="text" placeholder="3.50" formControlName="price">
        <span matPrefix>£&nbsp;</span>
        <mat-hint class="hint" align="start"><strong>Enter a price for this option (before VAT).</strong>
        </mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>



  <mat-dialog-actions align="end">
    <button mat-button color="accent" type="button" mat-dialog-close> Cancel </button>
    <button mat-button color="primary" type="submit" value="add" [disabled]="addExtra.invalid" *ngIf="type !== 'edit'"> Add </button>
    <button mat-button color="primary" type="submit" value="add" [disabled]="addExtra.invalid" *ngIf="type === 'edit'"> Update </button>

    <!-- [mat-dialog-close]="true"  -->
  </mat-dialog-actions>
</form>