import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Observable } from "rxjs";

const ELEMENT_DATA: any[] = [];

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit, AfterViewInit {
  constructor(
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService
  ) {}

  todaysDate = new Date();
  displayedColumns: string[] = ["Invoice", "Date range", "Net", "Download"];
  dataSource = new MatTableDataSource<[]>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild("pdfContent") pdfContent: ElementRef;

  sesh = this.ls.checkCookieOnly("sesh=");
  locId = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;
  emptyTable = true;

  ngOnInit(): void {
    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  ngAfterViewInit(): void {
    this.getInvoices();
    this.dataSource.paginator = this.paginator;
  }

  getInvoices() {
    let locID = this.ls.getSessionStorage("locID");
    let sesh = this.ls.checkCookieOnly("sesh=");

    this.service
      .getReqCompany(
        "?action=getInvoiceTable&key=" +
          sesh +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        if (res.result !== "No data available") {
          const ELEMENT_DATA = res.result;
          this.dataSource = new MatTableDataSource(ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.emptyTable = false;
        } else {
          this.emptyTable = true;
        }
      });
  }

  public SavePDF(id): void {
    let sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");
    const formData = new FormData();

    this.service
      .getReqCompany(
        "?action=pdfGen&key=" +
          sesh +
          "&invoiceId=" +
          id +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        let val = res.result;
        console.log("val", val);

        const doc = new jsPDF();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.text("Recipient", 10, 20);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");

        doc.text(val.locName, 10, 28);

        doc.text(val.locAddress, 10, 34);
        doc.text("Invoice: " + val.invoiceNo, 10, 40);
        doc.text("Invoice Period: " + val.invoicePeriod, 10, 46);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.text("Summary", 10, 66);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(16);

        doc.text("Waitron Commission", 10, 81);

        autoTable(doc, {
          headStyles: { fillColor: "#084574" },
          footStyles: { fillColor: "#DFF0F2", textColor: "#000000" },
          head: [val.WCommission.head],
          body: [val.WCommission.body],
          startY: 86,
          margin: 10,
        });

        let WCFinalY = (doc as any).lastAutoTable.finalY + 15;

        doc.text("Your Completed Orders", 10, WCFinalY);
        autoTable(doc, {
          headStyles: { fillColor: "#084574" },
          footStyles: { fillColor: "#DFF0F2", textColor: "#000000" },
          head: [val.completedOrders.head],
          body: val.completedOrders.body,
          foot: [val.completedOrders.foot],
          startY: WCFinalY + 5,
          margin: 10,
          showFoot: "lastPage",
        });

        let YCOFinalY = (doc as any).lastAutoTable.finalY + 15;

        if (YCOFinalY + 74 > 290) {
          doc.addPage();
          YCOFinalY = 20;
        }

        doc.text("Your Refunded Orders", 10, YCOFinalY);
        autoTable(doc, {
          headStyles: { fillColor: "#084574" },
          footStyles: { fillColor: "#DFF0F2", textColor: "#000000" },
          head: [val.refundedOrders.head],
          body: val.refundedOrders.body,
          foot: [val.refundedOrders.foot],
          startY: YCOFinalY + 5,
          margin: 10,
          showFoot: "lastPage",
        });

        let YROFinalY = (doc as any).lastAutoTable.finalY + 15;

        // If refund orders overflows pdf page height.
        if (YROFinalY + 74 > 290) {
          doc.addPage();
          YROFinalY = 20;
        }

        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Subtotal of Waitron Charges", 10, YROFinalY);

        doc.setFontSize(14);
        doc.setFont("helvetica", "normal");
        doc.text(val.subtotal, 10, YROFinalY + 8);

        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Total Payable to restaurant", 10, YROFinalY + 25);

        doc.setFontSize(14);
        doc.setFont("helvetica", "normal");
        doc.text(val.total, 10, YROFinalY + 33);

        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Your Bank Details", 10, YROFinalY + 50);

        doc.setFontSize(14);
        doc.setFont("helvetica", "normal");
        doc.text("Bank Name:" + val.bankDetails[0].name, 10, YROFinalY + 58);
        doc.text(
          "Sort Code:" + val.bankDetails[0].sortCode,
          10,
          YROFinalY + 66
        );
        doc.text(
          "Account Number:" + val.bankDetails[0].accountNumber,
          10,
          YROFinalY + 74
        );

        let pageCount = doc.getNumberOfPages(); //Total Page Number
        let i;
        for (i = 0; i < pageCount; i++) {
          doc.setPage(i);
          let pageCurrent = doc.getCurrentPageInfo().pageNumber; //Current Page
          doc.setFontSize(12);
          doc.text("page: " + pageCurrent + "/" + pageCount, 180, 290);
        }

        doc.output("dataurlnewwindow");

        // doc.save("table.pdf");
      });
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }
}
