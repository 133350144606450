import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { BooleanLiteral } from "typescript";

@Component({
  selector: "app-cookie-banner",
  templateUrl: "./cookie-banner.component.html",
  styleUrls: ["./cookie-banner.component.scss"],
})
export class CookieBannerComponent implements OnInit {
  constructor(private ls: LocalStorageService) {}

  showCookie: Boolean = false;

  ngOnInit(): void {
    this.checkIfCookieExists();
  }

  checkIfCookieExists() {
    // if (!!this.ls.getCookie("waitronCookiePolicy=")) {
    //   this.showCookie = false;
    // } else {
    //   this.showCookie = true;
    // }
    this.showCookie = true;
  }

  hasAcceptedCookie() {
    this.ls.setCookie("waitronCookiePolicy=", "accepted");
    this.showCookie = false;
  }
}
