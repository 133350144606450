<div class="container">
  <header>
    <div class="icon-container">
      <a routerLink="../cart" ActivatedRoute>
        <span class="custom-icon basket-icon"> </span>
      </a>

      <a routerLink="../account" ActivatedRoute>
        <span class="user-icon custom-icon"></span>
      </a>
    </div>

    <div class="info">
      <h1>
        {{ venue.companyDisplayName }}
      </h1>

      <a
        href="http://maps.google.com/?q={{ address.firstLine }}, {{
          address.city
        }}"
        target="_blank"
      >
        <span class="custom-icon location-icon"></span>
        {{ address.firstLine }},
        {{ address.city }}
      </a>

      <a href="tel:{{ venue.contactNumber }}">
        <span class="custom-icon phone-icon"></span>
        {{ venue.contactNumber }}
      </a>
    </div>
  </header>

  <div class="venue">
    <div class="description">
      <h2 class="info">{{ venue.companyDisplayName }}</h2>
      <p [ngClass]="{ 'show-less': showShortDesciption }">
        {{ venue?.description }}
      </p>

      <span (click)="alterDescriptionText()">
        {{ showShortDesciption ? "Read more" : "Read less" }}
      </span>
    </div>

    <div class="opening-times">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="title"> Opening times </span>
              <span> - {{ openingTime }} - {{ closingTime }}</span>
              <!-- <span *ngIf="!isOpen"> CLOSED</span> -->
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div
            *ngFor="let day of openingTimes"
            [ngClass]="todayDay === day.day ? 'is-today' : ''"
            class="accordian-content"
          >
            <p>
              {{ day.day }}
            </p>
            <p>
              {{ day.openTime }} -
              {{ day.closeTime }}
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="facilities">
      <h2 class="info">Facilities</h2>

      <div class="facilities-content">
        <div *ngFor="let facility of facilities" class="facility">
          <span class="material-icons">
            {{ facility.imgName }}
          </span>
          <p>{{ facility.name }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
