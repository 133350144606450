import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AdminService } from 'src/app/SERVICES/admin.service';
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { SuccessComponent } from '../../success/success.component';

@Component({
  selector: 'app-delete-product',
  templateUrl: './delete-product.component.html',
  styleUrls: ['./delete-product.component.scss']
})
export class DeleteProductComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ServicesService,
    private as: AdminService,
    private ls: LocalStorageService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteProductComponent>,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  durationInSeconds = 2;
  id = this.data[1];
  deleteType = this.data[2];

  ngOnInit(): void {
    console.log('data', this.data);
    console.log('data', this.id);

  }

  delete() {

    let sesh = this.ls.checkCookieOnly('sesh=');
    let locID = this.ls.getSessionStorage('locID');

    let data = {
      action: "deleteRecored",
      delete: this.deleteType,
      id: this.id,
      key: sesh,
      locId: locID,
      userId: this.service.userId
    }

    this.spinner.show();
    this.as.postReq(data).pipe(
      finalize(() => this.spinner.hide())
    ).subscribe(res => {
      console.log('res', res);

      if (res.reload === 0) {
        if (res.result === "success") {
          this.openSnackBar();
          this.dialogRef.close();
        }
      } else if (res.reload === 3) {
        this.router.navigateByUrl('/');
        this.service.isLoggedIn = false;
        document.cookie = 'sesh=' + '; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'PHPSESSID=' + '; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    })
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snackbar-container'],
      data: 'Product has been deleted.'
    });
  }




}
