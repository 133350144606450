<div class="container">

  <div *ngIf="items.length > 0">
    <!-- <div class="page-title">
      <h3>Order History </h3>
    </div> -->

    <div class="order-history">
      <h3 class="title"> Order History </h3>
      <div class="cart-item" *ngFor="let item of items">
        <a [routerLink]="item.link" routerActive>
          <div class="item">
            <h3> {{ item.orderNo }} </h3>
          </div>

          <div class="date">
            <h5>{{ item.date | date }}</h5>

            <mat-icon aria-label="Next button title" fontSet="material-icons-outlined"> keyboard_arrow_right
            </mat-icon>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="empty-history" *ngIf="items.length === 0">
    <div class="icon-centered">
      <mat-icon aria-label="Shopping cart" fontSet="material-icons-outlined">
        history</mat-icon>

      <h3> You currently have no previous orders from 'this place' </h3>

      <a routerLink=""> <button mat-button color="primary"> Return to menu </button> </a>
    </div>

  </div>

  <router-outlet></router-outlet>
</div>