<ngx-spinner></ngx-spinner>
<app-breadcrumbs></app-breadcrumbs>

<div class="categories-container">
  <div class="categories" *ngFor="let l of listArray">
    <a (click)="openDialog(l)">
      <div class="category">
        <div>
          <p class="title">{{ l.name }}</p>
          <p class="description">{{ l.itemDesc }}</p>
        </div>

        <p class="price">{{ l.price | currency: "GBP" }}</p>
      </div>
    </a>
  </div>
  <router-outlet></router-outlet>
</div>
<span class="custom-icon Drinks-background"></span>
