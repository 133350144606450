import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ErrorComponent } from "src/app/MODALS/error/error.component";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  constructor(
    private router: Router,
    private ls: LocalStorageService,
    private service: ServicesService,
    public dialog: MatDialog
  ) {}

  selectedPub: string;
  locId: string;
  companyList: any;
  companyName: any = [];
  // companyList: Array<[displayName: string, locId: string]>;
  // companyList: Observable<any[]>;

  myControl = new FormControl();
  options: any[] = [{ name: "Mary" }, { name: "Shelley" }, { name: "Igor" }];
  filteredOptions: Observable<any[]>;

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");
    this.setHome();
  }

  setHome() {
    this.service.getReqCompany("?action=CompanyList").subscribe((CL) => {
      console.log("companyList", CL);
      this.companyList = CL.list;

      this.companyList.forEach((element) => {
        this.companyName.push(element.displayName);
      });

      // this.filteredOptions = this.myControl.valueChanges.pipe(
      //   startWith(""),
      //   map((value) => this._filter(value))
      // );

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.companyList.slice()))
      );
    });

    let path = document.location.pathname;
    let url = document.location.href;
    console.log("document.location.pathname", document.location.pathname);

    console.log("document.location.href", document.location.href);
    let regex = /.uk\/$|.uk\/#\/$/g; // Prod
    // let regex = /00\/$|00\/#\/$/g; // DEV

    let locIdCheck = url.match(regex);

    if (path === "/" && locIdCheck) {
      console.log("does not have path");
    } else if (path === "/about" || path === "/cart") {
      // This one has to show or hide the header when it is on the abut page
    } else {
      console.log("has path");
    }
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.companyName.filter(
  //     (option) => option.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.companyList.filter(
      (option) => option.displayName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  selectPub(value) {
    this.companyList.forEach((element) => {
      if (value === element.displayName) {
        let url = document.location.href;

        location.href = url + "?locID=" + element.locId;
        window.location.reload();
        this.router.navigateByUrl("/c/" + this.locId);
      }
    });
  }

  searchClick(ls) {
    let match: boolean = false;
    this.companyList.forEach((element) => {
      if (element.displayName.toLowerCase() == ls.toLowerCase()) {
        console.log("yes indeed", element.locId);
        let url = document.location.href;
        location.href = url + "?locID=" + element.locId;
        window.location.reload();
        this.router.navigateByUrl("/c/" + this.locId);
        match = true;
      }
    });

    if (!match) {
      let message = "Location not found, please try another location";
      this.openError(message);
    }
  }

  openError(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      panelClass: "modal-container",
      data: [{ message: message }],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }
}
