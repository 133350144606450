import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AdminService } from 'src/app/SERVICES/admin.service';
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { SuccessComponent } from '../../success/success.component';

@Component({
  selector: 'app-add-extras',
  templateUrl: './add-extras.component.html',
  styleUrls: ['./add-extras.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddExtrasComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddExtrasComponent>,
    private spinner: NgxSpinnerService
  ) { }

  addExtra: FormGroup;
  sesh: Observable<any>;
  durationInSeconds = 2;
  type: string;

  ngOnInit(): void {
    console.log('edit', this.data);
    this.type = this.data.type;

    if (this.type !== 'edit') {
      this.addExtra = this.formBuilder.group({
        productName: ['', [Validators.required]],
        price: [''],
        enableItem: [''],
        displayProductName: [''],

        action: 'newExtraItem'

      });
    } else if (this.type === 'edit') {
      let item = this.data.item;
      let isEnabled = item.enabled === '1' ? true : false;
      let dpn = item.displayProductName === '1' ? true : false;

      this.addExtra = this.formBuilder.group({
        productName: [item.name, [Validators.required]],
        price: [item.price],
        enableItem: [isEnabled],
        displayProductName: [dpn],
        action: 'updateExtraItem',
      });
    }


    this.sesh = this.ls.checkCookieOnly('sesh=');
    let locID = this.ls.getSessionStorage('locID');

    // let request = '?action=getExtras&key=' + this.sesh + '&locId=' + locID;
    let request = '?action=getExtras&key=' + this.sesh + '&locId=' + locID + '&userId=' + this.service.userId;
    this.as.getReq(request).subscribe((res: any) => {
      console.log('res', res);
    });

  }

  add() {
    let product: any = {
      'enabled': this.addExtra.value.enableItem,
      'displayProductName': this.addExtra.value.displayProductName,
      'productName': this.addExtra.value.productName,
      'price': this.addExtra.value.price,
    }

    if (this.type !== 'edit') {
      product.categoryId = this.data.catId;
    } else if (this.type === 'edit') {
      product.id = this.data.item.id;
    }

    const formData = new FormData();
    formData.append('product', JSON.stringify(product));
    formData.append('action', this.addExtra.value.action);
    formData.append('key', this.sesh.toString());
    formData.append('locId', this.ls.getSessionStorage('locID'));
    formData.append('userId', this.service.userId);

    this.spinner.show();
    return this.as.postReq(formData).pipe(
      finalize(() => this.spinner.hide())
    ).subscribe(res => {
      console.log('Add new product', res);

      if (res.result === 'success') {
        this.openSnackBar();
        this.dialogRef.close();
      }
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['snackbar-container'],
      data: 'Product has been added.'
    });
  }

}
