<div class="item-text">
  <h2 mat-dialog-title> New Product</h2>
  <!-- <h5 mat-dialog-title> Add New category </h5> -->
</div>

<form [formGroup]="addProduct" id="add-form" (ngSubmit)="add()">

  <mat-dialog-content class="mid-content">


    <div id="name">

      <div class="is-active">
        <h3> Name </h3>
        <mat-slide-toggle formControlName="enableItem" class="enable-item">Active</mat-slide-toggle>
      </div>
      <mat-form-field required appearance="outline">
        <input matInput type="text" placeholder="Carlsberg" formControlName="productName">
        <mat-hint class="hint" align="start"><strong>Choose a name for your new product.</strong> </mat-hint>
      </mat-form-field>

    </div>

    <div id="category">
      <h3> Sub Category </h3>
      <mat-form-field appearance="outline">
        <mat-select formControlName="subCategory" placeholder="Draught Beers" required>
          <mat-option *ngFor="let cat of categories; let i = index;" [value]="cat.id">
            {{ cat.title }}
          </mat-option>
        </mat-select>
        <mat-hint class="hint" align="start"><strong>Choose the sub category your product will belong to.</strong>
        </mat-hint>
      </mat-form-field>
    </div>

    <div id="description">
      <h3> Description </h3>
      <mat-form-field appearance="outline">
        <textarea matInput placeholder="Refreshingly bubbly pint" type="text" formControlName="description"></textarea>
        <mat-hint class="hint" align="start"><strong>Give your new product a description.</strong> </mat-hint>
      </mat-form-field>
    </div>

    <div id="abv-units">
      <div>
        <h3> ABV </h3>
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="5" formControlName="abv">
          <span matSuffix>%</span>
          <mat-hint class="hint" align="start"><strong>Enter the ABV of the product (if alcoholic).</strong> </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div id="vat">
      <h3> VAT Rate </h3>
      <mat-form-field appearance="outline" required>
        <input type="text" placeholder="Pick ones" aria-label="Number" matInput [matAutocomplete]="auto"
          formControlName="vat">
        <span matSuffix>%</span>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let vat of vatOptions | async" [value]="vat">
            {{vat}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint class="hint" align="start"><strong>Enter VAT rate</strong> </mat-hint>
      </mat-form-field>
    </div>

    <div id="extras">
      <h3> Extras </h3>
      <mat-form-field appearance="outline">
        <mat-select formControlName="extras" multiple placeholder="Top, Shandy, Lime, Blackcurrant">
          <mat-optgroup *ngFor="let e of extras | keyvalue; let i = index;" [label]="e.key">
            <mat-option *ngFor="let ex of e.value; let i = index;" [value]="ex.optionId"> {{ ex.name }} </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-hint class="hint" align="start"><strong>Add any extas you wish to display on the product.</strong>
        </mat-hint>
      </mat-form-field>
    </div>

    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- ////////////////////////////////////////////////////////////////////////////////////////// -->

    <div formArrayName="options" class="options">
      <h2> Product Option </h2>
      <div *ngFor="let option of options.controls; let i=index" class="option-array">
        <div [formGroupName]="i">
          <div class="option-title">
            <h4> Option {{i + 1}} </h4>


            <div class="option-icons">
              <mat-slide-toggle formControlName="displayProductName" class="show-name">Display product name
              </mat-slide-toggle>

              <mat-slide-toggle formControlName="displayOptionActive">Active</mat-slide-toggle>
              <mat-slide-toggle formControlName="optionDefault">Default</mat-slide-toggle>
              <mat-icon aria-label="Next button title" fontSet="material-icons-outlined" (click)="removeOption(i)">
                delete
              </mat-icon>
            </div>
          </div>

          <div id="option-toggles">
            <!-- <mat-slide-toggle formControlName="displayProductName" class="show-name">Display product name
            </mat-slide-toggle> -->

            <!-- <mat-slide-toggle formControlName="displayOptionQTY">Display quantity</mat-slide-toggle> -->
          </div>

          <div id="option-name" *ngIf="this.addProduct.value.options[i].displayProductName === true">
            <h3> Name </h3>
            <mat-form-field appearance="outline">
              <input matInput type="text" placeholder="Pint" formControlName="optionName">
              <mat-hint class="hint" align="start"><strong>Enter a name you want to display for this option.</strong>
              </mat-hint>
            </mat-form-field>
          </div>

          <div id="qty-unit">
            <div>
              <h3> Portion </h3>
              <mat-form-field class="example-full-width" appearance="outline" required>
                <input type="text" placeholder="Pint" aria-label="Number" matInput [matAutocomplete]="auto"
                  formControlName="optionPortion">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let optionTing of filteredOptions | async" [value]="optionTing">
                    {{optionTing}}
                  </mat-option>
                </mat-autocomplete>
                <mat-hint class="hint" align="start"><strong>Enter portion size</strong> </mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div id="price-vat">
            <div>
              <h3> Price </h3>
              <mat-form-field appearance="outline" required>
                <input matInput type="text" placeholder="3.50" formControlName="optionPrice">
                <span matPrefix>£&nbsp;</span>
                <mat-hint class="hint" align="start"><strong>Enter a price for this option (before VAT).</strong>
                </mat-hint>
              </mat-form-field>
            </div>

            <div>
              <h3> Units </h3>
              <mat-form-field appearance="outline">
                <input matInput type="text" placeholder="2" formControlName="units">
                <mat-hint class="hint" align="start"><strong>Enter the Units of the default option (if
                    alcoholic).</strong>
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="toggle-active"> -->
    <!-- <mat-slide-toggle formControlName="optionActive">Active</mat-slide-toggle> -->
    <!-- </div> -->

    <!-- <div id="displayProduct"> -->
    <!-- <mat-slide-toggle formControlName="displayProductName">Display product name</mat-slide-toggle> -->
    <!-- </div> -->

    <!-- <div id="displayQTY"> -->
    <!-- <mat-slide-toggle formControlName="displayOptionQTY">Display quantity</mat-slide-toggle> -->
    <!-- </div> -->

    <div class="add-new">
      <button mat-button type="button" color="primary" (click)="addOption();"> Add new option </button>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" type="button" mat-dialog-close> Cancel </button>
    <button mat-button color="primary" type="submit" value="add" [disabled]="addProduct.invalid"> Add </button>

    <!-- [mat-dialog-close]="true"  -->
  </mat-dialog-actions>
</form>