import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-order-confirm",
  templateUrl: "./order-confirm.component.html",
  styleUrls: ["./order-confirm.component.scss"],
})
export class OrderConfirmComponent implements OnInit {
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarRef: MatSnackBarRef<OrderConfirmComponent>
  ) {}
  orderId;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params["orderId"]) {
        this.orderId = params["orderId"];
      }
      // else if (this.data) {
      // this.orderId = this.data
      // }
    });
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
