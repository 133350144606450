<!-- <div class="item-text">
  <h2 mat-dialog-title> Thank you for your order </h2>
</div> -->

<div class="snackbar-confirm">
  <!-- <mat-dialog-content class="mid-content"> -->
  <p class="title">
    Order <strong> #{{ orderId }} </strong> submitted
  </p>
  <!-- </mat-dialog-content> -->
  <!-- <mat-dialog-actions> -->
  <button mat-button color="accent" type="button" mat-dialog-close (click)="dismiss()">x</button>
  <!-- </mat-dialog-actions> -->
</div>
