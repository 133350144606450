import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  items: any = [];
  
  constructor(private service: ServicesService) { }

  ngOnInit(): void {

    this.service.getOrderHisotry('order-history').subscribe( x => {
      this.items = x;
    });
  }

}
