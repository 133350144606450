<div class="item-text">
  <h2 mat-dialog-title class="info">Error</h2>
</div>

<mat-dialog-content class="mid-content">
  {{ message }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" mat-dialog-close>
    Close
  </button>
</mat-dialog-actions>
