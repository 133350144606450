import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ServicesService } from "src/app/SERVICES/services.service";
import { Router } from "@angular/router";
import { MyErrorStateMatcher } from "../../my-error-state-matcher";
import { Status } from "src/app/INTERFACES/status-message";
import { DatePipe } from "@angular/common";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { SuccessComponent } from "src/app/MODALS/success/success.component";
import { MatSnackBar } from "@angular/material/snack-bar";

import * as _moment from "moment";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  providers: [DatePipe],
})
export class RegisterComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private service: ServicesService,
    private router: Router,
    private datePipe: DatePipe,
    private seshService: LocalStorageService,
    private ls: LocalStorageService,
    private _snackBar: MatSnackBar
  ) {}

  registerForm: FormGroup;
  isSubmitted: boolean = false;
  isTrue: boolean = false;
  matcher = new MyErrorStateMatcher();
  passwordIsValid: boolean;
  hasNumber: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasSymbol: boolean;
  hasEight: boolean;
  statusFailed: boolean = false;
  status: Status = {
    message: "",
  };
  startDate = new Date(1992, 0, 1);
  locId: string;

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");

    this.registerForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, this.matcher.strong]],
        confirmPassword: ["", [Validators.required]],
        dob: ["", [Validators.required, this.matcher.dob]],
        action: "newUser",
      },
      { validator: this.checkPasswords("password", "confirmPassword") }
    );
  }

  get formControls() {
    return this.registerForm.controls;
  }

  checkPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      this.hasNumber = MyErrorStateMatcher.hasNumber;
      this.hasUpper = MyErrorStateMatcher.hasUpper;
      this.hasLower = MyErrorStateMatcher.hasLower;
      this.hasSymbol = MyErrorStateMatcher.hasSymbol;
      this.hasEight = MyErrorStateMatcher.hasEight;

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  register(event) {
    const datePipe = this.datePipe.transform(
      this.registerForm.get("dob").value,
      "yyyy-MM-dd"
    );
    let locID = this.ls.getSessionStorage("locID");
    const formData = new FormData();

    formData.append("action", this.registerForm.value.action);
    formData.append("fname", this.registerForm.value.firstName);
    formData.append("lname", this.registerForm.value.lastName);
    formData.append("email", this.registerForm.value.email);
    formData.append("password", this.registerForm.value.password);
    formData.append("dob", datePipe);
    formData.append("title", "AI");
    formData.append("gender", "M");
    formData.append("locId", locID);

    this.service.register(formData).subscribe(
      (res) => {
        if (res?.results?.status === "success") {
          this.service.registeredSucces = true;
          // this.router.navigateByUrl('/account');

          this.openSnackBar();
          this.login();

          // TODO. If redirected from the checkout page then go back there.
          // If manually came here by user, go back to order page.
        } else if (res?.results?.status === "failed") {
          this.statusFailed = true;
          this.status.message = res.results.message;
        }
      },
      (err) => console.log("err", err)
    );
  }

  login() {
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("action", "login");
    formData.append("email", this.registerForm.value.email);
    formData.append("password", this.registerForm.value.password);
    formData.append("locId", locID);

    // add if to not subscibe if email and password isn't entered
    this.service.postReqLogin(formData).subscribe(
      (res) => {
        if (res.login.status === "success") {
          this.seshService.setCookie("sesh=", res.login.result.user);
          this.service.isLoggedIn = true;

          if (res.login.result?.type === "Admin") {
            this.service.isAdmin = true;
            this.service.isUserAdmin.next(true);
            this.router.navigateByUrl("/admin");
          } else {
            let fromCheckout =
              this.seshService.getSessionStorage("fromCheckout");
            if (fromCheckout) {
              this.router.navigate(["/cart", { CFL: true }]);
              this.seshService.removeSessionStorage("fromCheckout");
            } else {
              this.router.navigate(["c", this.locId]);
            }
          }
        } else if (res.login.status === "failed") {
          this.statusFailed = true;
          this.status.message = res.login.message; //TODO Look at this.
        }
      },
      (err) => console.log("err", err)
    );
  }

  openSnackBar() {
    let durationInSeconds = 4;
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: durationInSeconds * 1000,
      panelClass: ["snackbar-container"],
      data: "Your account has been created.",
    });
  }
}
