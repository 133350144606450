import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-view-menu",
  templateUrl: "./view-menu.component.html",
  styleUrls: ["./view-menu.component.scss"],
})
export class ViewMenusComponent implements OnInit {
  categorys: Array<{
    name: string;
    img: string;
  }> = [];

  constructor(
    private service: ServicesService,
    private ls: LocalStorageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.showMenus();
  }

  showMenus() {
    this.spinner.show();
    let locID = this.ls.getSessionStorage("locID");

    this.service
      .getReq(
        "?action=mainProductOptions&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        this.categorys = res.mainOptions;
      });
  }

  setCatSesh(id) {
    this.ls.setSessionStorage("menuId", id);
  }
}
