import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServicesService } from "src/app/SERVICES/services.service";
import { Location } from '@angular/common'

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadcrumbsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ServicesService,
    private location: Location
  ) {}

  breadcrumbs: any = [];
  breadcrumbLinks: any;
  mainPath;
  lastBreadCrumb;

  ngOnInit(): void {
    this.getBreadcrumb();

    let paths = this.router.url.split("/");

    // This is to set breadcrumn url.
    let i;
    for (i = 0; i < paths.length; i++) {
      if (paths[i] === "admin") {
        this.mainPath = paths[i];
      } else if (paths[i] === "category") {
        this.mainPath = paths[i];
      }

      console.log("mainPath", this.mainPath);
    }
  }

  getBreadcrumb() {
    this.route.params.subscribe((params) => {
      let obj: Object = {
        title: params.cat,
        link: params.cat,
      };
      this.breadcrumbs.push(obj);
      this.breadcrumbLinks = params;

      console.log("this.breadcrumbs", this.breadcrumbs);
      console.log("this.breadcrumbLinks", this.breadcrumbLinks);

      if (params.id) {
        obj = {
          title: params.id,
          link: params.cat + "/" + params.id,
        };

        this.breadcrumbs.push(obj);
        this.breadcrumbLinks = params;
      }
    });

    let lastBC = this.breadcrumbs.length - 1;
    this.lastBreadCrumb = this.breadcrumbs[lastBC].title;

  }

  back(): void {
    this.location.back();
  }
}
