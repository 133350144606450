import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "src/app/SERVICES/common/login.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
declare const gapi: any;

@Component({
  selector: "app-g-signin",
  templateUrl: "./g-signin.component.html",
  styleUrls: ["./g-signin.component.scss"],
})
export class GSigninComponent implements OnInit {
  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService,
    private service: ServicesService,
    private loginService: LoginService,
    private ls: LocalStorageService
  ) {}

  // private clientId: string =
  //   "1026065212492-0g31cgam9n519o227tkl4s485grcviq9.apps.googleusercontent.com";

  // private scope = [
  //   "profile",
  //   "email",
  //   "https://www.googleapis.com/auth/plus.me",
  //   "https://www.googleapis.com/auth/contacts.readonly",
  //   "https://www.googleapis.com/auth/admin.directory.user.readonly",
  // ].join(" ");

  locId = this.ls.getSessionStorage("locID");

  // public auth2: any;

  // ngOnInit(): void {}

  // public googleInit() {
  //   gapi.load("auth2", () => {
  //     this.auth2 = gapi.auth2.init({
  //       client_id: this.clientId,
  //       cookie_policy: "none",
  //       scope: "profile email",
  //       ux_mode: "redirect",
  //       redirect_uri: 'https://localhost:4200',
  //       redirect_uri_path: '/#/c/waitronInn',
  //       responstType: null,
  //     });
  //     this.attachSignin(this.element.nativeElement.firstChild);
  //   });
  // }

  // public attachSignin(element) {
  //   this.auth2.attachClickHandler(
  //     element,
  //     {},
  //     (googleUser) => {
  //       let id_token = googleUser.getAuthResponse().id_token;

  //       const formData = new FormData();
  //       formData.append("action", "googleLogin");
  //       formData.append("locId", this.locId);
  //       formData.append("googleToken", id_token);

  //       this.loginService.login(formData);
  //       // TODO: Router navigate here still isn't working :( Fix it!!
  //     },
  //     function (error) {
  //       console.log(JSON.stringify(error, undefined, 2));
  //     }
  //   );
  // }

  // ngAfterViewInit(): void {
  //   this.googleInit();
  // }
  user: SocialUser;

  ngOnInit() {}

  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(() => {
      this.socialAuthService.authState.subscribe((user) => {
        const formData = new FormData();
        formData.append("action", "googleLogin");
        formData.append("locId", this.locId);
        formData.append("googleToken", user.idToken);
        this.loginService.login(formData);
      });
    });
  }

  logout() {}
}
