import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { ThrowStmt } from "@angular/compiler";
import { ServicesService } from "./SERVICES/services.service";
import { LocalStorageService } from "./SERVICES/local-storage.service";
import { CartService } from "./SERVICES/cart.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { fadeAnimation } from "./animation";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  update: boolean = false;
  navigationLinks: Array<Object> = [
    { name: "Home", link: "about", icon: "home" },
    { name: "Order", link: "", icon: "receipt" },
    { name: "My Account", link: "account", icon: "account_circle" },
    // { name: 'Order History', link: 'history', icon: 'history' },
  ];

  isAdmin: boolean;
  hasPath: boolean;
  companyList: Array<[displayName: string, locId: string]>;
  showBottomNav: boolean = true;
  locId: string;
  adminLinks: Array<Object> = [];
  selectedPub: string;

  constructor(
    updates: SwUpdate,
    private service: ServicesService,
    private ls: LocalStorageService,
    private cart: CartService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    updates.available.subscribe((event) => {
      updates.activateUpdate().then(() => document.location.reload());
    });
  }

  ngOnInit() {
    this.locId = this.ls.getSessionStorage("locID");

    this.checkStorage();
    // this.checkSession();

    if (this.checkSession()) {
      this.service.checkIfAdmin().subscribe((x) => {
        this.isAdmin = x;
        this.service.isUserAdmin.next(x);
      });
    }

    this.locIdChangeURL();
  }

  locIdChangeURL() {
    let urlHasLocID: boolean = document.location.hash.includes("locID=");
    let locId = this.ls.getSessionStorage("locID");

    if (urlHasLocID) {
      this.router.navigateByUrl("/c/" + locId);
    }
  }

  checkStorage() {
    let cartStorage = this.ls.getSessionStorage("cart");

    if (cartStorage && cartStorage != "[]") {
      let cartProducts = JSON.parse(cartStorage);
      let i;
      for (i = 0; i < cartProducts.length; i++) {
        this.cart.addToCart(cartProducts[i]);
      }
    }
  }

  checkSession() {
    return this.ls.checkCookie("sesh=");
  }
}
