import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdminService } from "src/app/SERVICES/admin.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, of } from "rxjs";
import { Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddProductComponent } from "src/app/MODALS/ADMIN/add-product/add-product.component";
import { AddCategoryComponent } from "src/app/MODALS/ADMIN/add-category/add-category.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ThemePalette } from "@angular/material/core";
import { DeleteProductComponent } from "src/app/MODALS/ADMIN/delete-product/delete-product.component";
import { AddMenuComponent } from "src/app/MODALS/ADMIN/add-menu/add-menu.component";
import { ServicesService } from "src/app/SERVICES/services.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-catalog",
  templateUrl: "./menus.component.html",
  styleUrls: ["./menus.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenusComponent implements OnInit {
  formGroup: FormGroup;
  todaysDate = new Date();

  constructor(
    private as: AdminService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private service: ServicesService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  categorys: any[] = [];
  checked = true;
  disabled = false;
  extras;

  sesh = this.ls.checkCookieOnly("sesh=");
  locId = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;

  ngOnInit(): void {
    this.showMenus();

    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  showMenus() {
    let key = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");
    this.spinner.show();

    this.as
      .getReq(
        "?action=mainProductOptionsAdmin&key=" +
          key +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        if (res.mainOptions.result?.mainOptions) {
          this.categorys = res.mainOptions.result?.mainOptions;
        } else {
          this.categorys = res.mainOptions.result;
        }
      });
  }

  // TODO: This will go on the next page I think
  addNewMenu(title, item) {
    const dialogRef = this.dialog.open(AddMenuComponent, {
      panelClass: "menu-modal-container",
      // data: item,
      data: [{ title: title, item: item }],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);

      if (result === undefined) {
        this.showMenus();
        console.log("show menus");
      }
    });
  }

  addCategoryDialog() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: "modal-container",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }

  toggled(id, e, c, action) {
    let sesh = this.ls.checkCookieOnly("sesh=").toString();
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("action", "enableDisableValue");
    formData.append("update", action);
    formData.append("col", "enabled");
    formData.append("id", id);
    formData.append("key", sesh);
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    if (e.checked) {
      formData.append("value", "1");
    } else {
      formData.append("value", "0");
    }

    this.spinner.show();
    this.as
      .postReq(formData)
      .pipe(
        // this.as.toggleActive(formData).pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe((res) => {
        if (res.reload === 0) {
          if (res.result === "success") {
            c.enabled === "0" ? (c.enabled = "1") : (c.enabled = "0");
          }
        } else if (res.reload === 3) {
          this.router.navigateByUrl("/");
          this.service.isLoggedIn = false;
          document.cookie =
            "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
      });
  }

  setCatSesh(id) {
    this.ls.setSessionStorage("menuIdAdmin", id);
  }

  deleteProduct(item, id, deleteItem) {
    const dialogRef = this.dialog.open(DeleteProductComponent, {
      panelClass: "delete-product-container",
      data: [item, id, deleteItem],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);

      if (result === undefined) {
        this.showMenus();
      }
    });
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }
}
