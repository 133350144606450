import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { ServicesService } from "./services.service";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ls: LocalStorageService,
    private service: ServicesService
  ) {}

  private AUTH_SERVER = "https://www.waitron.azui.co.uk";

  categorys = [
    { name: "Drinks", img: "../../../assets/images/drinks.jpg" },
    { name: "Snacks", img: "../../../assets/images/snacks.jpg" },
  ];

  getReqOrder(action) {
    let path = "/php/order/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  // getRepoIssues(
  //   sort: string,
  //   order: string,
  //   page: number
  // ): Observable<GithubApi> {
  //   const href = "https://api.github.com/search/issues";
  //   const requestUrl = `${href}?q=repo:angular/components&sort=${sort}&order=${order}&page=${
  //     page + 1
  //   }`;

  //   return this._httpClient.get<GithubApi>(requestUrl);
  // }

  getReq(action) {
    let path = "/php/waitron/function.php" + action;
    return this.http.get<any>(`${this.AUTH_SERVER}` + path, {
      withCredentials: true,
    });
  }

  postReqOrder(form: any) {
    let path = "/php/order/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form, {
      withCredentials: true,
    });
  }

  postReq(form: any) {
    let path = "/php/waitron/function.php";
    return this.http.post<any>(`${this.AUTH_SERVER}` + path, form, {
      withCredentials: true,
    });
  }
}
