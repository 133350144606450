import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddComponent } from '../../MODALS/add/add.component'
import { LocalStorageService } from 'src/app/SERVICES/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ServicesService,
    public dialog: MatDialog,
    private ls: LocalStorageService,
    private spinner: NgxSpinnerService) { }
  listArray: Array<any>;

  currentCategory: string;
  categoryTitle: string;
  currentList: string;
  currentRoute: Object;

  ngOnInit(): void {
    // this.categorys = this.service.categorys;
    this.getList();
    // this.updateDetails();
  }

  openDialog(item) {
    const dialogRef = this.dialog.open(AddComponent, {
      panelClass: 'modal-container',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
    });
  }

  getList() {

    this.currentRoute = this.router.url.split(/\//);
    this.route.params.subscribe(params => {
      this.currentCategory = params.cat;
      this.currentList = params.id;
    });

    this.spinner.show();
    let id = this.ls.getSessionStorage('menuId');
    let locID = this.ls.getSessionStorage('locID');

    this.service.getReq('?action=getList&productType=' + id + '&locId=' + locID + '&userId=' + this.service.userId).pipe(
      finalize(() => this.spinner.hide())
    ).subscribe((res: any) => {
      let itemsLength = res.products[this.currentList].items;
      this.categoryTitle = this.currentList;
      this.listArray = itemsLength;

      // Create the description which is shown.
      let i;
      for (i = 0; i < this.listArray.length; i++) {
        this.listArray[i].itemDesc = this.listArray[i].desc + ' ' + this.listArray[i].abv + ' ABV, ' + this.listArray[i].units + ' Units';
      }
    });
  }
}
