<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">Orders</h1>

      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="outer-container-admin">
    <div class="order-sidebar">
      <mat-tab-group>
        <mat-tab>
          <ng-template matTabLabel>
            <span>Pending</span>
            <span *ngIf="pendingOrders.length > 0">
              ({{ pendingOrders.length }})
            </span>
          </ng-template>

          <app-open-orders
            [pendingOrders]="pendingOrders"
            [statusList]="statusList"
            (onItemPicked)="showItem($event)"
          >
          </app-open-orders>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>
            <span>Completed</span>
            <span *ngIf="completedOrders.length > 0">
              ({{ completedOrders.length }})
            </span>
          </ng-template>

          <app-completed-orders
            [completedOrders]="completedOrders"
            [statusList]="statusList"
            (onItemPicked)="showItem($event)"
          ></app-completed-orders>
        </mat-tab>
        <!-- <mat-tab label="Canceled"> Content 5 </mat-tab> -->
      </mat-tab-group>
    </div>
    <div class="order-container">
      <app-admin-order
        [orderItems]="parentData"
        [statusList]="statusList"
      ></app-admin-order>
    </div>
  </div>
</div>
