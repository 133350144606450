<ngx-spinner></ngx-spinner>
<div class="item-text">
  <h2 mat-dialog-title> Delete Product</h2>
</div>

<mat-dialog-content class="mid-content">
  Are you sure you want to delete this product?
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" mat-dialog-close> Cancel </button>
  <button mat-button color="primary" type="submit" value="add" (click)=delete();> Delete </button>
  <!-- [mat-dialog-close]="true"  -->
</mat-dialog-actions>