<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">Employees</h1>
      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="data-content">
    <!-- Filter goes here -->
    <div class="filters">
      <mat-form-field>
        <mat-label>Email </mat-label>
        <input matInput (keyup)="filterId($event)" #idFilter />
      </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="Role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">
          {{ element.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Menu">
        <th mat-header-cell *matHeaderCellDef>Menu</th>

        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <!-- (click)="openDialog(element)" -->
              <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[20, 40, 60, 80, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
