<ngx-spinner></ngx-spinner>
<app-login *ngIf="!isLoggedIn"></app-login>

<div *ngIf="isLoggedIn">
  <div class="container">
    <div class="header">
      <a routerLink="../">
        <mat-icon aria-label="Back Button" fontSet="material-icons-outlined">
          west</mat-icon
        >
        <p class="title">My Account</p>
      </a>
    </div>

    <div class="details">
      <div class="info">
        <p class="title">Personal Info</p>

        <div class="peronal">
          <div>
            <span class="custom-icon user-icon"></span>
            {{ customerInfo["first name"] }}
            {{ customerInfo["last name"] }}
          </div>

          <div>
            <mat-icon aria-label="email" fontSet="material-icons-outlined">
              email
            </mat-icon>

            {{ customerInfo.email }}
          </div>

          <div>
            <mat-icon aria-label="date age" fontSet="material-icons-outlined">
              date_range
            </mat-icon>
            {{ customerInfo["date of birth"] }}
          </div>
        </div>
      </div>

      <!-- <div class="info" *ngFor="let i of customerInfo | keyvalue">
        <h3>{{ i.key }}:</h3>
        <h4>{{ i.value }}</h4>
      </div> -->
    </div>

    <div class="logout-cta" style="margin-bottom: 90px">
      <div class="logout-button">
        <button mat-button color="primary" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>
