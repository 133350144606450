import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { LocalStorageService } from "../local-storage.service";
import { ServicesService } from "../services.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private service: ServicesService,
    private router: Router,
    private seshService: LocalStorageService,
    private ls: LocalStorageService
  ) {}

  login(formData) {
    // add if to not subscibe if email and password isn't entered

    let locId = this.ls.getSessionStorage("locID");
    this.service.postReqLogin(formData).subscribe(
      (res) => {
        if (res.login.status === "success") {
          this.seshService.setCookie("sesh=", res.login.result.user);
          this.service.isLoggedIn = true;
          let fromCheckout = this.seshService.getSessionStorage("fromCheckout");

          if (res.login.result?.type != 0) {
            this.service.isAdmin = res.login.result?.type;
            this.service.isUserAdmin.next(res.login.result?.type);

            switch (res.login.result?.type) {
              case "1":
              case "2":
              case "3":
              case "4":
              case "5":
              case "6":
                this.router.navigate(["c", locId, "admin", "dashboard"]);
                break;
              case "7":
                this.router.navigate(["c", locId, "admin", "orders"]);
                break;
              default:
                console.log("this should be a customer user");
                break;
            }
          } else if (fromCheckout) {
            // Normal user who tried to checkout

            this.router.navigate(["c", locId, "cart", { CFL: true }]);

            this.seshService.removeSessionStorage("fromCheckout");
          } else {
            // Just a normal user
            this.router.navigate(["c", locId]);
          }
        } else if (res.login.status === "failed") {
          // this.statusFailed = true;
          // this.status.message = res.login.message; //TODO Look at this.
        }
      },
      (err) => console.log("err", err)
    );
  }
}
