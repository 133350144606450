<!-- <app-header [nav]="navigationLinks" [navAdmin]="adminLinks" *ngIf="hasPath">
  <div
    id="app-root-body"
    [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
  </div>

  <app-bottom-nav></app-bottom-nav>
</app-header> -->

<!-- <app-header [nav]="navigationLinks" [navAdmin]="adminLinks" *ngIf="hasPath"> -->
  <router-outlet #o="outlet">
    <app-cookie-banner></app-cookie-banner>
  </router-outlet>
<!-- </app-header> -->

<!-- <div *ngIf="!hasPath">
  <mat-form-field appearance="fill">
    <mat-label>Please select a pub</mat-label>
    <mat-select
      [(value)]="selectedPub"
      (selectionChange)="selectPub(selectedPub)"
    >
      <mat-option></mat-option>
      <mat-option value="{{ list.locId }}" *ngFor="let list of companyList">{{
        list.displayName
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div> -->
