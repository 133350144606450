<div class="item-text">
  <h2 mat-dialog-title>Update Status</h2>
</div>

<mat-dialog-content class="mid-content">
  <!-- <form (submit)="onSubmit(currentProduct, portion, qty)"> -->
  <div class="portion">
    <mat-radio-group aria-labelledby="Updating Status" [(ngModel)]="currentId" name="status">
      <!-- [(ngModel)]="currentId" -->
      <div class="portion-size" *ngFor="let d of options">
        <mat-radio-button [value]="d.id" color="primary">
          <div class="optionTitle">
            <h3> {{d.status}} </h3>
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" type="button" mat-dialog-close> Cancel </button>
  <button mat-button color="primary" type="button" (click)="onSubmit(currentId)"> Update </button> <!--  -->
</mat-dialog-actions>