import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { filter, finalize } from "rxjs/operators";
import { AddCategoryComponent } from "src/app/MODALS/ADMIN/add-category/add-category.component";
import { AddExtrasComponent } from "src/app/MODALS/ADMIN/add-extras/add-extras.component";
import { AddProductComponent } from "src/app/MODALS/ADMIN/add-product/add-product.component";
import { DeleteProductComponent } from "src/app/MODALS/ADMIN/delete-product/delete-product.component";
import { EditCategoryComponent } from "src/app/MODALS/ADMIN/edit-category/edit-category.component";
import { EditProductComponent } from "src/app/MODALS/ADMIN/edit-product/edit-product.component";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

@Component({
  selector: "app-edit-menu",
  templateUrl: "./edit-menu.component.html",
  styleUrls: ["./edit-menu.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class EditMenuComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ServicesService,
    public dialog: MatDialog,
    private as: AdminService,
    private ls: LocalStorageService,
    private spinner: NgxSpinnerService
  ) {}

  categorys: Array<{
    name: string;
    img: string;
  }>;
  categoryArray: any;
  currentCategory: string;
  categoryTitle: string;
  currentRoute: string;
  paramFrom: string;
  action: string;

  ngOnInit(): void {
    this.currentRoute = this.router.url;

    console.log("this.routersssss");
    this.checkPath();

    this.getCat();
  }

  checkPath() {
    this.route.params.subscribe((param) => {
      this.paramFrom = param.cat;
      if (this.paramFrom === "extras") {
        this.action = "getExtrasByCategory";
      } else if (this.paramFrom === "menu") {
        this.action = "getAdminList";
      }
    });
  }

  getCat() {
    console.log("get cat");
    this.spinner.show();

    this.route.params.subscribe((params) => {
      let category = params.id;
      let id = this.ls.getSessionStorage("menuIdAdmin");
      let sesh = this.ls.checkCookieOnly("sesh=");
      let locID = this.ls.getSessionStorage("locID");

      this.service
        .getReq(
          "?action=" +
            this.action + //TODO Make this dynamic again when you get extras working.
            // "getAdminList" +
            "&productType=" +
            id +
            "&key=" +
            sesh +
            "&locId=" +
            locID +
            "&userId=" +
            this.service.userId
        )
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe((res: any) => {
          this.categoryArray = res.result;
          this.service.catArray = this.categoryArray;
        });
    });
  }

  openDialog(id, cat) {
    console.log("id", id);
    console.log("c", cat);
    if (cat === "menu") {
      const dialogRef = this.dialog.open(AddProductComponent, {
        panelClass: "add-product-container",
        data: id,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("Dialog result:", result);

        if (result === undefined) {
          this.getCat();
        }
      });
    } else if (cat === "extras") {
      const dialogRef = this.dialog.open(AddExtrasComponent, {
        panelClass: "add-extras-container",
        data: { catId: id },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("Dialog result:", result);

        if (result === undefined) {
          this.getCat();
        }
      });
    }
  }

  addCategoryDialog() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: "modal-container",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);

      if (result === undefined) {
        this.getCat();
      }
    });
  }

  editCategoryDialog(cat) {
    console.log("uses same");
    const dialogRef = this.dialog.open(EditCategoryComponent, {
      panelClass: "edit-container",
      data: [cat, this.paramFrom],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
      if (result === undefined) {
        this.getCat();
      }
    });
  }

  toggled(id, e, ci, name) {
    if (name === null && this.paramFrom === "extras") {
      name = "pxe";
    } else if (name === null && this.paramFrom === "menu") {
      name = "pro";
    }

    let sesh = this.ls.checkCookieOnly("sesh=").toString();
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("action", "enableDisableValue");
    formData.append("id", id);
    formData.append("update", name);
    formData.append("col", "enabled");
    formData.append("key", sesh);
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    if (e.checked) {
      formData.append("value", "1");
    } else {
      formData.append("value", "0");
    }

    this.spinner.show();
    this.as
      .postReq(formData)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res) => {
        if (res.reload === 0) {
          if (res.result === "success") {
            ci.enabled === "0" ? (ci.enabled = "1") : (ci.enabled = "0");
          }
        } else if (res.reload === 3) {
          this.router.navigateByUrl("/");
          this.service.isLoggedIn = false;
          document.cookie =
            "sesh=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            "PHPSESSID=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
      });
  }

  editProduct(item, catId, prodId, pf) {
    console.log("item", item);
    console.log("catId", catId);
    console.log("prodId", prodId);
    console.log("pf", pf);

    if (pf === "menu") {
      const dialogRef = this.dialog.open(EditProductComponent, {
        panelClass: "edit-product-container",
        data: [item, catId, prodId],
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("Dialog result:", result);
        if (result === undefined) {
          this.getCat();
        }
      });
    } else if (pf === "extras") {
      const dialogRef = this.dialog.open(AddExtrasComponent, {
        panelClass: "add-extras-container",
        data: { item: item, catId: catId, prodId: prodId, type: "edit" },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log("Dialog result:", result);

        if (result === undefined) {
          this.getCat();
        }
      });
    }
  }

  deleteProduct(item, id, deleteItem) {
    if (deleteItem === null && this.paramFrom === "extras") {
      deleteItem = "pxe";
    } else if (deleteItem === null && this.paramFrom === "menu") {
      deleteItem = "pro";
    }

    const dialogRef = this.dialog.open(DeleteProductComponent, {
      panelClass: "delete-product-container",
      data: [item, id, deleteItem],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);

      if (result === undefined) {
        this.getCat();
      }
    });
  }

  // NEXT PAGE TODO
  addExtra() {
    const dialogRef = this.dialog.open(AddExtrasComponent, {
      panelClass: "add-extras-container",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
      if (result === undefined) {
        this.getCat();
      }
    });
  }
}
