<ngx-spinner></ngx-spinner>
<div>
  <div class="add-menu-container" *ngIf="extras.length > 0">
    <button
      mat-flat-button
      color="primary"
      class="new-menu"
      (click)="addNewMenu('extra', 'item')"
    >
      <mat-icon aria-label="add menu" fontSet="material-icons-outlined">
        add </mat-icon
      >New extra
    </button>
  </div>

  <div class="extras-container" *ngIf="extras.length > 0">
    <div *ngFor="let e of extras | keyvalue; let i = index" class="menu">
      <div class="title-header">
        <h2 class="info">{{ e.value.extra }}</h2>

        <div class="icons">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="icon-button with a menu"
          >
            <mat-icon class="more-menu">more_vert</mat-icon>
          </button>
          <mat-menu class="mat-menu" #menu="matMenu">
            <button
              mat-menu-item
              routerLink="../extras/{{ e.value.extra }}"
              (click)="setCatSesh(e.value.id, 'extra')"
            >
              <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
              <span>Edit Extras</span>
            </button>

            <button
              mat-menu-item
              (click)="deleteProduct(e.value, e.value.id, 'cate')"
            >
              <mat-icon class="delete-icon" fontSet="material-icons-outlined"
                >delete</mat-icon
              >
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <h4 class="info">
        <strong>
          {{ e.value.items }}
        </strong>
        Item<span *ngIf="e.value.items != '1'">s</span>
      </h4>

      <div class="toggle-active">
        <h4 class="active info" *ngIf="e.value.enabled == 1">Active</h4>
        <h4 class="disabled info" *ngIf="e.value.enabled == 0">Disabled</h4>

        <mat-slide-toggle
          class="example-margin"
          color="blue"
          [checked]="e.value.enabled == '1' ? true : false"
          (change)="toggled(e.value.id, $event, e.value, 'cate')"
        >
        </mat-slide-toggle>
      </div>

      <!-- <div class="cta">
        <a>
          <button
            mat-flat-button
            color="primary"
            routerLink="{{ e.value.extra }}"
            (click)="setCatSesh(e.value.id)"
          >
            Edit Extras
          </button>
        </a>
      </div> -->
    </div>
    <!-- <button class="menu new-menu" (click)="addNewMenu('extra', 'item')">
      <mat-icon aria-label="add menu" fontSet="material-icons-outlined">
        add
      </mat-icon>
      <h4>New extra</h4>
    </button> -->
  </div>

  <div class="no-menus" *ngIf="extras.length == 0">
    <div class="icon-centered">
      <span class="custom-icon no-orders no-active"></span>
      <h2 class="info">There are no active extras</h2>
      <p class="title">
        Looks like there aren't any extras. Get started by creating one now.
      </p>

      <button
        mat-button
        color="primary"
        class="new-menu"
        (click)="addNewMenu('menu', 'item')"
      >
        <mat-icon aria-label="add menu" fontSet="material-icons-outlined">
          add </mat-icon
        >New menu
      </button>
    </div>
  </div>
</div>
