import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServicesService } from 'src/app/SERVICES/services.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateStatusComponent } from 'src/app/MODALS/update-status/update-status.component';

@Component({
  selector: 'app-accepted-orders',
  templateUrl: './accepted-orders.component.html',
  styleUrls: ['./accepted-orders.component.scss']
})
export class AcceptedOrdersComponent implements OnInit {

  constructor(private service: ServicesService, public dialog: MatDialog) { }

  // orders: any;
  @Input() acceptedOrders: any;
  @Input() statusList: any;
  
  @Output() onItemPicked: EventEmitter<any> = new EventEmitter<any>();
  
  ngOnInit(): void {
  }

  openDialog(currentStatus, orderId) {
    const dialogRef = this.dialog.open(UpdateStatusComponent, {
      panelClass: 'modal-container',
      data: [this.statusList, currentStatus, orderId]
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
    });
  }

  itemClicked(item) {
    this.onItemPicked.emit([item]);
  }
}
