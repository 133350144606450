import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ServicesService } from "src/app/SERVICES/services.service";
import { MatDialog } from "@angular/material/dialog";
import { UpdateStatusComponent } from "src/app/MODALS/update-status/update-status.component";
import { Observable, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit, OnDestroy {
  constructor(
    private service: ServicesService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private ls: LocalStorageService
  ) {}

  contentHeight: number = 0;
  orders: any;
  statusList: any;
  pendingOrders: Array<any> = [];
  acceptedOrders: Array<any> = [];
  completedOrders: Array<any> = [];
  parentData: any;
  subscription: Subscription;
  todaysDate = new Date();
  interval;

  sesh = this.ls.checkCookieOnly("sesh=");
  locId = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;

  ngOnInit(): void {
    this.getService();
    this.addTimer();

    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  openDialog(currentStatus, orderId) {
    const dialogRef = this.dialog.open(UpdateStatusComponent, {
      panelClass: "modal-container",
      data: [this.statusList, currentStatus, orderId],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }

  splitOrders() {
    let i;
    for (i = 0; i < this.orders?.length; i++) {
      if (this.orders[i].orderStatus === "2") {
        this.pendingOrders.push(this.orders[i]);
      } else if (this.orders[i].orderStatus === "3") {
        this.acceptedOrders.push(this.orders[i]);
      } else if (this.orders[i].orderStatus === "4") {
        this.completedOrders.push(this.orders[i]);
      }
    }
  }

  public showItem(item: any): void {
    this.parentData = item;
  }

  addTimer() {
    this.interval = setInterval(() => {
      this.getService();
    }, 5000);
  }

  getService() {
    this.spinner.hide();

    let sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    this.subscription = this.service
      .getReqOrder(
        "?action=getOpenOrder&key=" +
          sesh +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe((res: any) => {
        this.orders = res.result?.data;
        this.statusList = res.result?.statusList;
        this.pendingOrders = [];
        this.acceptedOrders = [];
        this.completedOrders = [];
        this.splitOrders();
      });
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }

  ngOnDestroy() {
    console.log("has been destroyed");
    clearInterval(this.interval);
    // this.subscription.unsubscribe();
  }
}
