<div class="snackbar-confirm">
  <p class="title">Items added</p>

  <div class="button-group">
    <a [routerLink]="['c', locId, 'cart']">
      <button mat-button color="accent">View basket</button>

      <span class="material-icons"> chevron_right </span>
    </a>
  </div>
</div>
