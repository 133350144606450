<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="dashboard-header">
    <div class="header">
      <h1 class="info">Menus</h1>

      <p class="date">
        {{ todaysDate | date: "fullDate" }}
      </p>
    </div>

    <div class="account-info">
      <span class="custom-icon user-icon"> </span>
      <div class="account-title">
        <p class="title">{{ employeeName }}</p>
        <span> {{ employeeRole }}</span>
      </div>

      <!-- <mat-icon aria-label="expand more" fontSet="material-icons-outlined">
        expand_more
      </mat-icon> -->
    </div>
  </div>

  <div class="menu-container">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Menu">
        <ng-template matTabContent>
          <div>
            <div class="add-menu-container" *ngIf="categorys.length > 0">
              <button
                mat-flat-button
                color="primary"
                class="new-menu"
                (click)="addNewMenu('menu', 'item')"
              >
                <mat-icon
                  aria-label="add menu"
                  fontSet="material-icons-outlined"
                >
                  add </mat-icon
                >New menu
              </button>
            </div>
            <div class="menus-container" *ngIf="categorys.length > 0">
              <div
                *ngFor="let c of categorys; let i = index"
                class="menu is-{{ c.enabled }}"
              >
                <div class="title-header">
                  <h2 class="info">{{ c.name }}</h2>

                  <div class="icons">
                    <!-- <mat-icon aria-label="edit item" fontSet="material-icons-outlined"
                      (click)=editProduct(ci,c.value.categoryId);>
                      edit</mat-icon> -->

                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="icon-button with a menu"
                    >
                      <mat-icon class="more-menu">more_vert</mat-icon>
                    </button>
                    <mat-menu class="mat-menu" #menu="matMenu">
                      <button
                        mat-menu-item
                        routerLink="{{ c.name }}"
                        (click)="setCatSesh(c.id)"
                      >
                        <mat-icon fontSet="material-icons-outlined"
                          >edit</mat-icon
                        >
                        <span>Edit Menu</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="deleteProduct(c, c.id, 'prt')"
                      >
                        <mat-icon
                          class="delete-icon"
                          fontSet="material-icons-outlined"
                          >delete</mat-icon
                        >
                        <span>Delete</span>
                      </button>
                    </mat-menu>

                    <!-- <mat-icon
                      aria-label="delite item"
                      fontSet="material-icons-outlined"
                      (click)="deleteProduct(c, c.id, 'prt')"
                      class="delete-icon"
                    >
                      delete</mat-icon
                    > -->
                  </div>
                </div>

                <h4 class="info">
                  <strong> {{ c.productCount }} </strong> Item<span
                    *ngIf="c.productCount !== '1'"
                    >s
                  </span>
                </h4>

                <div class="toggle-active">
                  <h4 *ngIf="c.enabled == 1" class="active info">Active</h4>
                  <h4 *ngIf="c.enabled == 0" class="disabled info">Disabled</h4>

                  <mat-slide-toggle
                    class="example-margin"
                    [checked]="c.enabled == '1' ? true : false"
                    color="blue"
                    (change)="toggled(c.id, $event, c, 'prt')"
                  >
                  </mat-slide-toggle>
                </div>

                <!-- <div class="cta">
                  <a routerLink="{{ c.name }}" (click)="setCatSesh(c.id)">
                    <button mat-flat-button color="primary">Edit Menu</button>
                  </a>
                </div> -->
              </div>

              <!-- <button class="menu new-menu" (click)="addNewMenu('menu', 'item')">
                <mat-icon aria-label="add menu" fontSet="material-icons-outlined">
                  add
                </mat-icon>
                <h4>New menu</h4>
              </button> -->
            </div>

            <div class="no-menus" *ngIf="categorys.length == 0">
              <div class="icon-centered">
                <span class="custom-icon no-orders no-active"></span>
                <h2 class="info">There are no active menus</h2>
                <p class="title">
                  Looks like there aren't any menus. Get started by creating one now.
                </p>

                <button
                  mat-button
                  color="primary"
                  class="new-menu"
                  (click)="addNewMenu('menu', 'item')"
                >
                  <mat-icon
                    aria-label="add menu"
                    fontSet="material-icons-outlined"
                  >
                    add </mat-icon
                  >New menu
                </button>
              </div>
            </div>
          </div>
          <router-outlet></router-outlet>
        </ng-template>
      </mat-tab>
      <mat-tab label="Extras">
        <ng-template matTabContent>
          <app-admin-config></app-admin-config>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
