import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";

const ELEMENT_DATA: any[] = [];

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.scss"],
})
export class AdminUsersComponent implements OnInit, AfterViewInit {
  constructor(
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService
  ) {}

  displayedColumns: string[] = ["Name", "Email", "Role", "Menu"];
  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource<[]>(ELEMENT_DATA);
  todaysDate = new Date();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  sesh = this.ls.checkCookieOnly("sesh=");
  locId = this.ls.getSessionStorage("locID");

  employeeName: Observable<boolean> | boolean | Observable<string>;
  employeeRole: Observable<boolean> | boolean | Observable<string>;

  ngOnInit(): void {
    this.getEmployees();

    if (!!this.ls.getCookie("employeeName=")) {
      this.employeeName = this.ls.getCookie("employeeName=");
      this.employeeRole = this.ls.getCookie("employeeRole=");
    } else {
      this.getAdminUserInfo();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getEmployees() {
    let locID = this.ls.getSessionStorage("locID");
    let sesh = this.ls.checkCookieOnly("sesh=");

    this.service
      .getReqLogin(
        "?action=ViewUsers&key=" +
          sesh +
          "&locId=" +
          locID +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        console.log("res", res);
        const ELEMENT_DATA = res.result;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      });
  }

  filterId(event: Event) {
    this.dataSource.filterPredicate = function (
      data: any,
      filter: string
    ): boolean {
      return data.email.toLowerCase().includes(filter);
    };

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAdminUserInfo() {
    this.service
      .getReqLogin(
        "?action=adminUserInfo&key=" +
          this.sesh +
          "&locId=" +
          this.locId +
          "&userId=" +
          this.service.userId
      )
      .subscribe((res) => {
        this.employeeName = res.result.name;
        this.employeeRole = res.result.role;
        this.ls.setCookie("employeeName=", res.result.name);
        this.ls.setCookie("employeeRole=", res.result.role);
      });
  }
}
