import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { CartService } from "../../SERVICES/cart.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EditComponent } from "src/app/MODALS/edit/edit.component";
import { TableNumberComponent } from "../../MODALS/table-number/table-number.component";
import { MatDialog } from "@angular/material/dialog";
import { OrderConfirmComponent } from "src/app/MODALS/order-confirm/order-confirm.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { ErrorComponent } from "../../MODALS/error/error.component";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, AfterViewInit {
  items;
  contentHeight: number = 0;
  totalQty: number = 0;
  selectedTable: number;
  selectedTableId: number;
  tableNotSelected: boolean;
  totalPrice: number = 0;
  discountPrice: number = 0;
  promotions: Array<any> = [];
  hasPromotion: Boolean;
  checkedPromo: Boolean;
  expiry: String;
  qty: number = 0;
  showDelete: boolean = false;
  currentShowDelete: number;
  locId: string;

  constructor(
    private cart: CartService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalStorageService,
    private service: ServicesService,
    private spinner: NgxSpinnerService
  ) {}

  @ViewChild("checkoutContainer") checkoutContainer: ElementRef;

  @HostListener("window:resize") onResize() {
    if (this.checkoutContainer) {
      this.contentHeight = this.checkoutContainer.nativeElement.offsetHeight;
    }
  }

  ngOnInit(): void {
    this.locId = this.ls.getSessionStorage("locID");
    this.items = this.cart.getItems();

    this.cart.currentPrice.subscribe(
      (totalPrice) => (this.totalPrice = totalPrice)
    );
    this.totalPrice = this.cart.totalPrice;

    this.isTableSet();

    if (this.route.snapshot.paramMap.get("CFL")) {
      this.checkIfRedirect(true);
    }

    this.hasCheckedOut();

    if (!this.selectedTable) {
      this.tableNotSelected = true;
    }
  }

  ngAfterViewInit() {
    // need the if otherwise we get an error in DOM when .checkout-cta is hidden.
    if (this.items.length > 0) {
      this.contentHeight = this.checkoutContainer.nativeElement.offsetHeight;
      this.cdr.detectChanges();
    }
  }

  ontap(item) {
    this.discountPrice = 0;
    this._snackBar.openFromComponent(EditComponent, {
      panelClass: ["snackbar-container"],
      data: item,
    });

    this.hasPromotion = false;
    this.checkedPromo = false;
    this.promotions = [];
    // this.promotions.length = 0;
  }

  openDialog() {
    const dialogRef = this.dialog.open(TableNumberComponent, {
      // panelClass: 'modal-container'
      disableClose: true,
      data: this.selectedTable,
    });

    dialogRef.afterClosed().subscribe((tableNo) => {
      tableNo ? (this.selectedTable = tableNo) : tableNo;
      this.selectedTable
        ? (this.tableNotSelected = false)
        : (this.tableNotSelected = true);
    });
  }

  toCheckout() {
    this.selectedTableId = +this.ls.getSessionStorage("tableId"); //ID sent to the BE

    if (!this.selectedTable) {
      this.tableNotSelected = true;
    }

    if (this.selectedTable) {
      this.tableNotSelected = false;

      this.cart.checkoutObj = [];
      this.cart.checkoutObj.push({ TableNo: this.selectedTableId });

      let i;
      for (i = 0; i < this.items.length; i++) {
        let obj: any = {};

        obj.id = this.items[i].id;
        obj.qty = this.items[i].qty;
        obj.priceId = this.items[i].priceId;

        if (this.items[i].extras) {
          let k;
          for (k = 0; k < this.items[i].extras.length; k++) {
            if (obj.extras) {
              obj.extras += "," + this.items[i].extras[k].optionId;
            } else {
              obj.extras = this.items[i].extras[k].optionId;
            }
          }
        }

        this.cart.checkoutObj.push(obj);
      }

      let sesh = this.ls.checkCookieOnly("sesh=");
      let locID = this.ls.getSessionStorage("locID");
      this.spinner.show();

      this.service
        .getReqOrder(
          "?action=createPaymentRequest&key=" + sesh + "&locId=" + locID
        )
        .pipe(
          // this.service.postReqOrder(form).pipe(
          finalize(() => this.spinner.hide())
        )
        .subscribe(
          (res: any) => {
            if (res.reload === 0) {
              console.log("res", res);
              if (res.result.status === "success") {
                this.cart.merchantKey = res.result.msk.merchantSessionKey;
                this.cart.expiry = res.result.msk.expiry;

                if (res.result?.msk) {
                  this.router.navigate(["c", this.locId, "cart", "checkout"]);
                } else {
                  console.log("msk does not exist");
                }
              } else if (res.result.status === "failed") {
                this.openError(res.result.message);
              }
            } else if (res.reload === 3) {
              this.ls.setSessionStorage("fromCheckout", "true");
              this.router.navigate(["c", this.locId, "account"]);
            }
          },
          (err) => console.log("err", err)
        );
    }
  }

  // TODO: This is a function for checking out. add to next screen or component..
  checkout() {
    if (!this.selectedTable) {
      this.tableNotSelected = true;
    }

    if (this.selectedTable) {
      this.tableNotSelected = false;

      this.cart.checkoutObj = [];
      this.cart.checkoutObj.push({ TableNo: this.selectedTable });

      let i;
      for (i = 0; i < this.items.length; i++) {
        let obj: any = {};

        obj.id = this.items[i].id;
        obj.qty = this.items[i].qty;
        obj.priceId = this.items[i].priceId;

        if (this.items[i].extras) {
          let k;
          for (k = 0; k < this.items[i].extras.length; k++) {
            if (obj.extras) {
              obj.extras += "," + this.items[i].extras[k].optionId;
            } else {
              obj.extras = this.items[i].extras[k].optionId;
            }
          }
        }

        this.cart.checkoutObj.push(obj);
      }

      let sesh = this.ls.checkCookieOnly("sesh=");

      let form = {
        action: "checkOut",
        message: this.cart.checkoutObj,
        key: sesh,
        locId: this.ls.getSessionStorage("locID"),
      };

      this.spinner.show();
      this.service
        .postReqOrder(form)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe(
          (res: any) => {
            if (res.reload === 0) {
            } else if (res.reload === 3) {
              this.ls.setSessionStorage("fromCheckout", "true");
              this.router.navigate(["c", this.locId, "account"]);
            }
          },
          (err) => console.log("err", err)
        );
    }
  }

  checkPromo() {
    this.checkedPromo = true;
    this.cart.checkoutObj = [];

    let i;
    for (i = 0; i < this.items.length; i++) {
      let obj: any = {};

      obj.id = this.items[i].id;
      obj.qty = this.items[i].qty;
      obj.priceId = this.items[i].priceId;

      if (this.items[i].extras) {
        let k;
        for (k = 0; k < this.items[i].extras.length; k++) {
          if (obj.extras) {
            obj.extras += "," + this.items[i].extras[k].optionId;
          } else {
            obj.extras = this.items[i].extras[k].optionId;
          }
        }
      }
      this.cart.checkoutObj.push(obj);
    }

    let form = {
      action: "checkPromotion",
      message: this.cart.checkoutObj,
      locId: this.ls.getSessionStorage("locID"),
    };

    this.spinner.show();
    this.service
      .postReqOrder(form)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          console.log("res", res);
          if (res.promotionCalculator?.length) {
            this.hasPromotion = true;
            this.promotions = [];
            this.promotions = res.promotionCalculator;

            let promoTotal = 0;
            let j;
            for (j = 0; j < this.promotions?.length; j++) {
              this.promotions[j];
              promoTotal += this.promotions[j]?.discount;
            }
            this.discountPrice = promoTotal;
          }
        },
        (err) => console.log("err", err)
      );
  }

  isTableSet() {
    // Changed to ID for the backend to handle.
    let tableNumber: number = +this.ls.getSessionStorage("tableNo"); // tableNo used for the FE.
    if (tableNumber) {
      this.selectedTable = tableNumber;
    }
  }

  checkIfRedirect(isRedirect: boolean) {
    this.checkout();
  }

  hasCheckedOut() {
    const timeout = 5000;
    this.route.queryParams.subscribe((params) => {
      if (params.success) {
        this.cart.items = [];
        this.ls.removeSessionStorage("cart");
        this.items = this.cart.getItems();
        this.cart.checkedOut();

        this._snackBar
          .openFromComponent(OrderConfirmComponent, {
            duration: 6000,
            panelClass: "snackbar-container",
          })
          .afterDismissed()
          .subscribe(() => {
            this.router.navigate(["c", this.locId]);
          });
      }
    });
  }

  addOne(item) {
    item.qty++;
    this.cart.updateCart("updated", 1);
  }

  subOne(item, i) {
    if (item.qty > 1) {
      item.qty--;
      this.cart.updateCart("updated", -1);
    } else if (item.qty == 1) {
      this.showDelete = true;
      this.currentShowDelete = i;
    }
  }

  updatePrice() {
    // this.price = this.data.price * this.qty;
  }

  cancel() {
    this.showDelete = false;
    this.currentShowDelete = null;
  }

  removeItem(item) {
    let i;
    for (i = 0; i < this.cart.items.length; i++) {
      if (this.cart.items[i].id === item.id) {
        // delete this.cart.items[i];
        this.cart.items.splice(i, 1);
        this.cart.updateCart("removed", item.qty);
        this.currentShowDelete = null;
        this.showDelete = false;
        break;
      }
    }
  }

  openError(message) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      panelClass: "modal-container",
      data: [{ message: message }],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }
}
