<div class="item-text">
  <p mat-dialog-title class="title">{{ title }}</p>
  <!-- <h5 mat-dialog-title> {{ desc }} </h5> -->
</div>

<mat-dialog-content class="mid-content">
  <div class="portion">
    <p class="title">Size</p>
    <mat-radio-group
      aria-labelledby="portion size group label"
      [(ngModel)]="optionId"
      name="portion"
    >
      <div class="portion-size" *ngFor="let o of options">
        <mat-radio-button [value]="o.optionId" color="primary">
          <div class="optionTitle">
            <p class="title">{{ o.optionTitle }}</p>
            <h5 *ngIf="o.optionDesc">{{ o.optionDesc }}</h5>
          </div>
          <span class="divider"> - </span>
          <div class="item-select">
            <p class="price">{{ o.price | currency: "GBP" }}</p>
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div class="extras">
    <p class="title" *ngIf="extras.length != 0">Available extras</p>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let e of extras | keyvalue; let i = index"
        class="mat-elevation-z0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="title">{{ e.key }}</p>
          </mat-panel-title>
          <button
            mat-button
            color="secondary"
            (click)="clearSortingFilters(i, $event)"
            *ngIf="extrasAdded[i]"
          >
            <span class="clear"> Clear </span>
          </button>
        </mat-expansion-panel-header>
        <mat-radio-group
          aria-labelledby="extra items group label"
          [(ngModel)]="extrasAdded[i]"
          name="{{ i }}"
        >
          <div class="extra-item" *ngFor="let eo of e.value">
            <mat-radio-button [value]="eo" selected color="primary">
              <p class="title">{{ eo.name }}</p>
              <p class="price" *ngIf="eo.extraPrice !== '0.00'">
                +{{ eo.extraPrice | currency: "GBP" }}
              </p>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="increment">
    <button
      type="button"
      aria-label="Add qty one"
      mat-icon-button
      (click)="subOne()"
      class="subtract"
    >
      <mat-icon
        aria-label="Remove one icon"
        color="primary"
        fontSet="material-icons-outlined"
      >
        remove
      </mat-icon>
    </button>

    <p class="title">{{ qty }}</p>

    <button
      type="button"
      aria-label="Add qty one"
      mat-icon-button
      (click)="addOne()"
      class="add"
    >
      <mat-icon
        aria-label="Add one icon"
        color="primary"
        fontSet="material-icons-outlined"
      >
        add
      </mat-icon>
    </button>
  </div>
  <button
    mat-button
    color="primary"
    type="button"
    (click)="onSubmit(currentProduct, optionId, qty)"
  >
    Add to basket
  </button>
</mat-dialog-actions>
