import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { AdminService } from "src/app/SERVICES/admin.service";
import { LocalStorageService } from "src/app/SERVICES/local-storage.service";
import { ServicesService } from "src/app/SERVICES/services.service";
import { SuccessComponent } from "../../success/success.component";
import { AddCategoryComponent } from "../add-category/add-category.component";

@Component({
  selector: "app-edit-category",
  templateUrl: "./edit-category.component.html",
  styleUrls: ["./edit-category.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditCategoryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private as: AdminService,
    private ls: LocalStorageService,
    private service: ServicesService,
    public dialogRef: MatDialogRef<AddCategoryComponent>,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) {}

  addCategory: FormGroup;
  parentCatgories: Object;
  uom: Object;
  selectedValue: string;
  durationInSeconds = 2;
  hasChanged: boolean = false;
  catName: string;
  paramFrom: any;

  ngOnInit(): void {
    this.catName = this.data[0].key;
    let catId = this.data[0].value.categoryId;
    let enabled = this.data[0].value.enabled;
    this.paramFrom = this.data[1];

    this.addCategory = this.formBuilder.group({
      // selectedCat: ['', [Validators.required]],
      newCategory: [this.catName],
      newIcon: [""],
      subCategory: [""],
      key: this.ls.checkCookieOnly("sesh="),
    });

    let id = this.ls.getSessionStorage("menuIdAdmin");
    let sesh = this.ls.checkCookieOnly("sesh=");
    let locID = this.ls.getSessionStorage("locID");

    // let request = '?action=getParentCategory&productType=' + id + '&locId=' + locID + '&key=' + sesh;
    let request =
      "?action=getParentCategory&productType=" +
      id +
      "&locId=" +
      locID +
      "&key=" +
      sesh +
      "&userId=" +
      this.service.userId;
    this.as.getReq(request).subscribe((res: any) => {
      if (res.result) {
        this.parentCatgories = res.result.categorys;
      }
    });
  }

  add() {
    let menuId = this.ls.getSessionStorage("menuIdAdmin");
    let locID = this.ls.getSessionStorage("locID");

    const formData = new FormData();
    formData.append("key", this.addCategory.value.key);
    formData.append("id", this.data[0].value.categoryId);
    formData.append("locId", locID);
    formData.append("userId", this.service.userId);

    console.log("this.catName", this.catName);
    if (this.catName !== this.addCategory.value.newCategory) {
      // formData.append("action", "enableDisableValue");
      formData.append("action", "updateValue");
      formData.append("value", this.addCategory.value.newCategory);

      if (this.paramFrom === "menu") {
        formData.append("update", "cat");
      } else if (this.paramFrom === "extras") {
        formData.append("update", "cate"); // needs to be cat for menu.
      }
      
      formData.append("col", "name");
    } else if (this.addCategory.value.subCategory != "") {
      formData.append("action", "insertSubCategory");
      // Send updateValue if the edit category title has been changed.
      // Keep as insertSubCat if sub category has been changed.
      formData.append("subCategory", this.addCategory.value.subCategory);
    } else if (this.addCategory.value.newIcon != "") {
      formData.append("action", "updateValue");
      formData.append("col", "icon");
      formData.append("value", this.addCategory.value.newIcon);
      formData.append("update", "cat");
    }

    this.spinner.show();

    this.as
      .postReq(formData)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res) => {
          if (res.result === "success") {
            this.openSnackBar();
            this.dialogRef.close();
          }
          console.log("res,", res);
        },
        (err) => {
          console.log("err", err);
        }
      );
  }

  closeDialog(): void {
    // this.dialogRef.close();
    // this.dialogRef.afterClosed()
    //   .pipe(finalize(() =>
    //     this.openSnackBar()
    //   ))
    //   .subscribe(data => {
    //   });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SuccessComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["snackbar-container"],
      data: "Category has been edited successfully.",
    });
  }
}
